import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '',
    redirectTo: '',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  // Normal user module
  // {
  //   path: '',
  //   loadChildren: () => import('./navigation/user-navigation/user-navigation.module').then(m => m.UserNavigationPageModule)
  // },
  // Admin module
  {
    path: 'admin',
    loadChildren: () => import('./navigation/admin-navigation/admin-navigation.module')
      .then(m => m.AdminNavigationPageModule),
    canActivate: [AuthGuard]
  },
  // Institutional module
  {
    path: 'institutional',
    loadChildren: () => import('./navigation/institutional-navigation/institutional-navigation.module')
      .then(m => m.InstitutionalNavigationPageModule),
    canActivate: [AuthGuard]
  },
  // Department module
  {
    path: 'department',
    loadChildren: () => import('./navigation/department-navigation/department-navigation.module')
      .then(m => m.DepartmentNavigationPageModule),
    canActivate: [AuthGuard]
  },
  // Unit module
  {
    path: 'unit',
    loadChildren: () => import('./navigation/unit-navigation/unit-navigation.module')
      .then(m => m.UnitNavigationPageModule),
    canActivate: [AuthGuard]
  },
  // Other modules
  {
    path: 'login',
    loadChildren: () => import('./navigation/authentication/authentication.module').then(m => m.AuthenticationPageModule),
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/common/auth/change-password/change-password.module').then(m => m.ChangePasswordPageModule)
  },
  {
    path: 'confirm',
    loadChildren: () => import('./pages/common/auth/confirm/confirm.module').then(m => m.ConfirmPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/common/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'authentication',
    loadChildren: () => import('./navigation/authentication/authentication.module').then(m => m.AuthenticationPageModule)
  },
  {
    path: 'ui-kit',
    loadChildren: () => import('./pages/common/ui-kit/ui-kit.module').then(m => m.UiKitPageModule)
  },
  {
    path: 'department-f1',
    loadChildren: () => import('./pages/pages-department/department-f1/department-f1.module').then( m => m.DepartmentF1PageModule)
  },
  {
    path: 'department-f2',
    loadChildren: () => import('./pages/pages-department/department-f2/department-f2.module').then( m => m.DepartmentF2PageModule)
  },
  {
    path: 'department-f3',
    loadChildren: () => import('./pages/pages-department/department-f3/department-f3.module').then( m => m.DepartmentF3PageModule)
  },
  {
    path: 'unit-f1',
    loadChildren: () => import('./pages/pages-unit/unit-f1/unit-f1.module').then( m => m.UnitF1PageModule)
  },
  {
    path: 'unit-f2',
    loadChildren: () => import('./pages/pages-unit/unit-f2/unit-f2.module').then( m => m.UnitF2PageModule)
  },
  {
    path: 'unit-f3',
    loadChildren: () => import('./pages/pages-unit/unit-f3/unit-f3.module').then( m => m.UnitF3PageModule)
  },
  {
    path: 'institutional-f3',
    loadChildren: () => import('./pages/pages-institutional/institutional-f3/institutional-f3.module').then( m => m.InstitutionalF3PageModule)
  },
  {
    path: 'institutional-f2',
    loadChildren: () => import('./pages/pages-institutional/institutional-f2/institutional-f2.module').then( m => m.InstitutionalF2PageModule)
  },
  {
    path: 'institutional-f1',
    loadChildren: () => import('./pages/pages-institutional/institutional-f1/institutional-f1.module').then( m => m.InstitutionalF1PageModule)
  },
  {
    path: 'statistics',
    loadChildren: () => import('./pages/common/statistics/statistics.module').then( m => m.StatisticsPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/pages-admin/settings/settings.module').then( m => m.SettingsPageModule)
  },
  { path: '**',
    redirectTo: 'login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
