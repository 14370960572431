import { Component, OnInit } from '@angular/core';
import {AlertController, IonDatetime, NavParams, PopoverController} from '@ionic/angular';
import { ToastService } from '../../../services/toast.service';
import * as moment from 'moment';
import { Moment } from 'moment/moment';
import { UsersService } from 'src/app/services/users.service';
import { AuthService } from 'src/app/services/auth.service';
import {F2Service} from '../../../services/f2.service';
import {F1Service} from '../../../services/f1.service';
import {OperativePlanService} from '../../../services/operative-plan.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-popover-select-createdBy-page',
  templateUrl: './popover-select-createdBy-page.component.html',
  styleUrls: ['./popover-select-createdBy-page.component.scss'],
})
export class PopoverSelectCreatedByPageComponent implements OnInit {
  moment = moment;

  usersList = [];
  selectedApprovalBy: any;
  private fId: number;
  isDirector = false;
  isF1 = false;
  isDate = false;
  isFirstSemester = false;
  selectedDate: string;
  isF3 = false;
  isF2 = false;
  year: string;
  customDayShortNames = ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'];
  customMonthShortName = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
  displayFormat = 'DD/MMM/YYYY';
  f3Message = '';
  f2Message = '';

  constructor(
    private popover: PopoverController,
    private toastService: ToastService,
    private navParams: NavParams,
    private usersService: UsersService,
    public authService: AuthService,
    public alertController: AlertController,
    private f2Service: F2Service,
    private f1Service: F1Service,
    private opService: OperativePlanService,
  ) {
    this.moment.locale('es-us');
    this.fId = this.navParams.data.fId;
    this.isDirector = this.navParams.data.isDirector;
    this.isF1 = this.navParams.data.isF1;
    this.isDate = this.navParams.data.isDate;
    this.isFirstSemester = this.navParams.data.isFirstSemester;
    this.isF3 = this.navParams.data.isF3;
    this.year = this.navParams.data.year;
    this.isF2 = this.navParams.data.isF2;
    this.f3Message = this.isDirector && !this.isF3 ? `Responsable` : `Elaborado por ${this.isFirstSemester ? '1er semestre' : '2do semestre'}`;
    this.f2Message = this.isDirector && !this.isF3 ? `Responsable` : `Elaborado por`;
    // this.forcedDate = this.navParams.data.approvalId;
    // this.selectedApprovalBy = this.navParams.data.approvalBy || this.authService.currentUserValue;
    // this.approvedAtIsChecked = this.navParams.data.approvedAtIsChecked || false;
    // this.approvedByIsChecked = this.navParams.data.approvedByIsChecked || false;
    // this.semesterIsChecked = this.navParams.data.semesterIsChecked || false;
    // console.log(this.navParams);
  }

  async presentAlert() {
    let alert: HTMLIonAlertElement;
    if ( !this.isDate ) {
      alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: '¡Atención!',
        message: 'Estás a punto de modificar:<br/>' + `<strong>${ this.isF2 ? this.f2Message : this.f3Message }</strong>`,
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              // console.log('Confirm Cancel: blah');
            }
          }, {
            text: 'Aceptar',
            role: 'accept',
            handler: () => {
              // console.log('Confirm Okay');
            }
          }
        ]
      });
    } else {
      alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: '¡Atención!',
        message: 'Estás a punto de modificar:<br/>' + `<strong>${ 'Fecha de 1ra revisión' }</strong>`,
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              // console.log('Confirm Cancel: blah');
            }
          }, {
            text: 'Aceptar',
            role: 'accept',
            handler: () => {
              // console.log('Confirm Okay');
            }
          }
        ]
      });
    }

    await alert.present();

    const { role } = await alert.onDidDismiss();

    if ( role === 'accept') {
      if (!this.isF1 && !this.isF3) {
        if (this.isDirector) {
          await this.f2Service.updateDirectorF2(this.fId, this.selectedApprovalBy.id);
        } else {
          await this.f2Service.updateApprovalAtF2(this.fId, this.selectedApprovalBy.id);
        }
      } else if (this.isF1) {
        if (this.isDirector && !this.isDate && this.isFirstSemester) {
          const f1s = ((await this.opService.getOperativePlansByYearByUnit(this.year, this.fId))[0]).f1s;
          for (const f1 of f1s) {
            await this.f1Service.updateResponsableIdF1(f1.id, this.selectedApprovalBy.id);
          }
          // await this.f1Service.updateResponsableIdF1(this.fId, this.selectedApprovalBy.id);
        }
        if (this.isDirector && !this.isDate && !this.isFirstSemester) {
          const f1s = ((await this.opService.getOperativePlansByYearByUnit(this.year, this.fId))[0]).f1s;
          for (const f1 of f1s) {
            await this.f1Service.updateDirectorIdF1(f1.id, this.selectedApprovalBy.id);
          }
          // await this.f1Service.updateResponsableId2F1(this.fId, this.selectedApprovalBy.id);
        }
        if (this.isDate) {
          await this.f1Service.updateSendToFirstRevisionAtF1(this.fId, this.selectedDate);
        }
      } else if (this.isF3) {
        if (this.isDirector && !this.isDate && this.isFirstSemester) {
          await this.f1Service.updateCreatedByIdF3(this.fId, this.selectedApprovalBy.id);
        }
        if (this.isDirector && !this.isDate && !this.isFirstSemester) {
          await this.f1Service.updateCreatedById2F3(this.fId, this.selectedApprovalBy.id);
        }
      }
      this.popover.dismiss({ });
    }
    // console.log('onDidDismiss resolved with role', role);
  }

  async ngOnInit() {
    this.usersList = await this.usersService.getAllNormal();
  }

  save() {
    this.presentAlert();
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 || item.lastname.toLocaleLowerCase().indexOf(term) > -1;
  }
}
