import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainModalComponent } from '../components/modals/main-modal/main-modal.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoadImageModalComponent } from '../components/modals/load-image-modal/load-image-modal.component';
import { ConfirmationModalComponent } from '../components/modals/confirmation-modal/confirmation-modal.component';
import { FormulaIndicatorModalComponent } from '../components/modals/formula-indicator-modal/formula-indicator-modal.component';
import { GeneratePlansModalComponent } from '../components/modals/generate-plans-modal/generate-plans-modal.component';
import { CommentModalComponent } from '../components/modals/comment-modal/comment-modal.component';
import { GenerateF2sModalComponent } from '../components/modals/generate-f2s-modal/generate-f2s-modal.component';
import { ObservationModalComponent } from '../components/modals/observation-modal/observation-modal.component';

@NgModule({
  declarations: [
    MainModalComponent,
    LoadImageModalComponent,
    ConfirmationModalComponent,
    FormulaIndicatorModalComponent,
    GeneratePlansModalComponent,
    CommentModalComponent,
    ObservationModalComponent,
    GenerateF2sModalComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule.forRoot(),
    NgSelectModule,
    SharedModule
  ],
  exports: [
    ObservationModalComponent,
    CommentModalComponent,
    FormulaIndicatorModalComponent,
    MainModalComponent,
    LoadImageModalComponent,
    ConfirmationModalComponent,
    GeneratePlansModalComponent,
    GenerateF2sModalComponent
  ]
})
export class ModalModule { }
