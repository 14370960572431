export enum userRoles {
  ADMIN,
  NORMAL,
  INSTITUTIONAL
}

export function userRoleToName(role: userRoles) {
  switch (role) {
    case userRoles.ADMIN:
      return 'Administrador';
    case userRoles.NORMAL:
      return 'Jefe Departamento/Director Unidad';
    case userRoles.INSTITUTIONAL:
      return 'Desarrollo Institucional';
    default:
      return '';
  }
}

export interface User {
  name: string;
  lastname: string;
  email: string;
  role: number;
  id: number;
  units?: string[];
  photo?: string;
  unitLeader?: { id: number; name: string; leaderId: number; };
  deptLeader?: { id: number; name: string; leaderId: number; unitId: number };
}
