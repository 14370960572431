import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { F1 } from 'src/app/models/F1';
import { getMonthMultiuse } from 'src/app/models/F2';
import { AuthService } from 'src/app/services/auth.service';
import { CloseIconService } from 'src/app/services/close-icon.service';
import { F1Service } from 'src/app/services/f1.service';
import { F2Service } from 'src/app/services/f2.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { OperativePlanService } from 'src/app/services/operative-plan.service';
import { SettingsService } from 'src/app/services/settings.service';
import { environment } from 'src/environments/environment';
import { IDeactivatableComponent } from '../../utils/deactivatable-component';

@Component({
  selector: 'app-f2-edit',
  templateUrl: './f2-edit.component.html',
  styleUrls: ['./f2-edit.component.scss'],
})
export class F2EditComponent implements OnInit, OnDestroy, IDeactivatableComponent {
  private destroyed$ = new Subject<boolean>();

  f1Id: number;
  f1: F1;
  f1ToList: {
    f1Id: number;
    f1: F1;
    department: string;
    header: { displayValue: string; value: string; flex: number; minWidth?: string; textAlign?: string }[];
    plans: {
      id: number;
      objectives: string;
      activities: string;
      indicator: {
        upperText: string;
        bottomText: string;
        goal: number;
      };
      annualGoal: string;
      frequency: number;
      responsable: string;
      endDate: string;
      observations: string;
    }[];
  };

  F2Editable: boolean;
  objectives: {
    count: number;
    rows: {
      title: string;
      id: number;
      index: number;
      objectives: string;
      activities: string;
      indicatorUpperText: string;
      indicatorBottomText: string;
      indicatorGoal: number;
      results: any;
      annualGoal: string | null;
      frequency: number;
      resultId: number;
      endDate: string;
      responsable: string;
      observations: string;
      goal: number;
      execution: number;
      resultStatus: number;
      status: number;
      createdBy: any;
      createdById: number;
      monthAvailability: { editable: boolean; color: boolean; }[];
    }[];
  };

  actualMonth: number;

  message = null;
  private indicatorFormulaGoal: boolean;
  areUnsavedChanges = false;

  constructor(
    private closeIcon: CloseIconService,
    public authService: AuthService,
    public opService: OperativePlanService,
    private f1Service: F1Service,
    private f2Service: F2Service,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private preferencesService: SettingsService
  ) {}

  ngOnInit() {
    this.initialSetup();
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    // For simplicity we use a flag. You should implement the logic to figure out if there are any unsaved changes or not

    let canDeactivate = true;

    if (this.areUnsavedChanges) {
      canDeactivate = window.confirm('¡Tienes cambios pendientes por guardar! Si aceptas podrías perder los cambios.');
    }

    return canDeactivate;
  }

  ionViewDidEnter() {
    this.initialSetup();
  }

  async initialSetup() {
    await this.preferencesService.getByKeySetting('indicatorFormulaGoal').then((res) => {
      this.indicatorFormulaGoal = res.setting?.value === 'true';
    });
    this.closeIcon.open();

    this.activatedRoute.params.pipe(takeUntil(this.destroyed$)).subscribe(async (params) => {
      this.f1Id = +params.f1Id;
      this.actualMonth = +params.month;
      this.f1 = await this.f1Service.getF1(this.f1Id);
      this.objectives = await this.f2Service.getF1Objectives(this.f1Id);
      this.objectives.rows = this.objectives.rows.sort((a, b) => (a.id > b.id ? 1 : -1));
      this.message = this.objectives.rows[0].results.find((res) => +res.month === this.actualMonth).comment;

      // Si es alguno de los primeros 5 dias del mes
      const f2CanBeEditedFirstXDays = +(await this.preferencesService.getByKeySetting('f2CanBeEditedFirstXDays')).setting.value;
      this.F2Editable = new Date().getDate() < f2CanBeEditedFirstXDays || !environment.production;

      // console.log(this.f1);
      // console.log(this.objectives);

      this.f1ToList = {
        f1Id: this.f1Id,
        f1: this.f1,
        department: this.f1.department ? `Departamento: '${this.f1.department.name}'` : `Unidad: '${this.f1.unit.name}'`,
        header: [
          { displayValue: 'Objetivos', value: 'objectives', flex: 0.8 },
          { displayValue: 'Actividades', value: 'activities', flex: 1 },
          { displayValue: 'Indicador', value: 'indicator', flex: 1 },
          { displayValue: 'Meta anual', value: 'annualGoal', flex: 0.2, minWidth: '40px', textAlign: 'center' },
          { displayValue: 'Frecuencia', value: 'frequency', flex: 0.5 },
          { displayValue: 'Responsable', value: 'responsable', flex: 0.8 },
        ],
        plans: this.objectives.rows.map((obj) => ({
          id: obj.id,
          index: obj.index,
          objectives: obj.title,
          activities: obj.activities,
          indicator: {
            upperText: obj.indicatorUpperText,
            bottomText: obj.indicatorBottomText,
            goal: obj.indicatorGoal,
          },
          results: obj.results,
          resultId: obj.results.find((r) => Number(r.month) === this.actualMonth).id,
          goal:
            obj.indicatorGoal !== null && this.indicatorFormulaGoal === true && (obj.monthAvailability[this.actualMonth - 1].editable || obj.results.find((r) => Number(r.month) === this.actualMonth).status !== 2) && this.checkAnnualException(obj)
              ? obj.indicatorGoal
              : obj.results.find((r) => Number(r.month) === this.actualMonth).goal,
          execution: obj.results.find((r) => Number(r.month) === this.actualMonth).execution,
          resultStatus: obj.results.find((r) => Number(r.month) === this.actualMonth).resultStatus,
          status: obj.results.find((r) => Number(r.month) === this.actualMonth).status,
          observations: obj.results.find((r) => Number(r.month) === this.actualMonth).observations,
          annualGoal: obj.annualGoal,
          frequency: obj.frequency,
          responsable: obj.responsable,
          createdBy: obj.createdBy,
          createdById: obj.createdById,
          endDate: obj.endDate
            ? `${new Date(obj.endDate).getFullYear()}-${
                new Date(obj.endDate).getMonth() + 1 < 10
                  ? '0' + (new Date(obj.endDate).getMonth() + 1)
                  : new Date(obj.endDate).getMonth() + 1
              }-${new Date(obj.endDate).getDate() < 10 ? '0' + new Date(obj.endDate).getDate() : new Date(obj.endDate).getDate()}`
            : null,
          monthAvailability: obj.monthAvailability
        })),
      };
    });
  }

  calculateMonth(f1: F1) {}

  getMonth() {
    return getMonthMultiuse(this.actualMonth);
  }

  showComment() {
    this.modalService.openModal('comment-modal', { comment: this.message }, 'normal');
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  getUnsavedChanges(evt: boolean) {
    // console.log(this.areUnsavedChanges);
    this.areUnsavedChanges = evt;
  }

  private checkAnnualException(activity: any): boolean {
    if (activity.frequency !== 2){
      return true;
    }
    // tslint:disable-next-line:triple-equals
    return this.actualMonth === 12 && activity.frequency === 2 && this.f1.plan.year == 2022;
  }
}
