import { CloseIconService } from './../close-icon.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  active = new BehaviorSubject(false);
  data = new BehaviorSubject(null);
  resetForm = new ReplaySubject(2);

  isOpen: boolean;
  isHidingCloseIcon: boolean;

  // Emitter that tells a modal to change type
  private modalType: string;
  private modalSize: string;

  // Determines wether a modal can be closed or not
  private unclosable: boolean;
  isClosing: boolean;
  isOpening: boolean;

  get getUnclosable() {
    return this.unclosable;
  }

  get getModalType() {
    return this.modalType;
  }

  get getModalSize(): string {
    return this.modalSize;
  }

  set setModalType(val) {
    this.modalType = val;
  }

  constructor(private router: Router, private closeIcon: CloseIconService) {
    this.router.events.subscribe((url) => {
      this.active.next(false);
    });
  }

  closeModal(): void {
    if (this.isHidingCloseIcon) { this.closeIcon.open(); }

    if ( !this.isClosing && !this.isOpening ) {
      this.isHidingCloseIcon = false;
      this.active.next(false);
      this.modalType = null;

      this.isClosing = true;
      this.isOpen = false;

      setTimeout(() => {
        if (!this.isOpen) {
          this.data.next(null);
          this.resetForm.next(true);
        }
        this.isOpening = false;
        this.isClosing = false;
      }, 500);
    }
  }

  openModal(type: string, data?, size?: 'small' | 'small-normal' | 'normal' | 'large', unclosable: boolean = false): void {
    if (this.closeIcon.opened.value) { this.isHidingCloseIcon = true; }

    this.isOpen = true;
    this.isOpening = true;

    this.closeIcon.close();
    if (!data) {
      this.resetForm.next(false);
      this.data.next(null);
    } else {
      this.data.next(data);
    }

    this.modalType = type;
    this.unclosable = unclosable;

    if (size) {
      this.modalSize = size;
    } else {
      this.modalSize = null;
    }

    // this.WindowScrolling.disable();
    if (!this.isClosing) {
      this.active.next(true);
    } else {
      setTimeout(() => this.active.next(true), 500);
    }
    
    setTimeout(() => this.isOpening = false, 500);
  }

  // updateData(data) {
  //   setTimeout(() => {
  //     if (this.isClosing) {
  //       this.updateData(data);
  //     } else {
  //       this.data.next(data);
  //     }
  //   }, 100);
  // }
}
