import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { F2 } from '../models/F2';
import { F1 } from '../models/F1';

@Injectable({
  providedIn: 'root',
})
export class F2Service {
  constructor(private http: HttpClient) {}

  async getF1MonthlyReport(f1Id: number): Promise<any[]> {
    const res = await this.http.get<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}/monthly-report`).toPromise();
    return res.report;
  }

  async getF1Objectives(f1Id: number): Promise<any> {
    const res = await this.http.get<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}/objectives?paginated=false`).toPromise();

    const obj = {
      count: res.objectives.length,
      rows: res.objectives,
    };

    return obj;
  }

  async getDepartmentF2sByYear(depId: number, year: string, month?: string): Promise<F2[]> {
    const res = await this.http
      .get<any>(
        `${environment.apiUrl}/api/v1/objective-results/${year}?sortKey=month&departmentId=${depId}&month=${
          month || ''
        }&paginated=false`
      )
      .toPromise();
    return res.results;
  }

  async getUnitF2sByYear(unitId: number, year: string, month?: string): Promise<F2[]> {
    const res = await this.http
      .get<any>(
        `${environment.apiUrl}/api/v1/objective-results/${year}?sortKey=month&unitId=${unitId}&month=${
          month || ''
        }&paginated=false`
      )
      .toPromise();
    return res.results;
  }

  async updateF2stoSent(
    objectives: {
      resultId: number;
      resultStatus: number;
      goal: number;
      execution: number;
      comment: string;
      observations: string;
    }[]
  ) {
    const promises = [];
    for (const objective of objectives) {
      promises.push(this.updateF2Single(objective, 1));
    }

    const res = Promise.all(promises);
    return res;
  }

  async returnF2s(
    objectives: {
      resultId: number;
      resultStatus: number;
      goal: number;
      execution: number;
      comment: string;
      observations: string;
    }[]
  ) {
    const promises = [];
    for (const objective of objectives) {
      promises.push(this.updateF2Single(objective, 3));
    }

    const res = Promise.all(promises);
    return res;
  }

  async updateF2s(
    objectives: {
      resultId: number;
      resultStatus: number;
      goal: number;
      execution: number;
      comment: string;
      observations: string;
    }[]
  ) {
    const promises = [];
    for (const objective of objectives) {
      promises.push(this.updateF2Single(objective));
    }

    const res = Promise.all(promises);
    return res;
  }

  async updateApprovalAtF2(f2Id: number, updatedById): Promise<F1> {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/objective-result/${f2Id}`, { updatedById }).toPromise();
    return res;
  }

  async updateDirectorF2(f2Id: number, directorId: number): Promise<any> {
      return await this.http.put<any>(`${environment.apiUrl}/api/v1/objective-result/${f2Id}`, { directorId }).toPromise();
  }

  async updateUpdatedByIdF2(f2Id: number, approvalAt: Date, updatedById): Promise<F1> {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/objective-result/${f2Id}`, { updatedById }).toPromise();
    return res;
  }

  async approveF2s(f2s: F2[]) {
    const promises = [];
    for (const f2 of f2s) {
      promises.push(this.http.put<any>(`${environment.apiUrl}/api/v1/objective-result/${f2.id}/approve`, {}).toPromise());
    }

    const res = Promise.all(promises);
    return res;
  }

  async sendToReviewF2s(f2s: F2[]) {
    const promises = [];
    for (const f2 of f2s) {
      promises.push(this.updateF2Status(f2, 6));
    }

    const res = Promise.all(promises);
    return res;
  }

  async sendToApprovementF2s(f2s: F2[]) {
    const promises = [];
    for (const f2 of f2s) {
      promises.push(this.updateF2Status(f2, 5));
    }

    const res = Promise.all(promises);
    return res;
  }

  updateF2Single(
    objective: { resultId: number; resultStatus: number; goal: number; execution: number; comment: string; observations: string },
    status?: number
  ) {
    let res: any;

    // console.log(objective);
    // console.log(status);

    if (status !== undefined && status !== null) {
      res = this.http
        .put<any>(`${environment.apiUrl}/api/v1/objective-result/${objective.resultId}`, {
          goal: objective.goal,
          execution: objective.execution,
          comment: objective.comment,
          observations: objective.observations,
          status: status === 0 ? 4 : status,
        })
        .toPromise();
    } else {
      res = this.http
        .put<any>(`${environment.apiUrl}/api/v1/objective-result/${objective.resultId}`, {
          goal: objective.goal,
          comment: objective.comment,
          execution: objective.execution,
          observations: objective.observations,
        })
        .toPromise();
    }

    return res;
  }

  updateF2Status(f2: F2, status: number) {
    const res = this.http
      .put<any>(`${environment.apiUrl}/api/v1/objective-result/${f2.id}`, {
        status,
      })
      .toPromise();
    return res;
  }

  async generateMonthlyF2s(year: string, month: string): Promise<F2[]> {
    const res = await this.http.post<any>(`${environment.apiUrl}/api/v1/objective-results/${year}/${month}`, {}).toPromise();
    return res.result;
  }

  // async updateObjective(obj: ObjectiveBDD) {
  //   const objId = obj.id;
  //   delete obj.id;

  //   const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/objective/${objId}`, obj).toPromise();
  //   console.log(res);
  //   return res.objective;
  // }
}
