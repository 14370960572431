import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

function validURL(textval) {
  const urlregex = new RegExp(
    '(https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)'
  );
  return urlregex.test(textval);
}

@Pipe({
  name: 'urlToEnv'
})
export class UrlToEnvPipe implements PipeTransform {

  transform(value: string, ...args: string[]): string {
    if (validURL(value)) {
      // console.log(value)
      return value;
    } else {
      // console.log(environment.apiUrl + value)
      return environment.apiUrl + value;
    }
  }

}
