import { Component, OnInit } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'app-main-modal',
  templateUrl: './main-modal.component.html',
  styleUrls: ['./main-modal.component.scss'],
})
export class MainModalComponent implements OnInit {

  public data;
  public modalType = null;
  public modalSize = '';
  public fullModal = false;

  unclosable = false;
  temporalPassword: string;
  noBackground = false;
  smallModal = true;


  constructor( public modalService: ModalService ) {
    document.addEventListener('backbutton', (e) => {
    }, !this.unclosable);
  }

  ngOnInit() {
    this.modalService.active.pipe().subscribe(status => {

      this.modalType = this.modalService.getModalType;
      this.modalSize = this.modalService.getModalSize;
      this.unclosable = this.modalService.getUnclosable;

      // if(!status){
      //   setTimeout(() => {
      //     this.modalService.data.next(null);
      //   }, 500);
      // }
    });
  }

  innerClose() {
    this.close();
  }

  close() {
    if (!this.unclosable) {
      this.modalService.closeModal();
    }
  }
  
}
