import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {SettingsService} from '../../../services/settings.service';

@Component({
  selector: 'formula-indicator-modal',
  templateUrl: './formula-indicator-modal.component.html',
  styleUrls: ['./formula-indicator-modal.component.scss'],
})
export class FormulaIndicatorModalComponent implements OnInit {
  // tslint:disable-next-line: ban-types
  @Input() data: Observable<{ indicator: FormGroup , res: Function }>;
  @Output() innerClose = new EventEmitter();
  private destroyed$ = new Subject<boolean>();

  formulaForm: FormGroup;
  isEditing: boolean;
  indicatorFormulaGoal: boolean;

  constructor( fb: FormBuilder, private preferencesService: SettingsService ) {
    this.formulaForm = fb.group( {
      upperText: ['' , [ Validators.required, Validators.minLength( 5 ) ] ],
      bottomText: ['' , [Validators.required, Validators.minLength( 5 ) ] ],
      goal: ['#' , [] ]
    });
  }

  async ngOnInit() {
    this.data.pipe(takeUntil( this.destroyed$ ) ).subscribe( r => {
      if ( r ){
        this.isEditing = true;
        this.formulaForm.patchValue( r.indicator.value );
      } else {
        this.formulaForm.reset();
        this.isEditing = false;
      }
    });
    await this.preferencesService.getByKeySetting('indicatorFormulaGoal').then(res => {
      this.indicatorFormulaGoal = res.setting?.value === 'true';
      if (this.indicatorFormulaGoal) {
        this.formulaForm.controls.goal.setValue( 0 );
        this.formulaForm.controls.goal.setValidators( Validators.min(0) );
      }
    });
  }

  ngOnChange(){
    // this.data.pipe(takeUntil( this.destroyed$ ) ).subscribe( r => {
    //   if( r ){
    //     this.isEditing = true;
    //     this.formulaForm.patchValue( r.indicator.value );
    //   } else {
    //     this.formulaForm.reset();
    //     this.isEditing = false;
    //   }
    // })
  }

  close() {
    this.innerClose.emit(true);
  }

  async saveFormula(){
    const fVal = this.formulaForm.value;
    ( await this.data.pipe( take(1) ).toPromise() ).res( fVal );

    setTimeout(() => {
      this.formulaForm.reset();
      this.close();
    }, 500);
    // Send it to the w/e
  }

}
