import { Location } from '@angular/common';
import { CloseIconService } from './services/close-icon.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();
  public selectedIndex = 0;
  isDevice = false;
  path: string = '/';
  showCloseIcon: boolean;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private config: NgSelectConfig,
    private router: Router,
    private closeIcon: CloseIconService,
    private location: Location,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    // Platform detection
    const platforms: string[] = this.platform.platforms();
    const platform: string = platforms.find((p: string) => {
      return p === 'capacitor';
    });
    this.isDevice = !!platform;

    this.configureNgSelect();
  }

  configureNgSelect() {
    this.config.notFoundText = 'No se han conseguido resultados';
    this.config.loadingText = 'Cargando...';
  }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.path = val.url;
      }
    });

    // Close icon
    this.closeIcon.opened.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.showCloseIcon = res;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  goBack() {
    if (this.closeIcon.customRoute.value) {
      const route = this.closeIcon.customRoute.value;
      this.closeIcon.customRoute.next(null);
      this.router.navigate([route]);
    } else {
      this.location.back();
    }
  }
}
