import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class F3Service {

  constructor(
    private http: HttpClient
  ) { }

  async getF3Objectives(f1Id: number): Promise<any> {
    const res = await this.http.get<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}/f3s`).toPromise();
    return res.objectives;
  }
}
