import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CloseIconService {
  public opened = new BehaviorSubject<boolean>(false);
  public customRoute = new BehaviorSubject<string>(null);

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationStart) {
        this.close();
      }
    });
  }

  open(route?: string) {
    this.opened.next(true);
    if (route) { this.customRoute.next(route); }
    else { this.customRoute.next(null); }
  }

  close() {
    this.opened.next(false);
  }
}
