import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class UserResolver implements Resolve<any> {
  constructor(private authService: AuthService) { }


  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> {
    // console.log('in resolve');
    return this.authService.user;
  }
}