import { HttpConfigInterceptor } from './interceptors/httpConfig.interceptor';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { CloseIconService } from './services/close-icon.service';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
registerLocaleData(es);
import { AngularSvgIconModule } from 'angular-svg-icon';
import { UserResolver } from './services/user-resolver/user-resolver';
import { UrlToEnvPipe } from './pipes/url-to-env.pipe';
import { PopoverNormalComponent } from './components/popovers/popover-normal/popover-normal.component';
import { ModalService } from './services/modal/modal.service';
import { ModalModule } from './shared/modal.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OpTableComponent } from './components/table/op-table/op-table.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [AppComponent, PopoverNormalComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot({ animated: false }),
        AppRoutingModule,
        HttpClientModule,
        JwtModule,
        HammerModule,
        ModalModule,
        AngularSvgIconModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'es-*'
        },
        CloseIconService,
        UserResolver
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
