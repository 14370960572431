import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})

export class PopoverComponent implements OnInit {
  showVotings: boolean;

  constructor(
    public auth: AuthService,
    private popover: PopoverController,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  logOut() {
    this.popover.dismiss().then(() => { this.auth.logout(); });
  }

  goTo(route: string) {
    this.popover.dismiss().then(() => {
      this.router.navigate([`/admin/${route}`]);
    });
  }
}
