import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'a507-filter-data',
  templateUrl: './filter-data.component.html',
  styleUrls: ['./filter-data.component.scss'],
})
export class FilterDataComponent implements OnInit {
  @Input() itemList: any[] = [];
  @Input() secondItemList: any[];
  @Input() title: string;
  @Input() secondTitle: string;
  @Input() selectedItem = null;
  @Input() secondSelectedItem = null;
  @Input() clearable = true;
  @Input() secondClearable = true;
  @Input() includeMonth = false;
  @Output() res = new EventEmitter();
  @Output() secondRes = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  itemSelect() {
    this.res.emit(this.selectedItem);
  }

  secondItemSelect() {
    this.secondRes.emit(this.secondSelectedItem);
  }

}
