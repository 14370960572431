import { userRoles } from './../models/User';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JWTTokenService } from '../services/jwttoken.service';
import { LocalStorageService } from '../services/local-storage.service';
import { AuthService } from '../services/auth.service';
import { userRoleToName } from '../models/User';
import { Platform } from '@ionic/angular';
import { ToastService } from '../services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  isDevice: boolean;

  constructor(
    private jwtTokenService: JWTTokenService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private router: Router,
    private platform: Platform,
    private toast: ToastService
  ) {
    const platforms: string[] = this.platform.platforms();
    const platformDetected: string = platforms.find((p: string) => {
      return p === 'capacitor';
    });

    this.isDevice = !!platformDetected;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // this will be passed from the route config
    // on the data property
    this.jwtTokenService.setToken(this.localStorageService.get('token'));
    if (this.jwtTokenService.isTokenExpired() || !userRoleToName(this.authService.currentUserValue?.role)) {
      this.authService.logout();
      return false;
    } else {
      if (this.authService.isNormalUser && (!this.authService.isDepartmentLeader && !this.authService.isUnitLeader)) {
        this.authService.logoutContactAdmin();
        return false;
      } else {
        this.roleInterceptorNavigator(state);
        return true;
      }
    }
  }

  roleInterceptorNavigator(state: RouterStateSnapshot) {
    const path = state.url;
    const queryParams = this.getQueryStringParams(path);

    if (this.authService.currentUserValue.role === userRoles.ADMIN) {
      if (path.split('/')[1] !== 'admin') {
        const redirectTo = `/admin${path}`;
        if (queryParams) {
          this.router.navigate([redirectTo.split('?')[0]], { queryParams });
        } else {
          this.router.navigate([redirectTo]);
        }
      }
    } else if (this.authService.currentUserValue.role === userRoles.INSTITUTIONAL) {
      if (path.split('/')[1] !== 'institutional') {
        const redirectTo = `/institutional${path}`;
        if (queryParams) {
          this.router.navigate([redirectTo.split('?')[0]], { queryParams });
        } else {
          this.router.navigate([redirectTo]);
        }
      }
    } else if (this.authService.currentUserValue.role === userRoles.NORMAL && this.authService.isUnitLeader) {
      if (path.split('/')[1] !== 'unit') {
        const redirectTo = `/unit${path}`;
        if (queryParams) {
          this.router.navigate([redirectTo.split('?')[0]], { queryParams });
        } else {
          this.router.navigate([redirectTo]);
        }
      }
    } else if (this.authService.currentUserValue.role === userRoles.NORMAL && this.authService.isDepartmentLeader) {
      if (path.split('/')[1] !== 'department') {
        const redirectTo = `/department${path}`;
        if (queryParams) {
          this.router.navigate([redirectTo.split('?')[0]], { queryParams });
        } else {
          this.router.navigate([redirectTo]);
        }
      }
    } else {
      if (path.split('/')[1] === 'secretary' || path.split('/')[1] === 'audio') {
        this.router.navigate(['home']);
      }
    }
  }

  getQueryStringParams(path: string) {
    if (path && path.split('?')[1]) {
      const q = {};
      path.split('?')[1].split('&').forEach((i) => {
        q[i.split('=')[0]] = i.split('=')[1];
      });

      return q;
    } else {
      return null;
    }
  }
}
