import { AngularSvgIconModule } from 'angular-svg-icon';
import { SearchDescriptionPipe } from './../pipes/search-description.pipe';
import { PopoverF2ReviewComponent } from './../components/popovers/popover-f2-review/popover-f2-review.component';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { SearchNamePipe } from '../pipes/search-name.pipe';
import { PopoverComponent } from '../components/popovers/popover-admin/popover.component';
import { UrlToEnvPipe } from '../pipes/url-to-env.pipe';
import { OpTableComponent } from '../components/table/op-table/op-table.component';
import { TableModule } from './table/table.module';
import { FilterDataComponent } from '../components/filter-data/filter-data.component';
import { AutosizeModule } from 'ngx-autosize';
import { F1ListComponent } from '../components/table/f1-list/f1-list.component';
import { F2ListComponent } from '../components/table/f2-list/f2-list.component';
import { F2DataEditComponent } from '../components/table/f2-data-edit/f2-data-edit.component';
import { F1EditComponent } from '../components/f1-edit/f1-edit.component';
import { F2ReviewComponent } from '../components/f2-review/f2-review.component';
import { PopoverInstitutionalComponent } from '../components/popovers/popover-institutional/popover-institutional.component';
import { F3ViewComponent } from '../components/f3-view/f3-view.component';
import { F3TableComponent } from '../components/table/f3-table/f3-table.component';
import { F2EditComponent } from '../components/f2-edit/f2-edit.component';
import {UnitF2HomeComponent} from '../pages/pages-unit/unit-f2/unit-f2-home/unit-f2-home.component';
import { PopoverPdfPageComponent } from '../components/modals/popover-pdf-page/popover-pdf-page.component';
import { PopoverSelectCreatedByPageComponent } from '../components/modals/popover-select-createdBy-page/popover-select-createdBy-page.component';

@NgModule({
  declarations: [
    PopoverComponent,
    SearchNamePipe,
    OpTableComponent,
    UrlToEnvPipe,
    FilterDataComponent,
    F1ListComponent,
    F2ListComponent,
    F1EditComponent,
    F2DataEditComponent,
    F2ReviewComponent,
    PopoverInstitutionalComponent,
    F2EditComponent,
    PopoverF2ReviewComponent,
    F3ViewComponent,
    F3TableComponent,
    SearchDescriptionPipe,
    UnitF2HomeComponent,
    PopoverPdfPageComponent,
    PopoverSelectCreatedByPageComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    TableModule,
    AutosizeModule,
  ],
  exports: [
    FilterDataComponent,
    OpTableComponent,
    PopoverComponent,
    SearchNamePipe,
    NgSelectModule,
    UrlToEnvPipe,
    F1EditComponent,
    F1ListComponent,
    F2ListComponent,
    F2DataEditComponent,
    F2ReviewComponent,
    F3ViewComponent,
    F3TableComponent,
    PopoverInstitutionalComponent,
    PopoverF2ReviewComponent,
    SearchDescriptionPipe,
    F2EditComponent,
    UnitF2HomeComponent,
    PopoverPdfPageComponent,
    PopoverSelectCreatedByPageComponent
  ],
  providers: []
})
export class SharedModule { }
