import { AuthService } from './../services/auth.service';
import { Unit } from './Units';
import { Department } from './Department';
import { User } from './User';
import { F1 } from './F1';

export interface OperativePlan {
  id?: number;
  department?: Department;
  departmentId?: number;
  unit?: Unit;
  unitId?: number;
  responsable?: User;
  responsableId?: number;
  year?: number;
  status: OPStatus;
  approvalAt: any;
  createdAt: any;
  createdBy: any;
  createdById: number;
  f1s?: F1[];
}

export enum OPStatus {
  TO_FILL,
  TO_SEND,
  SENT,
  TO_APPROVE,
  APPROVED,
  RETURNED,
  TO_REVIEW,
}

export function isEditable(f1: F1, auth: AuthService) {
  if (auth.isUnitLeader) {
    // If the user is unit leader can edit all the F1s of their departments
    return (
      f1.status === OPStatus.TO_FILL ||
      (f1.unit && f1.department && f1.status === OPStatus.SENT) ||
      (f1.unit && !f1.department && f1.status === OPStatus.SENT) ||
      f1.status === OPStatus.TO_SEND ||
      f1.status === OPStatus.RETURNED
    );
  } else if (auth.isDepartmentLeader) {
    return f1.status === OPStatus.TO_FILL || f1.status === OPStatus.TO_SEND || f1.status === OPStatus.RETURNED;
  } else if (auth.isInstitutional) {
    return f1.status !== OPStatus.APPROVED && f1.status !== OPStatus.TO_APPROVE;
  }
}

export function opPlanStatus(f1Array: F1[] = []) {
  const SOME_RETURNED = f1Array.length > 0 ? f1Array.some((r) => r.status === OPStatus.RETURNED) : false;
  const SOME_TO_FILL = f1Array.length > 0 ? f1Array.some((r) => r.status === OPStatus.TO_FILL) : false;
  const SOME_TO_SEND = f1Array.length > 0 ? f1Array.some((r) => r.status === OPStatus.TO_SEND) : false;
  const SOME_TO_APPROVE = f1Array.length > 0 ? f1Array.some((r) => r.status === OPStatus.TO_APPROVE) : false;
  const ALL_APPROVED = f1Array.length > 0 ? f1Array.every((r) => r.status === OPStatus.APPROVED) : false;
  const ALL_TO_REVIEW = f1Array.length > 0 ? f1Array.every((r) => r.status === OPStatus.TO_REVIEW) : false;

  return SOME_RETURNED
    ? OPStatus.RETURNED
    : SOME_TO_FILL
    ? OPStatus.TO_FILL
    : SOME_TO_SEND
    ? OPStatus.TO_SEND
    : SOME_TO_APPROVE
    ? OPStatus.TO_APPROVE
    : ALL_TO_REVIEW
    ? OPStatus.TO_REVIEW
    : ALL_APPROVED
    ? OPStatus.APPROVED
    : OPStatus.SENT;
}
