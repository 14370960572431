import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-popover-normal',
  templateUrl: './popover-normal.component.html',
  styleUrls: ['./popover-normal.component.scss'],
})
export class PopoverNormalComponent implements OnInit {
  showVotings: boolean;

  constructor(
    public auth: AuthService,
    private popover: PopoverController,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  logOut() {
    this.popover.dismiss().then(() => { this.auth.logout(); });
  }

  goTo(route: string) {
    this.popover.dismiss().then(() => {
      if (this.auth.isUnitLeader) {
        this.router.navigate([`unit/${route}`]);
      } else if (this.auth.isDepartmentLeader) {
        this.router.navigate([`department/${route}`]);
      } else if (this.auth.isInstitutional) {
        this.router.navigate([`institutional/${route}`]);
      }
    });
  }
}
