const ErrorMessages = {
  deputySpeaking: 'Hay un usuario hablando',
  badRequest: 'Solicitud inválida',
  emailNotFind: 'Correo no encontrado',
  invalidCredentials: 'Credenciales incorrectas',
  pwdResetNotFind: 'Código de cambio de contraseña no encontrado',
  pwdResetExp: 'Código de cambio de contraseña expirado',
  badConfirmedPassword: 'Las contraseñas no coinciden',
  serverError: 'Error del servidor',
  badVotingId: 'Id de votación no encontrado',
  badVotes: 'Votos no encontrados',
  votingNotFind: 'Votación no encontrada',
  votingNotInit: 'Votación no iniciada',
  votingClosed: 'Votación cerrada',
  hasVoted: 'Ya has votado',
  badQuestionId: 'Id de pregunta no encontrado',
  badVoteId: 'Id de voto no encontrado',
  badAnswerId: 'Id de respuesta no encontrado',
  questionNotFind: 'Pregunta no encontrada',
  voteNotFind: 'Voto no encontrado',
  invalidAnswer: 'Respuesta inválida',
  sessionPointNotFind: 'Punto de sesión plenaría no encontrado',
  plenarySessionNotInit: 'Sesión plenaria no iniciada',
  votingInProgress: 'Votación en progreso',
  votingCanceled: 'Votación cancelada',
  votingEnded: 'Votación ya finalizada',
  votingUpdated: 'Votación se ha modificado',
  politicalPartyMissing: 'Partido político faltante',
  politicalPartyNotExist: 'Partido político no existe',
  principalNotExist: 'Principal no existe',
  badCredentials: 'Error de credenciales',
  notAdmin: 'No eres administrador',
  votingInitOrClosed: 'Votación ya iniciada o cerrada',
  partyNotFind: 'Partido político no encontrado',
  partyExist: 'Partido político ya existe',
  partyHasDeputies: 'Este partido político tiene diputados',
  partyNotExist: 'Partido político no existe',
  badSessionId: 'Id de sesión plenaria no encontrado',
  badIndex: 'Error de índice',
  badStatus: 'Error de status',
  openPointHasOpenVoting: 'Punto de sesión plenaria tiene una votación abierta',
  sessionPointNotInit: 'Punto de sesión plenaria no iniciado',
  hasDependencies: 'Existen dependencias',
  presidentNotFind: 'Presidente no encontrado',
  secretaryNotFind: 'Secretario no encontrado',
  actNotFind: 'Acta no encontrada',
  sessionHasPoints: 'Sesión plenaria tiene puntos',
  allCallMaked: 'Todos los llamados hechos',
  plenarySessionNotFind: 'Sesión plenaria no encontrada',
  plenarySessionSuspended: 'Sesión plenaria suspendida',
  plenarySessionEnded: 'Sesión plenaria ya finalizada',
  plenarySessionCantEnd: 'Sesión plenaria no puede finalizar',
  documentNotExist: 'No existe documento',
  noteNotFind: 'Nota no encontrada',
  notNoteOwner: 'Esta nota no te pertenece',
  noteNotExist: 'Nota no existe',
  unauthorizedRole: 'Rol no autorizado',
  documentExist: 'Documento ya existe',
  isAct: 'Es un acta',
  userDocumentExist: 'Documento de usuario ya existe',
  pointNotExist: 'Punto de sesión plenaria no existe',
  badDocumentId: 'Id de documento no encontrado',
  deputyNotExist: 'Diputado no existe',
  badDates: 'Error de fechas',
  substituteNotExist: 'Suplente no existe',
  notIsSubstitute: 'No es suplente',
  activePeriodNotFind: 'Período actual no encontrado',
  badType: 'Error de tipo',
  answerNotFind: 'Respuesta no encontrada',
  userExist: 'Este usuario ya existe',
  presidentExist: 'Ya existe un presidente',
  fullVicepresidents: 'Ya existen dos vicepresidentes',
  unauthorized: 'Acceso no autorizado',
  alreadyConnected: 'Este usuario ya se encuentra conectado en otro dispositivo',
  existOPForYear: 'Ya existe plan operativo para ese año',
  unitNotHaveOP: 'La unidad no posee plan operativo',
  unitAlreadyExists: 'El nombre de esta unidad ya existe',
  deptAlreadyExists: 'El nombre de este departamento ya existe',
  invalidChangeOfRole: 'No puedes realizar este cambio de rol',
}

export function getError(searchKey) {
  const res = (Object.entries(ErrorMessages)
    .find(([key, value]) => key === searchKey) || []).splice(-1);
  if (!!res.length) {
    return res[0];
  } else {
    return 'Error desconocido';
  }
}
