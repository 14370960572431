import { Component, OnInit } from '@angular/core';
import {AlertController, NavParams, PopoverController} from '@ionic/angular';
import { ToastService } from '../../../services/toast.service';
import * as moment from 'moment';
import { Moment } from 'moment/moment';
import { UsersService } from 'src/app/services/users.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-popover-pdf-page',
  templateUrl: './popover-pdf-page.component.html',
  styleUrls: ['./popover-pdf-page.component.scss'],
})
export class PopoverPdfPageComponent implements OnInit {
  moment = moment;

  initial: number = null;
  final: number = null;
  leftFooter = '';
  forcedDate: Moment | Date | null = null;
  minDate: any = new Date();
  customDayShortNames = ['dom', 'lun', 'mar', 'mie', 'jue', 'vie', 'sab'];
  type: any = undefined;
  approvalDate: any = undefined;
  firstSemester: Date;
  secondSemester: Date;
  semesterIsChecked: boolean;
  forcedSemesterDate: Moment | Date = moment();
  usersList = [];
  selectedApprovalBy: any;
  approvedAtIsChecked: boolean;
  approvedByIsChecked: boolean;
  director: any;

  constructor(
    private popover: PopoverController,
    private toastService: ToastService,
    private navParams: NavParams,
    private usersService: UsersService,
    public authService: AuthService,
    public alertController: AlertController
  ) {
    this.moment.locale('es-us');
    this.type = this.navParams.data.type;
    this.forcedDate = this.navParams.data.approvalDate ? moment(this.navParams.data.approvalDate) : null;
    this.selectedApprovalBy = this.navParams.data.approvalBy || this.authService.currentUserValue;
    this.approvedAtIsChecked = this.navParams.data.approvedAtIsChecked || false;
    this.approvedByIsChecked = this.navParams.data.approvedByIsChecked || false;
    this.semesterIsChecked = this.navParams.data.semesterIsChecked || false;
    this.leftFooter = this.navParams.data.leftFooter || '';
    this.director = this.navParams.data.director || '';
    // console.log(this.navParams);
  }

  async presentAlert(forcedDate: Date | null, approvalBy: string, approvalById: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '¡Atención!',
      message: 'Estás a punto de modificar:<br/>' + ((this.approvedByIsChecked && '<strong>Elaborado por</strong>') || '') + ((this.approvedAtIsChecked && '<br/><strong>Fecha de aprobación</strong>') || ''),
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Aceptar',
          role: 'accept',
          handler: () => {
            // console.log('Confirm Okay');
          }
        }
      ]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();

    if ( role === 'accept') {
      this.popover.dismiss({
        initial: this.initial,
        final: this.final,
        leftFooter: this.leftFooter,
        forcedDate,
        forcedSemesterDate: this.forcedSemesterDate,
        semesterIsChecked: this.semesterIsChecked,
        approvalBy,
        approvalById,
      });
    }
    // console.log('onDidDismiss resolved with role', role);
  }

  async ngOnInit() {
    this.usersList = await this.usersService.getAllNormal();
  }

  changeInitial(event: any) {
    if (event.detail.value >= 0) {
      this.initial = +event.detail.value;
    } else {
      this.initial = 0;
    }
  }

  changeFinal(event: any) {
    if (event.detail.value >= 0) {
      this.final = +event.detail.value;
    } else {
      this.final = 0;
    }
  }

  export() {
    const approvalBy = this.navParams.data.approvalBy ? `${this.selectedApprovalBy.name} ${this.selectedApprovalBy.lastname}` : 'Vacío';
    const director = this.navParams.data.director ? `${this.director.name} ${this.director.lastname}` : '';
    const approvalById = this.selectedApprovalBy.id || null;
    const forcedDate = this.forcedDate ? this.moment(this.forcedDate).endOf('day').toDate() : null;

    // console.log(approvalById, approvalBy);

    if (this.initial > this.final) {
      this.toastService.showError('La página inicial no puede ser superior a la página final.', 2000);
    } else if (
      this.initial === this.final &&
      this.initial !== 0 &&
      this.initial !== null &&
      this.final !== 0 &&
      this.final !== null
    ) {
      this.toastService.showError('La página inicial no puede ser igual a la página final.', 2000);
    } else {
      // if (this.approvedAtIsChecked || this.approvedByIsChecked){
      //   this.presentAlert(forcedDate, approvalBy, approvalById);
      // } else {
        this.popover.dismiss({
          initial: this.initial,
          final: this.final,
          leftFooter: this.leftFooter,
          forcedDate: this.moment(this.forcedDate).endOf('day').toDate(),
          forcedSemesterDate: this.forcedSemesterDate,
          semesterIsChecked: this.semesterIsChecked,
          approvalBy,
          approvalById,
          director
        });
      }
  }

  dateChanged(evt: any) {
    // console.log(evt);
    this.forcedDate = evt.detail.value;
  }

  semesterDateChanged(evt: any) {
    // console.log(evt);
    this.forcedSemesterDate = evt.detail.value;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 || item.lastname.toLocaleLowerCase().indexOf(term) > -1;
  }

  changeLeftFooter(evt: any) {
    // console.log(evt);
    this.leftFooter = evt.detail.value;
  }
}
