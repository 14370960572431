import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-popover-institutional',
  templateUrl: './popover-institutional.component.html',
  styleUrls: ['./popover-institutional.component.scss'],
})
export class PopoverInstitutionalComponent implements OnInit {
  showVotings: boolean;

  constructor(
    public auth: AuthService,
    private popover: PopoverController,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  logOut() {
    this.popover.dismiss().then(() => { this.auth.logout(); });
  }

  goTo(route: string) {
    this.popover.dismiss().then(() => {
      this.router.navigate([`/admin/${route}`]);
    });
  }

}
