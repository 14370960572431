import { userRoles } from './../models/User';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User } from '../models/User';
import { map, tap, catchError, skipWhile, take } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { JWTTokenService } from './jwttoken.service';
import { Platform } from '@ionic/angular';
import { environment } from '../../environments/environment';
import CryptoJS from 'crypto-es';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = environment.apiUrl;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private isDevice = false;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router,
    private jwtTokenService: JWTTokenService,
    private platform: Platform,
    private toast: ToastService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();
    // Platform detection
    const platforms: string[] = this.platform.platforms();
    const currentPlatform: string = platforms.find((p: string) => {
      return p === 'capacitor';
    });

    this.isDevice = !!currentPlatform;
  }

  public get currentUserValue(): User {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();

    return this.currentUserSubject.value;
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/api/v1/login?device=' + this.isDevice,
      { email, password: CryptoJS.HmacSHA512(password, environment.cryptoKey).toString(CryptoJS.enc.Hex) }
    ).pipe(map(res => {
      // login successful if there's a jwt token in the response
      if (res && res.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(res.user));
        localStorage.setItem('votingPass', CryptoJS.HmacSHA512(password, environment.cryptoKey).toString(CryptoJS.enc.Hex));

        // if (this.isDevice) {
        //   if (!res.user.deputy?.principalId) {
        //     let users = JSON.parse(localStorage.getItem('users'));
        //     if (users) {
        //       if (!!users.find(user => user.email === email)) {
        //         console.log('User already exists on local storage');
        //         const index = users.findIndex(user => user.email === email);
        //         // Border case, if user changed password previous information must be replaced
        //         users[index] = { email, password, name: res.user.name, lastname: res.user.lastname, photo: res.user.photo };
        //       } else {
        //         users.push({ email, password, name: res.user.name, lastname: res.user.lastname, photo: res.user.photo });
        //       }
        //     } else {
        //       users = [];
        //       users.push({ email, password, name: res.user.name, lastname: res.user.lastname, photo: res.user.photo });
        //     }
        //     localStorage.setItem('users', JSON.stringify(users));
        //   }
        // }


        this.currentUserSubject.next(res.user);
        this.localStorageService.set('token', res.token);
        // this.socketService.connect();
      }
      return res;
    }));
  }

  get user(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/api/v1/session/check', {})
      .pipe(
        tap((res) => {
          if (res && res.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes

            localStorage.setItem('user', JSON.stringify(res.user));
            this.currentUserSubject.next(res.user);
            this.localStorageService.set('token', res.token);
          }
        })
      );
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/api/v1/password/forgotten', { email })
      .pipe();
  }

  changePassword(email: string, code: string, password: string, confirmedPassword: string): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/api/v1/password/reset',
      {
        email,
        code,
        password: CryptoJS.HmacSHA512(password, environment.cryptoKey).toString(CryptoJS.enc.Hex),
        confirmedPassword: CryptoJS.HmacSHA512(confirmedPassword, environment.cryptoKey).toString(CryptoJS.enc.Hex)
      }).pipe();
  }

  checkRecoveryCode(email: string, code: string): Observable<any> {
    // console.log(email);
    return this.http.post<any>(this.apiUrl + '/api/v1/password/check-code', { email, code })
      .pipe();
  }

  logout(forced?: boolean) {
    if (!this.isDevice) {
      // sessionStorage.clear();
      localStorage.clear();
      this.currentUserSubject = new BehaviorSubject<User>({} as User);
      this.router.navigate(['/login']).then(() => { forced ? this.toast.showError('Se ha detectado un inicio de sesión desde otro lugar') : window.location.reload(); });
    } else {
      // this.socketService.disconnect();
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('votingPass');
      localStorage.removeItem('notFirstTime');
      this.currentUserSubject = new BehaviorSubject<User>({} as User);
      const users = JSON.parse(localStorage.getItem('users'));
      if (!!users) {
        if (users.length > 0) {
          this.router.navigate(['/fingerauth']).then(() => { forced ? this.toast.showError('Se ha detectado un inicio de sesión desde otro lugar') : window.location.reload(); });
        } else {
          this.router.navigate(['/login']).then(() => { forced ? this.toast.showError('Se ha detectado un inicio de sesión desde otro lugar') : window.location.reload(); });
        }
      } else {
        this.router.navigate(['/login']).then(() => { forced ? this.toast.showError('Se ha detectado un inicio de sesión desde otro lugar') : window.location.reload(); });
      }
    }
  }

  logoutContactAdmin() {
    if (!this.isDevice) {
      // sessionStorage.clear();
      localStorage.clear();
      this.currentUserSubject = new BehaviorSubject<User>({} as User);
      this.router.navigate(['/login']).then(() => { this.toast.showError('No eres jefe de ningún departamento ni unidad. Contacta a un administrador'); });
    } else {
      // this.socketService.disconnect();
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('votingPass');
      localStorage.removeItem('notFirstTime');
      this.currentUserSubject = new BehaviorSubject<User>({} as User);
      const users = JSON.parse(localStorage.getItem('users'));
      if (!!users) {
        if (users.length > 0) {
          this.router.navigate(['/fingerauth']).then(() => { this.toast.showError('No eres jefe de ningún departamento ni unidad. Contacta a un administrador'); });
        } else {
          this.router.navigate(['/login']).then(() => { this.toast.showError('No eres jefe de ningún departamento ni unidad. Contacta a un administrador'); });
        }
      } else {
        this.router.navigate(['/login']).then(() => { this.toast.showError('No eres jefe de ningún departamento ni unidad. Contacta a un administrador'); });
      }
    }
  }


  onlyAPK() {
    if (environment.production) {
      return this.isDevice;
    } else {
      return true;
    }
  }

  get isUnitLeader(): boolean {
    return !!this.currentUserValue?.unitLeader;
  }

  get isDepartmentLeader(): boolean {
    return !!this.currentUserValue?.deptLeader;
  }

  get isNormalUser(): boolean {
    return this.currentUserValue?.role === userRoles.NORMAL;
  }

  get isInstitutional(): boolean {
    return this.currentUserValue?.role === userRoles.INSTITUTIONAL;
  }

  get isAdmin(): boolean {
    return this.currentUserValue?.role === userRoles.ADMIN;
  }
}
