import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

type LoadImageStructure = {
  readonly preloadedImages: string[],
  readonly description: string;
  readonly icon?: string
  readonly accept_text?: string;
  readonly accept: (images: string[]) => void
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'load-image-modal',
  templateUrl: './load-image-modal.component.html',
  styleUrls: ['./load-image-modal.component.scss'],
})
export class LoadImageModalComponent implements OnInit {

  @ViewChild('fileUpload', { static: false })
  fileUpload: ElementRef;
  
  @Input() data: Observable<LoadImageStructure>;
  @Output() innerClose = new EventEmitter();

  // private acceptFunction: (images: string[]) => void;
  private destroyed$ = new Subject<boolean>();
  private images = new Array<{ 'name': string, 'base64': string } >();

  public constructor(private sanitizer: DomSanitizer) {
  }

  public get loadedImages(): { 'name': string, 'base64': string }[] {
    return this.images;
  }

  public ngOnInit(): void {
    this.data.pipe(takeUntil(this.destroyed$)).subscribe((observable) => {
      const preloadedImages = observable.preloadedImages?.map((image, index) => {
        return { 'name': `index-${index}`, 'base64': image }
      });

      if (preloadedImages !== undefined) {
        this.images.push(...preloadedImages);
      }
    });
  }

  public async accept(): Promise<void> {
    this.data.pipe(takeUntil(this.destroyed$)).subscribe(response => {
      if (response) {
        response.accept(this.images.map((item) => item.base64));
      }
    });

    setTimeout(() => {
      this.close();
    }, 300);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  public close(): void {
    this.destroyed$.next(true);
    this.innerClose.emit(true);
  }

  public removeImage(index: number): void {
    this.images.splice(index, 1);
  }

  public searchImage(): void {
    const fileUpload = this.fileUpload.nativeElement;
    const reader = new FileReader();

    reader.onload = () => {
      if(this.images.length === 0){
        this.images.push(
          {
          'name': (this.images.length - 1).toString(),
          'base64': reader.result.toString()
          }
        );
      } else {
        this.images[0] = {
          'name': (this.images.length - 1).toString(),
          'base64': reader.result.toString()
        }
      }
    }

    reader.onerror = function (error) {
      console.error('Error cargando la imagen');
    };

    fileUpload.onchange = async () => {
      for (const file of fileUpload.files) {
        reader.readAsDataURL(file);
      }
    };

    fileUpload.click();
  }

  public sanitize(base64: string ): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64);
  }
}
