import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { getError } from '../models/Errors';
import { ToastService } from './toast.service';
import {CodesGetAllRequest, CodesRequest} from '../models/Setting';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(
    private http: HttpClient,
    private toast: ToastService
  ) { }

  getSetting(id: string) {
    return this.http.get(environment.apiUrl + '/api/v1/setting/' + id);
  }

  getByKeySetting(key: string): Promise<any> {
    return this.http.get(environment.apiUrl + '/api/v1/setting/key/' + key).toPromise()
      .catch((err) => this.toast.showError(getError(err)));
  }

  async getAllSettings(): Promise<any> {
    return await this.http.get(environment.apiUrl + '/api/v1/settings').toPromise()
      .catch((err) => this.toast.showError(getError(err)));
  }

  async removeSetting(id: string): Promise<any> {
    return await this.http.delete(environment.apiUrl + '/api/v1/setting/' + id).toPromise()
      .catch((err) => this.toast.showError(getError(err)));
  }

  updateSetting(id: string, data: any) {

    const editedSetting = data;
    if (editedSetting.key.toLowerCase().includes('time')) {
      editedSetting.value = (editedSetting.value * 60).toString();
    }

    delete editedSetting.key;
    delete editedSetting.id;

    editedSetting.value = editedSetting.value.toString();

    return this.getSetting(id)
      .pipe(
        map((response: any): number => response.setting.id),
        mergeMap(settingId => this.http.put(environment.apiUrl + '/api/v1/setting/' + settingId, editedSetting))
      );
  }

  async createSetting(data: any): Promise<any> {
    return this.http.post(environment.apiUrl + '/api/v1/setting/', data);
  }

  async getAnnualConstants(year: string): Promise<CodesRequest> {
    return this.http.get<CodesRequest>(environment.apiUrl + '/api/v1/annual-code/' + year).toPromise();
  }

  async getAllAnnualConstants(): Promise<CodesGetAllRequest> {
    return this.http.get<CodesGetAllRequest>(environment.apiUrl + '/api/v1/annual-codes?sortOrder=ASC&sortKey=year').toPromise();
  }

  async createAnnualConstants(data: Pick<CodesRequest, 'code'>): Promise<any> {
    const constantData = data.code;
    return this.http.post(environment.apiUrl + '/api/v1/annual-code', constantData).toPromise();
  }

  async updateAnnualConstants(data: Pick<CodesRequest, 'code'>): Promise<any>{
    const constantData = data.code;
    // console.log('grand constant', constantData);
    return this.http.put(environment.apiUrl + '/api/v1/annual-code/' + constantData.year.toString(), constantData).toPromise();
  }

  async deleteAnnualConstants(year: string): Promise<any>{
    return this.http.delete(environment.apiUrl + '/api/v1/annual-code/' + year).toPromise();
  }

}
