import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.scss'],
})
export class CommentModalComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @Output() innerClose = new EventEmitter();

  message = '';
  private destroyed$ = new Subject<boolean>();
  acceptFunction: any;

  constructor() { }

  ngOnInit() {
    this.data.subscribe(r => {
      if (r?.comment) {
        this.message = r.comment;
      } else {
        this.message = '';
      }
    });
  }

  async accept() {
    this.data.pipe(takeUntil(this.destroyed$)).subscribe(r => {
      if (r) {
        this.acceptFunction = r.accept(this.message);
      }
    });

    setTimeout(() => {
      this.close();
    }, 300);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.message = '';
  }

  close() {
    this.destroyed$.next(true);
    this.innerClose.emit(true);
  }

}
