import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popover-f2-review',
  templateUrl: './popover-f2-review.component.html',
  styleUrls: ['./popover-f2-review.component.scss'],
})
export class PopoverF2ReviewComponent implements OnInit {
  info: { goal: number, execution: number };
  result: any;

  constructor() { }

  isValidNumber(v) {
    // !NaN && !Infinity
    if (v === v && v !== Infinity && v !== -Infinity){
      return v;
    } else {
      return 100;
    }
  }

  ngOnInit() {
    this.result = this.isValidNumber((this.info.execution / this.info.goal) * 100)?.toFixed(0);
  }

}
