import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchName'
})
export class SearchNamePipe implements PipeTransform {

  transform(value: any[], search: any): unknown {
    if (!search) { return value; }
    return value.filter((d: any) => {
      return d.name.toLowerCase().includes(search.toLowerCase()) || (d.hasOwnProperty('lastname') ? d.lastname.toLowerCase().includes(search.toLowerCase()) : null)
          || (d.hasOwnProperty('email') ? d.email.toLowerCase().includes(search.toLowerCase() ) : null) || (d.hasOwnProperty('leaderEmail') ? d.leaderEmail.toLowerCase().includes(search.toLowerCase() ) : null );
    }).sort((a, b) => a.hasOwnProperty('lastname') ? (a.lastname.toLowerCase() < b.lastname.toLowerCase() ? -1 : 1) :
                      (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
    );

  }

}
