import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'observation-modal',
  templateUrl: './observation-modal.component.html',
  styleUrls: ['./observation-modal.component.scss'],
})
export class ObservationModalComponent implements OnInit {

  @Input() data: any;
  @Output() innerClose = new EventEmitter();

  message = '';
  private destroyed$ = new Subject<boolean>();
  acceptFunction: any;
  editable = false;

  constructor() { }

  ngOnInit() {
    this.data.subscribe(r => {
      if (r?.observations) {
        this.message = r.observations;
      } else {
        this.message = '';
      }
      if (r?.editable) {
        this.editable = r.editable;
      } else {
        this.editable = false;
      }
    });
  }

  async accept() {
    this.data.pipe( takeUntil(this.destroyed$)).subscribe(r => {
      if (r) {
        this.acceptFunction = r.accept(this.message);
      }
    });

    setTimeout(() => {
      this.close();
    }, 300);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.message = '';
  }

  close() {
    this.destroyed$.next(true);
    this.innerClose.emit(true);
  }

}
