import { F1, PERIOD_LIST } from './../../../models/F1';
import { AuthService } from './../../../services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { F1Service } from './../../../services/f1.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { PdfService } from '../../../services/pdf.service';
import * as XLSX from 'xlsx';
import { IonReorderGroup, PopoverController } from '@ionic/angular';
import { ItemReorderEventDetail } from '@ionic/core';
import { SettingsService } from '../../../services/settings.service';
import { PopoverPdfPageComponent } from '../../modals/popover-pdf-page/popover-pdf-page.component';
import * as moment from 'moment';
import {YearConstant} from '../../../models/Setting';

@Component({
  selector: 'app-f1-list',
  templateUrl: './f1-list.component.html',
  styleUrls: ['./f1-list.component.scss'],
})
export class F1ListComponent implements OnInit {
  private destroyed$ = new Subject<boolean>();
  moment = moment;

  @Input() data: {
    f1Id: number;
    f1: F1;
    department: string;
    header: { displayValue: string; value: string; flex: string }[];
    plans: {
      id: number;
      index: number;
      objectives: string;
      activities: string;
      indicator: {
        upperText: string;
        bottomText: string;
        goal: number;
      };
      annualGoal: string;
      frequency: number;
      responsable: string;
      endDate: string;
      createdBy: any;
      createdById: number;
    }[];
  };

  @Output() unsavedChanges = new EventEmitter<boolean>(false);

  @Input() editable = false;

  today = new Date(Date.now());
  minDate = `${this.today.getFullYear()}-${
    this.today.getMonth() + 1 < 10 ? '0' + (this.today.getMonth() + 1) : this.today.getMonth() + 1
  }-${this.today.getDate() < 10 ? '0' + this.today.getDate() : this.today.getDate()}`;
  NextYearDate = `${this.today.getFullYear() + 1}-${
    this.today.getMonth() + 1 < 10 ? '0' + (this.today.getMonth() + 1) : this.today.getMonth() + 1
  }-${this.today.getDate() < 10 ? '0' + this.today.getDate() : this.today.getDate()}`;

  periodList = PERIOD_LIST;

  showSavingStatus: boolean;
  sendingData = false;
  private indicatorFormulaGoal: boolean;
  private approvalBy = null;

  get objectiveGroupsGet(): FormArray {
    return this.planFormObjectiveGroups.get('objectives') as FormArray;
  }

  displayTable = false;
  planForm: FormGroup;
  planFormObjectiveGroups: FormGroup;
  activitiesIdsToUpdate = new Set();

  private initial = null;
  private final = null;
  private forcedDate = null;
  private approvalId = null;
  private annualConstants: YearConstant = null;

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private f1Service: F1Service,
    private toast: ToastService,
    private auth: AuthService,
    private router: Router,
    private location: Location,
    public authService: AuthService,
    private pdfService: PdfService,
    private preferencesService: SettingsService,
    private popoverController: PopoverController,
    private settingsService: SettingsService,
  ) {
    this.planFormObjectiveGroups = fb.group({ objectives: new FormArray([]) });
  }

  async presentPopover(ev: any) {
    if (!this.annualConstants){
        this.toast.showError(`No es posible generar este PDF debido a que este Plan Operativo de ${this.data.f1.plan.year.toString()} no tiene su respectivo pie de página configurado. Comunícate con un administrador.`, 5000);
        return;
    }
    const popover = await this.popoverController.create({
      component: PopoverPdfPageComponent,
      cssClass: 'header-popover noLogout',
      event: ev,
      translucent: true,
      mode: 'ios',
      componentProps: { approvalBy: this.data.f1.createdBy, leftFooter: this.annualConstants.f1Footer },
    });
    await popover.present();

    const { data, role } = await popover.onDidDismiss();
    this.initial = data?.initial;
    this.final = data?.final;
    this.forcedDate = data?.forcedDate;
    this.approvalBy = data?.approvalBy;
    this.approvalId = data?.approvalById;
    // console.log('onDidDismiss resolved with role', role, data);

    if (this.forcedDate) {
        await this.f1Service.updateCreatedByIdF1(this.data.f1Id, this.approvalId);
    }

    if (!role) {
      this.export();
    }
  }

  getSingleObjectiveFromGroup(idxObj: number): FormArray {
    return (this.planFormObjectiveGroups.get('objectives') as FormArray).at(idxObj) as FormArray;
  }

  genPlanGroup(objId: number, index: number, title?: string) {
    const ng = new FormGroup({
      id: new FormControl(objId),
      index: new FormControl(index),
      objectives: new FormControl(title || '', [Validators.required, Validators.minLength(0)]),
      activities: new FormControl('', [Validators.required, Validators.minLength(0)]),
      indicator: new FormGroup({
        upperText: new FormControl(null, []),
        bottomText: new FormControl(null, []),
        goal: new FormControl(null, []),
      }),
      annualGoal: new FormControl(null, [Validators.required, Validators.min(0)]),
      frequency: new FormControl({ displayValue: 'Anual', value: 2 }, [Validators.required, Validators.minLength(0)]),
      responsable: new FormControl('', [Validators.required, Validators.minLength(0)]),
      endDate: new FormControl(this.NextYearDate, [Validators.required, Validators.minLength(0)]),
    });
    return ng;
  }

  async ngOnInit() {
    await this.settingsService.getAnnualConstants(this.data.f1.plan.year.toString()).then((res) => {
        this.annualConstants = res.code;
    })
    await this.preferencesService.getByKeySetting('indicatorFormulaGoal').then((res) => {
      this.indicatorFormulaGoal = res.setting?.value === 'true';
    });
    this.data.plans.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));

    let currentIdx = 0;
    for (let i = 0; i < this.data.plans.length; i++) {
      if (!(this.planFormObjectiveGroups.controls.objectives as FormArray).at(currentIdx)) {
        (this.planFormObjectiveGroups.controls.objectives as FormArray).insert(currentIdx, new FormArray([]));
      }

      const formGroupToPush = this.genPlanGroup(this.data.plans[i].id, this.data.plans[i].index);
      formGroupToPush.patchValue(this.data.plans[i]);
      formGroupToPush.controls.frequency.setValue(this.periodList.find((p) => p.value === this.data.plans[i].frequency));
      ((this.planFormObjectiveGroups.controls.objectives as FormArray).at(currentIdx) as FormArray).push(formGroupToPush);

      // Si no es el último y el que viene es otro objetivo, cambio de index
      if (i + 1 !== this.data.plans.length && this.data.plans[i].index !== this.data.plans[i + 1].index) {
        currentIdx++;
      }
    }

    this.objectiveGroupsGet.controls.forEach((obj) => {
      if (this.editable) {
        obj.enable();
      } else {
        obj.disable();
      }
      (obj as FormArray).controls.forEach((act) =>
        act.valueChanges
          .pipe(
            takeUntil(this.destroyed$),
            tap((activity) => {
              this.showSavingStatus = true;
              this.unsavedChanges.emit(true);
              this.activitiesIdsToUpdate.add(activity.id);
            }),
            debounceTime(6000)
          )
          .subscribe((activity) => this.updateActivity(activity))
      );
    });

    // this.plansGet.controls.forEach(con => con.valueChanges.pipe(
    //   takeUntil(this.destroyed$),
    //   tap(obj => {
    //     this.showSavingStatus = true;
    //     this.objIdsToUpdate.add(obj.id);
    //   }),
    //   debounceTime(6000)
    // ).subscribe(obj => this.updateObjective(obj)));

    // console.log((this.planFormObjectiveGroups.controls.objectives as FormArray).value);
  }

  updateActivity(objective) {
    const arrToEditIdx = this.objectiveGroupsGet.value.findIndex((obj) => obj[0].index === objective.index);
    const arrToEdit = this.objectiveGroupsGet.value[arrToEditIdx];
    // Update changed objective
    this.f1Service
      .updateObjective({
        id: objective.id,
        f1Id: this.data.f1Id,
        title: objective.objectives,
        responsable: objective.responsable,
        activities: objective.activities,
        indicatorUpperText: objective.indicator.upperText,
        indicatorBottomText: objective.indicator.bottomText,
        indicatorGoal: this.indicatorFormulaGoal && this.checkAnnualException(objective) ? objective.indicator.goal : null,
        annualGoal: objective.annualGoal,
        frequency: objective.frequency?.value,
        endDate: moment(objective.endDate, 'YYYY-MM-DD').endOf('day').toDate(),
        index: objective.index,
        createdById: this.auth.currentUserValue.id,
      })
      .then(async (res) => {
        if ((this.data.f1.status === 0 || this.data.f1.status === 1) && !this.auth.isInstitutional) {
            await this.f1Service.updateCreatedByIdF1(this.data.f1Id, this.authService.currentUserValue.id.toString());
        }
        this.activitiesIdsToUpdate.delete(objective.id);
        this.unsavedChanges.emit(false);
        setTimeout(() => (this.showSavingStatus = false), 2000);
      })
      .catch((err) => {
        // console.log(err);
      });

    // Verify and update the objective title
    const promises = [];
    for (const obj of arrToEdit) {
      if (obj.objectives !== objective.objectives) {
        promises.push(
          this.f1Service.updateObjective({
            id: obj.id,
            f1Id: this.data.f1Id,
            title: objective.objectives,
            responsable: obj.responsable,
            activities: obj.activities,
            indicatorUpperText: obj.indicator.upperText,
            indicatorBottomText: obj.indicator.bottomText,
            indicatorGoal: obj.frequency?.value !== 2 && this.checkAnnualException(obj) ? obj.indicator.goal : null,
            annualGoal: obj.annualGoal,
            frequency: obj.frequency?.value,
            endDate: moment(obj.endDate, 'YYYY-MM-DD').endOf('day').toDate(),
            index: obj.index,
            createdById: this.auth.currentUserValue.id,
          })
        );
      }
    }

    Promise.all(promises)
      .then((res) => null);
      // .catch((err) => console.log(err));
  }

  addActivity(objIdx: number) {
    this.f1Service
      .createObjectiveInBlank(
        this.data.f1Id,
        this.getSingleObjectiveFromGroup(objIdx).value[0].index,
        this.getSingleObjectiveFromGroup(objIdx)?.at(0)?.value?.objectives
      )
      .then((res) => {
        this.getSingleObjectiveFromGroup(objIdx).push(
          this.genPlanGroup(
            res.id,
            this.getSingleObjectiveFromGroup(objIdx).value[0].index,
            this.getSingleObjectiveFromGroup(objIdx).value[0].objectives
          )
        );

        this.destroyed$.next(true);
        this.destroyed$.next(false);

        this.objectiveGroupsGet.controls.forEach((obj) => {
          (obj as FormArray).controls.forEach((act) =>
            act.valueChanges
              .pipe(
                takeUntil(this.destroyed$),
                tap((activity) => {
                  this.showSavingStatus = true;
                  this.unsavedChanges.emit(true);
                  this.activitiesIdsToUpdate.add(activity.id);
                }),
                debounceTime(6000)
              )
              .subscribe((activity) => this.updateActivity(activity))
          );
        });
      })
      .catch((err) => {
        console.log(err);
        this.toast.showError('Ha ocurrido un error al crear el objetivo');
      });
  }

  addNewObjective() {

    const newIndex = this.objectiveGroupsGet.value.length ?
    this.objectiveGroupsGet.value.slice(-1)[0][0].index + 1 : this.objectiveGroupsGet.value.length;

    this.f1Service
      .createObjectiveInBlank(this.data.f1Id, newIndex)
      .then((res) => {
        this.objectiveGroupsGet.push(new FormArray([this.genPlanGroup(res.id, newIndex)]));

        this.destroyed$.next(true);
        this.destroyed$.next(false);

        this.objectiveGroupsGet.controls.forEach((obj) => {
          (obj as FormArray).controls.forEach((act) =>
            act.valueChanges
              .pipe(
                takeUntil(this.destroyed$),
                tap((activity) => {
                  this.showSavingStatus = true;
                  this.unsavedChanges.emit(true);
                  this.activitiesIdsToUpdate.add(activity.id);
                }),
                debounceTime(6000)
              )
              .subscribe((activity) => this.updateActivity(activity))
          );
        });
      })
      .catch((err) => {
        console.log(err);
        this.toast.showError('Ha ocurrido un error al crear el objetivo');
      });
  }

  // Handling the Indicator Formula Module
  openIndicatorModal(activity?) {
    this.modalService.openModal('formula-indicator-modal', {
      indicator: activity,
      res: (e) => this.saveFormulaIndicator(activity, e),
    });
  }

  saveFormulaIndicator(activity: FormGroup, formulaIndicator: { upperText: string; bottomText: string; goal: number }) {
    activity.patchValue(formulaIndicator);
  }

  deleteActivity(idxObj: number, idxAct: number) {
    this.modalService.openModal(
      'confirmation-modal',
      {
        description: '¿Está seguro que desea eliminar este objetivo?',
        accept_text: 'Eliminar',
        accept: () => {
          this.f1Service
            .deleteObjective(this.getSingleObjectiveFromGroup(idxObj).at(idxAct).value.id)
            .then((res) => {
              this.getSingleObjectiveFromGroup(idxObj).removeAt(idxAct);
              if (this.getSingleObjectiveFromGroup(idxObj).length === 0) {
                this.objectiveGroupsGet.removeAt(idxObj);
              }
              this.toast.showSuccess('Objetivo eliminado de manera exitosa');
            })
            .catch((err) => {
              console.log(err);
              this.toast.showError('Ha ocurrido un error al borrar el objetivo');
            });
        },
      },
      'small'
    );
  }

  returnF1() {
    this.modalService.openModal(
      'comment-modal',
      {
        accept: (message) => {
          this.f1Service
            .returnF1(this.data.f1Id, message)
            .then((res) => {
              this.toast.showSuccess('Se ha devuelto de manera exitosa');
              this.location.back();
            })
            .catch((err) => {
              console.log(err);
              this.toast.showError('Ha ocurrido un error al devolver el F1');
            })
            .finally(() => {
              this.sendingData = false;
            });
        },
      },
      'normal'
    );
  }

  approveF1() {
    this.f1Service
      .approveF1(this.data.f1Id)
      .then((res) => {
        this.toast.showSuccess('Se ha aprobado de manera exitosa');
        this.location.back();
      })
      .catch((err) => {
        console.log(err);
        this.toast.showError('Ha ocurrido un error al aprobar el F1');
      })
      .finally(() => {
        this.sendingData = false;
      });
  }

  sendF1() {
    this.modalService.openModal(
      'confirmation-modal',
      {
        description: '¿Está seguro que desea enviar este F1 al Director de unidad?',
        accept_text: 'Enviar',
        accept: () => {
          this.f1Service
            .sendF1ToUnitLeader(this.data.f1Id)
            .then((res) => {
              this.toast.showSuccess('Se ha enviado se manera exitosa');
              this.location.back();
            })
            .catch((err) => {
              console.log(err);
              this.toast.showError('Ha ocurrido un error al enviar el F1');
            })
            .finally(() => {
              this.sendingData = false;
            });
        },
      },
      'small'
    );
  }

  viewReview() {
    this.router.navigate(
      [
        this.authService.isUnitLeader
          ? `unit/f2/${this.data.f1.id}`
          : this.authService.isDepartmentLeader
          ? `department/f2/${this.data.f1.id}`
          : `institutional/f2/${this.data.f1.id}`,
      ],
      { queryParams: { back: true } }
    );
  }

  viewF3() {
    this.router.navigate([
      this.authService.isUnitLeader
        ? `unit/f3/plan/${this.data.f1.id}`
        : this.authService.isDepartmentLeader
        ? `department/f3/${this.data.f1.id}`
        : `institutional/f3/plan/${this.data.f1.id}`,
    ]);
  }

  export() {
    this.pdfService.generateF1PDF(
        this.data,
        this.planFormObjectiveGroups.value,
        this.initial,
        this.final,
        this.forcedDate,
        this.approvalBy,
        this.annualConstants,
        `${this.data.f1.unit.leader.name} ${this.data.f1.unit.leader.lastname}`
    );
    this.initial = null;
    this.final = null;
    this.forcedDate = null;
  }

  getObjectivesLength() {
    return (this.planFormObjectiveGroups.controls.objectives as FormArray).length;
  }

  doReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    const from = ev.detail.from;
    const to = ev.detail.to;

    const fromPos = (this.planFormObjectiveGroups.controls.objectives as FormArray).at(from);
    (this.planFormObjectiveGroups.controls.objectives as FormArray).removeAt(from);
    (this.planFormObjectiveGroups.controls.objectives as FormArray).insert(to, fromPos);

    ev.detail.complete();

    // console.log(
    //   {
    //     id: fromPos.value.id,
    //     f1Id: this.data.f1Id,
    //     title: fromPos.value.objectives,
    //     responsable: fromPos.value.responsable,
    //     activities: fromPos.value.activities,
    //     indicatorUpperText: fromPos.value.indicator?.upperText,
    //     indicatorBottomText: fromPos.value.indicator?.bottomText,
    //     indicatorGoal: fromPos.value.indicator?.goal,
    //     annualGoal: fromPos.value.annualGoal,
    //     frequency: fromPos.value.frequency?.value,
    //     endDate: fromPos.value.endDate,
    //     index: to,
    //   }
    // );

    const promises = [];
    for (const [idx, group] of (this.planFormObjectiveGroups.controls.objectives as FormArray).value.entries()) {
      for (const obj of group) {
        promises.push(
          this.f1Service.updateObjective({
            id: obj.id,
            f1Id: this.data.f1Id,
            title: obj.objectives,
            responsable: obj.responsable,
            activities: obj.activities,
            indicatorUpperText: obj.indicator?.upperText,
            indicatorBottomText: obj.indicator?.bottomText,
            indicatorGoal: obj.indicator?.goal,
            annualGoal: obj.annualGoal,
            frequency: obj.frequency?.value,
            endDate: moment(obj.endDate, 'YYYY-MM-DD').endOf('day').toDate(),
            index: idx,
            createdById: this.auth.currentUserValue.id,
          })
        );
      }
    }

    Promise.all(promises);
  }

    private checkAnnualException(activity: any): boolean {
        if (activity.frequency?.value !== 2){
            return true;
        }
        // tslint:disable-next-line:triple-equals
        return activity.frequency?.value === 2 && this.data.f1.plan.year == 2022;
    }
}
