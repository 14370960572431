import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { F1, PERIOD_LIST } from 'src/app/models/F1';
import { AuthService } from 'src/app/services/auth.service';
import { F1Service } from 'src/app/services/f1.service';
import { F2Service } from 'src/app/services/f2.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ToastService } from 'src/app/services/toast.service';
import { Location } from '@angular/common';
import * as XLSX from 'xlsx';
import { PdfService } from '../../../services/pdf.service';
import { PopoverPdfPageComponent } from '../../modals/popover-pdf-page/popover-pdf-page.component';
import { PopoverController } from '@ionic/angular';
import {YearConstant} from '../../../models/Setting';
import {SettingsService} from '../../../services/settings.service';

@Component({
  selector: 'app-f3-table',
  templateUrl: './f3-table.component.html',
  styleUrls: ['./f3-table.component.scss'],
})
export class F3TableComponent implements OnInit {
  private destroyed$ = new Subject<boolean>();

  @Input() data: {
    f1Id: number;
    f1: F1;
    department: string;
    header: { displayValue: string; value: string; flex: string }[];
    plans: {
      id: number;
      index: number;
      objectives: string;
      activities: string;
      indicator: {
        upperText: string;
        bottomText: string;
        goal: number;
      };
      annualGoal: string;
      frequency: number;
      responsable: string;
    }[];
  };
  @Input() editable = false;

  periodList = PERIOD_LIST;

  showSavingStatus: boolean;

  get resultGet(): FormArray {
    return this.planForm.get('plans') as FormArray;
  }

  get objectiveGroupsGet(): FormArray {
    return this.planFormObjectiveGroups.get('objectives') as FormArray;
  }

  displayTable = false;
  planForm: FormGroup;
  planFormObjectiveGroups: FormGroup;
  objIdsToUpdate = new Set();

  private initial = null;
  private final = null;
  private forcedDate = null;
  private approvalBy = null;
  private approvalId = null;
  private isSemesterChecked = false;
  private semesterIsChecked: boolean;
  private forcedSemesterDate = null;
  private annualConstants: YearConstant = null;
  private leftFooter = '';

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private f1Service: F1Service,
    private toast: ToastService,
    private auth: AuthService,
    private location: Location,
    private f2Service: F2Service,
    public authService: AuthService,
    public router: Router,
    private pdfService: PdfService,
    private popoverController: PopoverController,
    private settingsService: SettingsService,
  ) {
    // this.planForm = fb.group({ plans: this.formPlan });
    this.planFormObjectiveGroups = fb.group({ objectives: new FormArray([]) });
  }

  async presentPopover(ev: any, semesterIsChecked) {
    if (!this.annualConstants){
      this.toast.showError(`No es posible generar este PDF debido a que este Plan Operativo de ${this.data.f1.plan.year.toString()} no tiene su respectivo pie de página configurado. Comunícate con un administrador.`, 5000);
      return;
    }
    const popover = await this.popoverController.create({
      component: PopoverPdfPageComponent,
      cssClass: 'header-popover noLogout',
      event: ev,
      translucent: true,
      mode: 'ios',
      componentProps: { type: 'f3', approvalBy: this.data.f1.f3CreatedBy, semesterIsChecked, approvalDate: semesterIsChecked ? (this.data.f1.f3Sem2ExportedAt || this.data.f1.createdAt) : (this.data.f1.f3Sem1ExportedAt || this.data.f1.createdAt), leftFooter: this.annualConstants.f3Footer },
    });
    await popover.present();

    const { data, role } = await popover.onDidDismiss();
    this.initial = data?.initial;
    this.final = data?.final;
    this.forcedDate = data?.forcedDate;
    this.approvalBy = data?.approvalBy;
    this.approvalId = data?.approvalById;
    this.semesterIsChecked = data?.semesterIsChecked;
    this.forcedSemesterDate = data?.forcedSemesterDate;
    // console.log('onDidDismiss resolved with role', role, data);

    if (this.forcedDate) {
      // await this.f1Service.updateCreatedByIdF3(this.data.f1Id, this.approvalId);
    }

    if (!role) {
      this.export();
    }
  }

  getSingleObjectiveFromGroup(idxObj: number): FormArray {
    return (this.planFormObjectiveGroups.get('objectives') as FormArray).at(idxObj) as FormArray;
  }

  genPlanGroup(objId: number) {
    const ng = new FormGroup({
      id: new FormControl(objId),

      objectives: new FormControl('', [Validators.required, Validators.minLength(0)]),
      activities: new FormControl('', [Validators.required, Validators.minLength(0)]),
      indicator: new FormGroup({
        upperText: new FormControl('', []),
        bottomText: new FormControl('', []),
        goal: new FormControl('', []),
      }),
      annualGoal: new FormControl('', [Validators.required, Validators.min(0)]),
      frequency: new FormControl({ displayValue: 'Anual', value: 2 }, [Validators.required, Validators.minLength(0)]),
      responsable: new FormControl('', [Validators.required, Validators.minLength(0)]),
      firstSemester: new FormControl('', []),
      secondSemester: new FormControl('', []),
      totalAdvance: new FormControl('', []),
    });
    return ng;
  }

  async ngOnInit() {
    await this.settingsService.getAnnualConstants(this.data.f1.plan.year.toString()).then((res) => {
      this.annualConstants = res.code;
    }).catch((err) => {
      this.toast.showError(`No es posible generar este PDF debido a que este Plan Operativo de ${this.data.f1.plan.year.toString()} no tiene su respectivo pie de página configurado. Comunícate con un administrador.`, 5000);
    });
    this.data.plans.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));

    let currentIdx = 0;
    for (let i = 0; i < this.data.plans.length; i++) {
      if (!(this.planFormObjectiveGroups.controls.objectives as FormArray).at(currentIdx)) {
        (this.planFormObjectiveGroups.controls.objectives as FormArray).insert(currentIdx, new FormArray([]));
      }

      const formGroupToPush = this.genPlanGroup(this.data.plans[i].id);
      formGroupToPush.patchValue(this.data.plans[i]);
      formGroupToPush.controls.frequency.setValue(this.periodList.find((p) => p.value === this.data.plans[i].frequency));
      ((this.planFormObjectiveGroups.controls.objectives as FormArray).at(currentIdx) as FormArray).push(formGroupToPush);

      // Si no es el último y el que viene es otro objetivo, cambio de index
      if (i + 1 !== this.data.plans.length && this.data.plans[i].index !== this.data.plans[i + 1].index) {
        currentIdx++;
      }
    }

    // this.data.plans.forEach((obj, index) => {
    //   (this.planForm.controls.plans as FormArray).push(this.genPlanGroup(obj.id));

    //   ((this.planForm.controls.plans as FormArray).at(index) as FormGroup).patchValue(obj);

    //   ((this.planForm.controls.plans as FormArray).at(index) as FormGroup).controls.frequency.setValue(
    //     this.periodList.find(p => p.value === obj.frequency)
    //   );
    // });
  }

  get getF2Status() {
    return ((this.getSingleObjectiveFromGroup(0) as FormArray).at(0) as FormGroup).controls.status.value;
  }

  export() {
    this.pdfService.generateF3PDF(
      this.data,
      this.planFormObjectiveGroups.value,
      this.initial,
      this.final,
      this.semesterIsChecked ? this.forcedSemesterDate : this.forcedDate,
      this.approvalBy,
      this.semesterIsChecked,
      this.leftFooter,
      this.annualConstants
    );
    this.initial = null;
    this.final = null;
    this.forcedDate = null;
  }

  getObjectivesLength() {
    return (this.planFormObjectiveGroups.controls.objectives as FormArray).length;
  }
}
