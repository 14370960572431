import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { F1 } from 'src/app/models/F1';
import { getMonthMultiuse } from 'src/app/models/F2';
import { AuthService } from 'src/app/services/auth.service';
import { CloseIconService } from 'src/app/services/close-icon.service';
import { F1Service } from 'src/app/services/f1.service';
import { F3Service } from 'src/app/services/f3.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { OperativePlanService } from 'src/app/services/operative-plan.service';
import { SettingsService } from 'src/app/services/settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-f3-view',
  templateUrl: './f3-view.component.html',
  styleUrls: ['./f3-view.component.scss'],
})
export class F3ViewComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<boolean>();

  f1Id: number;
  f1: F1;
  f1ToList: {
    f1Id: number,
    f1: F1,
    department: string,
    header: { displayValue: string, value: string, flex: number, minWidth?: string, textAlign?: string }[],
    plans: {
      id: number,
      objectives: string,
      activities: string,
      indicator: {
        upperText: string,
        bottomText: string,
        goal: number,
      },
      annualGoal: string,
      frequency: number,
      responsable: string,
      endDate: string,

      firstSemester: number,
      secondSemester: number,
      totalAdvance: number,
    }[]
  };

  F2Editable: boolean;
  objectives: {
    title: string,
    id: number,
    index: number,
    objectives: string,
    activities: string,
    indicatorUpperText: string,
    indicatorBottomText: string,
    indicatorGoal: number,
    frequency: number,
    endDate: string,
    responsable: string,
    annualGoal: string,
    status: number,
    f3?: {
      semester1: number,
      semester2: number,
      total: number
    }
    firstSemester: number,
    secondSemester: number,
    totalAdvance: number,
  }[];

  actualMonth: number;

  message = null;

  constructor(
    private closeIcon: CloseIconService,
    public authService: AuthService,
    public opService: OperativePlanService,
    private f1Service: F1Service,
    private f3service: F3Service,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private preferencesService: SettingsService,
  ) { }

  ngOnInit() {
    this.initialSetup();
  }

  ionViewDidEnter() {
    this.initialSetup();
  }

  initialSetup() {
    this.closeIcon.open();

    this.activatedRoute.params.pipe(takeUntil(this.destroyed$)).subscribe(async params => {
      this.f1Id = +params.f1Id;
      this.actualMonth = 1;
      this.f1 = await this.f1Service.getF1(this.f1Id);
      this.objectives = await this.f3service.getF3Objectives(this.f1Id);

      // Si es alguno de los primeros 5 dias del mes
      const f2CanBeEditedFirstXDays = +(await this.preferencesService.getByKeySetting('f2CanBeEditedFirstXDays')).setting.value;
      this.F2Editable = new Date().getDate() < f2CanBeEditedFirstXDays || !environment.production;

      this.f1ToList = {
        f1Id: this.f1Id,
        f1: this.f1,
        department: this.f1.department ? `Departamento: '${this.f1.department.name}'` : `Unidad: '${this.f1.unit.name}'`,
        header: [
          { displayValue: 'Objetivos', value: 'objectives', flex: .8 },
          { displayValue: 'Actividades', value: 'activities', flex: 1 },
          { displayValue: 'Indicador', value: 'indicator', flex: 1 },
          { displayValue: 'Meta anual', value: 'annualGoal', flex: 0.2, minWidth: '40px', textAlign: 'center' },
          { displayValue: 'Frecuencia', value: 'frequency', flex: 0.5 },
          { displayValue: 'Responsable', value: 'responsable', flex: .8 },
        ],
        plans: this.objectives.map((obj, index) => ({
          id: obj.id,
          index: obj.index,
          objectives: obj.title,
          activities: obj.activities,
          indicator: {
            upperText: obj.indicatorUpperText,
            bottomText: obj.indicatorBottomText,
            goal: obj.indicatorGoal,
          },
          annualGoal: obj.annualGoal,
          frequency: obj.frequency,
          responsable: obj.responsable,
          endDate: obj.endDate ? `${(new Date(obj.endDate)).getFullYear()}-${((new Date(obj.endDate)).getMonth() + 1) < 10 ? '0' + ((new Date(obj.endDate)).getMonth() + 1) : ((new Date(obj.endDate)).getMonth() + 1)}-${(new Date(obj.endDate)).getDate() < 10 ? '0' + (new Date(obj.endDate)).getDate() : (new Date(obj.endDate)).getDate()}` : null,

          firstSemester: obj.f3.semester1 || null,
          secondSemester: obj.f3.semester2 || null,
          totalAdvance: obj.f3.total || 0,

        }))
      };
    });
  }

  getMonth() {
    return getMonthMultiuse(this.actualMonth);
  }

  showComment() {
    this.modalService.openModal('comment-modal', { comment: this.message }, 'normal');
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }
}
