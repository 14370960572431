import { PopoverF2ReviewComponent } from './../../popovers/popover-f2-review/popover-f2-review.component';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { F1, PERIOD_LIST } from 'src/app/models/F1';
import { getF2GoalColor, getMonthMultiuse } from 'src/app/models/F2';
import { AuthService } from 'src/app/services/auth.service';
import { F1Service } from 'src/app/services/f1.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ToastService } from 'src/app/services/toast.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { PdfService } from '../../../services/pdf.service';
import { F3Service } from '../../../services/f3.service';
import { PopoverPdfPageComponent } from '../../modals/popover-pdf-page/popover-pdf-page.component';
import { YearConstant } from '../../../models/Setting';
import { SettingsService } from '../../../services/settings.service';

@Component({
  selector: 'app-f2-list',
  templateUrl: './f2-list.component.html',
  styleUrls: ['./f2-list.component.scss'],
})
export class F2ListComponent implements OnInit {
  private destroyed$ = new Subject<boolean>();

  @Input() data: {
    f1Id: number;
    f1: F1;
    department: string;
    header: { displayValue: string; value: string; flex: string }[];
    plans: {
      id: number;
      index: number;
      objectives: string;
      activities: string;
      indicator: {
        upperText: string;
        bottomText: string;
        goal: number;
      };
      annualGoal: string;
      frequency: number;
      responsable: string;
      status: number;
      endDate: string;
    }[];
  };
  @Input() editable = false;

  periodList = PERIOD_LIST;

  showSavingStatus: boolean;
  popover: any;
  private f3sObjectives: any;

  get resultGet(): FormArray {
    return this.planForm.get('plans') as FormArray;
  }

  get objectiveGroupsGet(): FormArray {
    return this.planFormObjectiveGroups.get('objectives') as FormArray;
  }

  displayTable = false;

  planForm: FormGroup;
  planFormObjectiveGroups: FormGroup;
  objIdsToUpdate = new Set();

  private initial = null;
  private final = null;
  private forcedDate = null;
  private approvalBy = null;
  private annualConstants: YearConstant = null;
  private leftFooter = '';

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private f1Service: F1Service,
    private toast: ToastService,
    private auth: AuthService,
    private location: Location,
    public authService: AuthService,
    public router: Router,
    public popoverController: PopoverController,
    private pdfService: PdfService,
    private f3service: F3Service,
    private settingsService: SettingsService,
  ) {
    // this.planForm = fb.group({ plans: new FormArray([]) });
    this.planFormObjectiveGroups = fb.group({ objectives: new FormArray([]) });
  }

  async presentPopover(ev: any) {
    if (!this.annualConstants){
      this.toast.showError(`No es posible generar este PDF debido a que este Plan Operativo de ${this.data.f1.plan.year.toString()} no tiene su respectivo pie de página configurado. Comunícate con un administrador.`, 5000);
      return;
    }
    const popover = await this.popoverController.create({
      component: PopoverPdfPageComponent,
      cssClass: 'header-popover noLogout',
      event: ev,
      translucent: true,
      mode: 'ios',
      componentProps: { leftFooter: this.annualConstants.f2Footer }
    });
    await popover.present();

    const { data, role } = await popover.onDidDismiss();
    this.initial = data?.initial;
    this.final = data?.final;
    this.forcedDate = data?.forcedDate;
    this.approvalBy = data?.approvalBy;
    this.leftFooter = data?.leftFooter;
    // console.log('onDidDismiss resolved with role', role, data);
    if (!role) {
      this.export();
    }
  }

  getSingleObjectiveFromGroup(idxObj: number): FormArray {
    return (this.planFormObjectiveGroups.get('objectives') as FormArray).at(idxObj) as FormArray;
  }

  genPlanGroup(objId: number) {
    const ng = new FormGroup({
      id: new FormControl(objId),
      objectives: new FormControl('', [Validators.required, Validators.minLength(0)]),
      activities: new FormControl('', [Validators.required, Validators.minLength(0)]),
      frequency: new FormControl(0, [Validators.minLength(0)]),
      results: new FormGroup({
        ene: new FormControl('', []),
        feb: new FormControl('', []),
        mar: new FormControl('', []),
        abr: new FormControl('', []),
        may: new FormControl('', []),
        jun: new FormControl('', []),
        jul: new FormControl('', []),
        ago: new FormControl('', []),
        sep: new FormControl('', []),
        oct: new FormControl('', []),
        nov: new FormControl('', []),
        dic: new FormControl('', []),
      }),
    });
    return ng;
  }

  async ngOnInit() {
    await this.settingsService.getAnnualConstants(this.data.f1.plan.year.toString()).then((res) => {
      this.annualConstants = res.code;
    }).catch((err) => {
      this.toast.showError(`No es posible generar este PDF debido a que este Plan Operativo de ${this.data.f1.plan.year.toString()} no tiene su respectivo pie de página configurado. Comunícate con un administrador.`, 5000);
    });
    this.f3sObjectives = await this.f3service.getF3Objectives(this.data.f1.id);
    this.data.plans.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));

    let currentIdx = 0;
    for (let i = 0; i < this.data.plans.length; i++) {
      if (!(this.planFormObjectiveGroups.controls.objectives as FormArray).at(currentIdx)) {
        (this.planFormObjectiveGroups.controls.objectives as FormArray).insert(currentIdx, new FormArray([]));
      }

      const formGroupToPush = this.genPlanGroup(this.data.plans[i].id);
      formGroupToPush.patchValue(this.data.plans[i]);
      ((this.planFormObjectiveGroups.controls.objectives as FormArray).at(currentIdx) as FormArray).push(formGroupToPush);

      // Si no es el último y el que viene es otro objetivo, cambio de index
      if (i + 1 !== this.data.plans.length && this.data.plans[i].index !== this.data.plans[i + 1].index) {
        currentIdx++;
      }
    }
  }

  getF2GoalColor(resultStatus, objective, ele) {
    const frequency = objective.value.frequency;

    if (objective.value.goal === 0 && objective.value.execution === 0 && objective.value.resultStatus === 0) {
      return getF2GoalColor(2);
    }

    if (resultStatus === null) {
      return null;
    }

    switch (frequency) {
      // Semestral
      case 1:
        if (objective.value.results.jun !== null && ele === 'jun') {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results.dic !== null && ele === 'dic') {
          return getF2GoalColor(resultStatus);
        }
        return 'a507-f2-legend--inProgress';
      // Anual
      case 2:
        if (objective.value.results.dic !== null && ele === 'dic') {
          return getF2GoalColor(resultStatus);
        }
        return 'a507-f2-legend--inProgress';
      // Trimestral
      case 3:
        if (objective.value.results.mar !== null && ele === 'mar') {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results.jun !== null && ele === 'jun') {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results.sep !== null && ele === 'sep') {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results.dic !== null && ele === 'dic') {
          return getF2GoalColor(resultStatus);
        }
        return 'a507-f2-legend--inProgress';
      // Bimestral
      case 4:
        if (objective.value.results.feb !== null && ele === 'feb') {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results.abr !== null && ele === 'abr') {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results.jun !== null && ele === 'jun') {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results.ago !== null && ele === 'ago') {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results.oct !== null && ele === 'oct') {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results.dic !== null && ele === 'dic') {
          return getF2GoalColor(resultStatus);
        }
        return 'a507-f2-legend--inProgress';
      default:
        return getF2GoalColor(resultStatus);
    }
  }

  goToFillF2(month) {
    const monthTo = getMonthMultiuse(month);
    this.router.navigate([`department/f2/${this.data.f1Id}/${monthTo}`]);
  }

  // Handling the Indicator Formula Module

  sendF1() {
    if (this.auth.isDepartmentLeader) {
      this.modalService.openModal(
        'confirmation-modal',
        {
          description: '¿Está seguro que desea enviar este F1 al Director de unidad?',
          accept_text: 'Enviar',
          accept: () => {
            this.f1Service
              .sendF1ToUnitLeader(this.data.f1Id)
              .then((res) => {
                this.toast.showSuccess('Se ha enviado se manera exitosa');
                this.location.back();
              })
              .catch((err) => {
                // console.log(err);
                this.toast.showError('Ha ocurrido un error al enviar el F1');
              });
          },
        },
        'small'
      );
    }
  }

  async onClickReview(result, event) {
    this.popover = await this.popoverController.create({
      component: PopoverF2ReviewComponent,
      cssClass: 'my-custom-class',
      event,
      componentProps: {
        info: result,
      },
      showBackdrop: false,
      mode: 'ios',
    });
    return await this.popover.present();
  }

  export() {
    // (this.planFormObjectiveGroups.controls.objectives as FormArray)
    //     .controls.forEach( value => (value as FormArray)
    //     .controls.forEach( innerValue => (innerValue as FormGroup).controls.execution.enable() ));
    this.pdfService.generateF1MonthlyOverviewPDF(
      this.data,
      this.planFormObjectiveGroups.value,
      this.f3sObjectives,
      12,
      this.initial,
      this.final,
      this.forcedDate,
      this.approvalBy,
      this.leftFooter,
      this.annualConstants
    );
    this.initial = null;
    this.final = null;
    this.forcedDate = null;
    // (this.planFormObjectiveGroups.controls.objectives as FormArray)
    //     .controls.forEach( value => (value as FormArray)
    //     .controls.forEach( innerValue => (innerValue as FormGroup).controls.execution.disable() ));
  }

  getObjectivesLength() {
    return (this.planFormObjectiveGroups.controls.objectives as FormArray).length;
  }
}
