import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { F1, PERIOD_LIST } from '../models/F1';
import { AuthService } from './auth.service';

export interface ObjectiveBDD {
  id?: number;
  index?: number;
  f1Id?: number;
  title?: string;
  responsable?: string;
  activities?: string;
  indicatorUpperText?: string;
  indicatorBottomText?: string;
  indicatorGoal?: string;
  annualGoal?: string;
  frequency?: string;
  endDate?: any;
  createdBy?: string;
  createdById?: any;
}

@Injectable({
  providedIn: 'root'
})
export class F1Service {
  periodList = PERIOD_LIST;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private auth: AuthService
  ) { }

  async getF1(f1Id: number): Promise<F1> {
    const res = await this.http.get<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}`).toPromise();
    return res.f1;
  }

  async updateExportedAtF1(f1Id: number, exportedAt: Date): Promise<F1> {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}`, {exportedAt}).toPromise();
    return res.f1;
  }

  async updateCreatedByIdF1(f1Id: number, createdById: string): Promise<F1> {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}`, {createdById}).toPromise();
    return res.f1;
  }

  async updateResponsableIdF1(f1Id: number, responsableId: string): Promise<F1> {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}`, {responsableId}).toPromise();
    return res.f1;
  }

  async updateResponsableId2F1(f1Id: number, responsableId2: string): Promise<F1> {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}`, {responsableId2}).toPromise();
    return res.f1;
  }

  async updateSendToFirstRevisionAtF1(f1Id: number, sendToFirstRevisionAt: string): Promise<F1> {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}`, { sendToFirstRevisionAt }).toPromise();
    return res.f1;
  }

  async updateCreatedByIdF3(f1Id: number, f3Sem1CreatedById: string): Promise<F1> {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}`, {f3Sem1CreatedById}).toPromise();
    return res.f1;
  }

  async updateCreatedById2F3(f1Id: number, f3Sem2CreatedById: string): Promise<F1> {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}`, {f3Sem2CreatedById}).toPromise();
    return res.f1;
  }

  async updateDirectorIdF1(f1Id: number, directorId: string): Promise<F1> {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}`, {directorId}).toPromise();
    return res.f1;
  }

  async updateSemesterF1(f1Id: number, f3Sem1ExportedAt?: Date, f3Sem2ExportedAt?: Date): Promise<F1> {
    // console.log(f3Sem1ExportedAt, f3Sem2ExportedAt);
    if (f3Sem1ExportedAt){
      const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}`, {f3Sem1ExportedAt}).toPromise();
      return res.f1;
    } else {
      const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}`, {f3Sem2ExportedAt}).toPromise();
      return res.f1;
    }
  }

  async getDepartmentF1s(depId: number): Promise<F1[]> {
    const res = await this.http.get<any>(`${environment.apiUrl}/api/v1/f1s?department=${depId}`).toPromise();
    return res.f1s.rows;
  }

  async getAllUnitF1s(unitId: number): Promise<F1[]> {
    const res = await this.http.get<any>(`${environment.apiUrl}/api/v1/f1s?unit=${unitId}&full=true&paginated=false`).toPromise();
    return res.f1s;
  }

  async getAllDepartmentF1s(deptId: number): Promise<F1[]> {
    const res = await this.http.get<any>(`${environment.apiUrl}/api/v1/f1s?department=${deptId}&full=true`).toPromise();
    return res.f1s.rows;
  }

  async getF1sByPlan(planId: number): Promise<F1[]> {
    const res = await this.http.get<any>(`${environment.apiUrl}/api/v1/operative-plan/${planId}/f1s?paginated=false&full=true`).toPromise();
    return res.f1s;
  }

  async getF1sByPlanFull(planId: number): Promise<F1[]> {
    const res = await this.http.get<any>(`${environment.apiUrl}/api/v1/operative-plan/${planId}/f1s?paginated=false&full=true`).toPromise();
    return res.f1s;
  }

  async createObjectiveInBlank(f1Id: number, index: number, title?: string) {
    const res = await this.http.post<any>(`${environment.apiUrl}/api/v1/objective`, {
      f1Id,
      index,
      title: title || '',
      responsable: '',
      activities: '',
      indicatorUpperText: '',
      indicatorBottomText: '',
      indicatorGoal: null,
      annualGoal: null,
      frequency: null,
      endDate: null,
    }).toPromise();

    return res.objective;
  }

  async deleteObjective(objId: number) {
    const res = await this.http.delete<any>(`${environment.apiUrl}/api/v1/objective/${objId}`).toPromise();
    return res;
  }

  async updateObjective(obj: ObjectiveBDD) {
    const objId = obj.id;
    delete obj.id;

    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/objective/${objId}`, obj).toPromise();
    return res.objective;
  }

  async sendF1ToUnitLeader(f1Id: number) {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}`, {
      status: 2,
      comment: '',
      createdById: this.auth.currentUserValue.id
    }).toPromise();

    return res.f1;
  }

  async returnF1(f1Id: number, comment: string) {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}`, {
      status: 5,
      comment
    }).toPromise();

    return res.f1;
  }

  async approveF1(f1Id: number) {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}/approve`, {}).toPromise();

    return res.f1;
  }

  async sendToApproveF1(f1Id: number) {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}`, {
      status: 3,
      comment: ''
    }).toPromise();

    return res.f1;
  }

  async sendToReviewF1(f1Id: number) {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/f1/${f1Id}`, {
      status: 6,
      comment: '',
      createdById: this.auth.currentUserValue.id
    }).toPromise();

    return res.f1;
  }

  async changeF1sIndexes(data: { f1s: { id: number, index: number }[] }, planId): Promise<any> {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/operative-plan/${planId}/f1s/indexes`, data).toPromise();
    return res.plan;
  }

  f1ObjectivesToObjectiveGroups(objs: any[]) {
    const formArrayGroups = this.fb.group({ objectives: new FormArray([]) });
    const genPlanGroup = (objId: number) => {
      const ng = new FormGroup({
        id: new FormControl(objId),
        title: new FormControl(),
        activities: new FormControl(),
        indicator: new FormGroup({
          upperText: new FormControl(),
          bottomText: new FormControl(),
          goal: new FormControl()
        }),
        annualGoal: new FormControl(),
        frequency: new FormControl(),
        responsable: new FormControl(),
        endDate: new FormControl(),
        objective: new FormControl(),
        createdById: new FormControl(),
        createdBy: new FormControl()
      });
      return ng;
    };

    objs?.sort((a, b) =>
      (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0)
    );

    let currentIdx = 0;
    for (let i = 0; i < objs?.length; i++) {
      if (!(formArrayGroups.controls.objectives as FormArray).at(currentIdx)) {
        (formArrayGroups.controls.objectives as FormArray).insert(currentIdx, new FormArray([]));
      }

      const formGroupToPush = genPlanGroup(objs[i].id);
      formGroupToPush.patchValue({
        ...objs[i],
        indicator: {
          upperText: objs[i].indicatorUpperText,
          bottomText: objs[i].indicatorBottomText,
          goal: objs[i].indicatorGoal
        },
        objective: objs[i]
      });
      formGroupToPush.controls.frequency.setValue(this.periodList.find(p => p.value === objs[i].frequency));
      ((formArrayGroups.controls.objectives as FormArray).at(currentIdx) as FormArray).push(formGroupToPush);

      // Si no es el último y el que viene es otro objetivo, cambio de index
      if (((i + 1) !== objs?.length &&
        objs[i].index !== objs[i + 1].index)) {
        currentIdx++;
      }
    }

    return formArrayGroups.value.objectives;
  }

  f2ObjectivesToObjectiveGroups(objs: any[]) {
    const formArrayGroups = this.fb.group({ objectives: new FormArray([]) });
    const genPlanGroup = (objId: number) => {
      const ng = new FormGroup({
        id: new FormControl(objId),
        title: new FormControl(),
        createdAt: new FormControl(),
        activities: new FormControl(),
        indicator: new FormGroup({
          upperText: new FormControl(),
          bottomText: new FormControl(),
          goal: new FormControl()
        }),
        annualGoal: new FormControl(),
        frequency: new FormControl(),
        responsable: new FormControl(),
        goal: new FormControl(),
        execution: new FormControl(),
        resultId: new FormControl(),
        resultStatus: new FormControl(),
        status: new FormControl(),
        objective: new FormControl()
      });
      return ng;
    };

    objs?.sort((a, b) =>
      (a.objective.index > b.objective.index) ?
        1 : ((b.objective.index > a.objective.index) ? -1 : 0)
    );

    let currentIdx = 0;
    for (let i = 0; i < objs?.length; i++) {
      if (!(formArrayGroups.controls.objectives as FormArray).at(currentIdx)) {
        (formArrayGroups.controls.objectives as FormArray).insert(currentIdx, new FormArray([]));
      }

      const formGroupToPush = genPlanGroup(objs[i].id);
      formGroupToPush.patchValue({
        ...objs[i].objective,
        frequency: this.periodList.find(p => p.value === objs[i].objective.frequency),
        indicator: {
          upperText: objs[i].objective.indicatorUpperText,
          bottomText: objs[i].objective.indicatorBottomText,
          goal: objs[i].objective.indicatorGoal
        },
        goal: objs[i].goal,
        execution: objs[i].execution,
        resultId: objs[i].id,
        resultStatus: objs[i].resultStatus,
        status: objs[i].status,
        objective: objs[i].objective,
        createdAt: objs[i].createdAt,
      });
      ((formArrayGroups.controls.objectives as FormArray).at(currentIdx) as FormArray).push(formGroupToPush);

      // Si no es el último y el que viene es otro objetivo, cambio de index
      if (((i + 1) !== objs?.length &&
        objs[i].objective.index !== objs[i + 1].objective.index)) {
        currentIdx++;
      }
    }

    return formArrayGroups.value.objectives;
  }
}
