import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchDescription'
})
export class SearchDescriptionPipe implements PipeTransform {
  transform(value: any[], search: any): unknown {
    if (!search) { return value; }
    return value.filter((d: any) => {
      return d.description.toLowerCase().includes(search.toLowerCase());
    }).sort((a, b) => a.description.toLowerCase() < b.description.toLowerCase() ? -1 : 1);
  }
}
