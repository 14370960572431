import { Department } from './Department';
import { Objective } from './Objective';
import { Unit } from './Units';

export enum F2Status {
  TO_FILL,
  SENT,
  APPROVED,
  RETURNED,
  TO_SEND,
  TO_APPROVE,
  TO_REVIEW,
}

export interface F2 {
  id?: number;
  objectiveId: string;
  objective?: Objective;
  goal: string;
  execution: string;
  result: string;
  resultStatus: string;
  month: string;
  status: F2Status;
  comment?: string;
  createdBy: any;
  createdById: number;
  updatedBy?: any;
  director?: any;
  directorId?: any;
  approvalAt?: any;
  sendToFirstRevisionAt: any;
}

export function getF2Goal(f2: { goal: number; excecution: number; result: number }) {
  return f2.excecution / f2.goal;
}

/**
 * @param execution
 * @param goal
 */
export function calculateF2ResultStatus(execution = 0, goal) {
  if (goal === null) {
    return 0;
  }

  const result = Math.round(((execution / goal) * 100 + Number.EPSILON) * 10) / 10;
  return result < 90 ? (result < 75 ? 2 : 1) : result > 100 ? 3 : 0;
}

export function getF2GoalColor(resultStatus: number) {
  switch (resultStatus?.toString()) {
    case '0':
      return 'a507-f2-legend--good';
    case '1':
      return 'a507-f2-legend--regular';
    case '2':
      return 'a507-f2-legend--low';
    case '3':
      return 'a507-f2-legend--overextended';
    default:
      return null;
  }
}

export function getDistinctF2sByMonth(f2s: F2[]) {
  const distinctF2s: F2[] = [];

  for (const f2 of f2s) {
    const allWithMonthAndF1 = f2s.filter((f2a) => f2a.month === f2.month && f2a.objective.f1Id === f2.objective.f1Id);

    if (!distinctF2s.find((f2aux) => f2aux.month === f2.month && f2aux.objective.f1Id === f2.objective.f1Id)) {
      if (allWithMonthAndF1.find((f2a) => f2a.updatedBy)) {
        distinctF2s.push(allWithMonthAndF1.find((f2a) => f2a.updatedBy));
      } else {
        distinctF2s.push(f2);
      }
    }
  }

  return distinctF2s;
}

export function getDistinctF2sByMonthByF(f2s: F2[]) {
  const distinctF2s: any = [];
  const accum = [];
  const alreadyAccumulated: any = [{}];

  for (const f2 of f2s) {
    // if (!distinctF2s.find(f2aux => f2aux.month === f2.month && f2aux.objective.f1Id === f2.objective.f1Id)) {
    // distinctF2s.push(f2);
    if (!alreadyAccumulated.find((f2aux) => f2aux.month === f2.month && f2aux.f1Id === f2.objective.f1Id)) {
      // if (!alreadyAccumulated.includes(f2.objective.f1Id)){
      accum.push([f2s.filter((f2x) => f2x.month === f2.month && f2x.objective.f1Id === f2.objective.f1Id)]);
      alreadyAccumulated.push({ f1Id: f2.objective.f1Id, month: f2.month });
      // }
    }
    // }
  }

  return accum;
}

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export function getMonthMultiuse(month: string | number) {
  if (typeof month === 'string') {
    return months.findIndex((e) => e.toLowerCase().includes(month as string)) + 1;
  } else {
    return months[(month as number) - 1];
  }
}
