import { Unit } from './Units';
import { Department } from './Department';
import { User } from './User';
import { OperativePlan, OPStatus } from './OperativePlan';
import { Objective } from './Objective';

export interface F1 {
  id?: number;
  plan?: OperativePlan;
  planId: number;
  objectives: Objective[];
  department?: Department;
  departmentId?: number;
  unit?: Unit;
  unitId?: number;
  responsable?: User;
  responsableId?: number;
  responsable2?: User;
  responsableId2?: number;
  status: OPStatus;
  approvalAt: any;
  createdAt: any;
  comment?: string;
  createdBy: string;
  createdById: number;
  exportedAt?: Date;
  f3Sem1ExportedAt?: Date;
  f3Sem2ExportedAt?: Date;
  index?: number;
  f3CreatedBy?: any;
  f3Sem1CreatedName?: any;
  f3Sem2CreatedName?: any;
  f3Sem1CreatedBy?: any;
  f3Sem2CreatedBy?: any;
  sendToFirstRevisionAt?: any;
  director?: User;
}

export const PERIOD_LIST = [
  { displayValue: 'Mensual', value: 0 },
  { displayValue: 'Bimestral', value: 4 },
  { displayValue: 'Trimestral', value: 3 },
  { displayValue: 'Semestral', value: 1 },
  { displayValue: 'Anual', value: 2 },
];
