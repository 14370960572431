import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { F1, PERIOD_LIST } from 'src/app/models/F1';
import { calculateF2ResultStatus, F2Status, getF2GoalColor, getMonthMultiuse } from 'src/app/models/F2';
import { AuthService } from 'src/app/services/auth.service';
import { F1Service } from 'src/app/services/f1.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { ToastService } from 'src/app/services/toast.service';
import { Location } from '@angular/common';
import { F2Service } from 'src/app/services/f2.service';
import { PdfService } from '../../../services/pdf.service';
import { F3Service } from '../../../services/f3.service';
import { debounceTime, skip, takeUntil, tap } from 'rxjs/operators';
import { SettingsService } from '../../../services/settings.service';
import { PopoverPdfPageComponent } from '../../modals/popover-pdf-page/popover-pdf-page.component';
import { PopoverController } from '@ionic/angular';
import {YearConstant} from '../../../models/Setting';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'f2-data-edit',
  templateUrl: './f2-data-edit.component.html',
  styleUrls: ['./f2-data-edit.component.scss'],
})
export class F2DataEditComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();
  activitiesIdsToUpdate = new Set();
  indicatorFormulaGoal: boolean;

  @Input() actualMonth: number = null;

  @Output() unsavedChanges = new EventEmitter<boolean>(false);

  @Input() data: {
    f1Id: number;
    f1: F1;
    department: string;
    header: { displayValue: string; value: string; flex: string }[];
    plans: {
      id: number;
      index: number;
      objectives: string;
      activities: string;
      indicator: {
        upperText: string;
        bottomText: string;
        goal: number;
      };
      annualGoal: string;
      frequency: number;
      execution: number;
      goal: number;
      resultId: number;
      resultStatus: number;
      responsable: string;
      monthAvailability: { editable: boolean; }[];
      observations: string;
    }[];
  };
  @Input() editable = false;

  periodList = PERIOD_LIST;

  showSavingStatus: boolean;
  private f3sObjectives: any;
  private approvalBy = null;
  private director = null;

  get resultGet(): FormArray {
    return this.planForm.get('plans') as FormArray;
  }

  get objectiveGroupsGet(): FormArray {
    return this.planFormObjectiveGroups.get('objectives') as FormArray;
  }

  displayTable = false;

  planForm: FormGroup;
  planFormObjectiveGroups: FormGroup;
  objIdsToUpdate = new Set();
  sendingData = false;

  formPlanGroup: FormGroup;

  private initial = null;
  private final = null;
  private forcedDate = null;
  private approvalId = null;
  private leftFooter = '';
  private annualConstants: YearConstant = null;

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private f1Service: F1Service,
    private toast: ToastService,
    private auth: AuthService,
    private location: Location,
    private f2Service: F2Service,
    public authService: AuthService,
    public router: Router,
    private pdfService: PdfService,
    private f3service: F3Service,
    private preferencesService: SettingsService,
    private popoverController: PopoverController,
    private settingsService: SettingsService,
  ) {
    // this.planForm = fb.group({ plans: new FormArray([]) });
    this.planFormObjectiveGroups = fb.group({ objectives: new FormArray([]) });
  }

  async presentPopover(ev: any) {
    if (!this.annualConstants){
      this.toast.showError(`No es posible generar este PDF debido a que este Plan Operativo de ${this.data.f1.plan.year.toString()} no tiene su respectivo pie de página configurado. Comunícate con un administrador.`, 5000);
      return;
    }
    const popover = await this.popoverController.create({
      component: PopoverPdfPageComponent,
      cssClass: 'header-popover noLogout',
      event: ev,
      translucent: true,
      mode: 'ios',
      componentProps: {
        approvalBy: (this.data.plans[0] as any).results.find((f2) => f2.month === this.actualMonth).updatedBy,
        leftFooter: this.annualConstants.f2Footer,
        director: (this.data.plans[0] as any).results.find((f2) => f2.month === this.actualMonth).director
      },
    });
    await popover.present();
    const { data, role } = await popover.onDidDismiss();
    this.initial = data?.initial;
    this.final = data?.final;
    this.forcedDate = (this.data.plans[0] as any).results.find((f2) => f2.month === this.actualMonth).approvalAt;
    this.approvalBy = data?.approvalBy;
    this.approvalId = data?.approvalById;
    this.director = data?.director;
    this.leftFooter = data?.leftFooter;

    // if (this.forcedDate) {
    //   await this.f2Service.updateUpdatedByIdF2(
    //     (this.data.plans[0] as any).results.find((f2) => f2.month === this.actualMonth).id,
    //     this.forcedDate,
    //     this.approvalId
    //   );
    // }

    // console.log('onDidDismiss resolved with role', role, data);
    if (!role) {
      this.export();
    }
  }

  getSingleObjectiveFromGroup(idxObj: number): FormArray {
    return (this.planFormObjectiveGroups.get('objectives') as FormArray).at(idxObj) as FormArray;
  }

  genPlanGroup(objId: number) {
    const ng = new FormGroup({
      id: new FormControl(objId),
      objectives: new FormControl(''),
      activities: new FormControl(''),
      indicator: new FormGroup({
        upperText: new FormControl('', []),
        bottomText: new FormControl('', []),
        goal: new FormControl('', []),
      }),
      annualGoal: new FormControl(''),
      frequency: new FormControl({ displayValue: 'Anual', value: 2 }),
      responsable: new FormControl(''),
      goal: new FormControl(null, [Validators.required, Validators.min(0)]),
      execution: new FormControl('', [Validators.required, Validators.min(0)]),
      resultId: new FormControl(''),
      resultStatus: new FormControl('', []),
      status: new FormControl('', []),
      observations: new FormControl('', []),
      results: new FormGroup({
        0: new FormControl(null, []),
        1: new FormControl(null, []),
        2: new FormControl(null, []),
        3: new FormControl(null, []),
        4: new FormControl(null, []),
        5: new FormControl(null, []),
        6: new FormControl(null, []),
        7: new FormControl(null, []),
        8: new FormControl(null, []),
        9: new FormControl(null, []),
        10: new FormControl(null, []),
        11: new FormControl(null, []),
      }),
      monthAvailability: new FormControl(null, [])
    });
    return ng;
  }

  async ngOnInit() {
    await this.settingsService.getAnnualConstants(this.data.f1.plan.year.toString()).then((res) => {
        this.annualConstants = res.code;
    }).catch((err) => {
      this.toast.showError(`No es posible generar este PDF debido a que este Plan Operativo de ${this.data.f1.plan.year.toString()} no tiene su respectivo pie de página configurado. Comunícate con un administrador.`, 5000);
    });
    await this.preferencesService.getByKeySetting('indicatorFormulaGoal').then((res) => {
      this.indicatorFormulaGoal = res.setting?.value === 'true';
    });
    this.data.plans.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));

    let currentIdx = 0;
    for (let i = 0; i < this.data.plans.length; i++) {
      if (!(this.planFormObjectiveGroups.controls.objectives as FormArray).at(currentIdx)) {
        (this.planFormObjectiveGroups.controls.objectives as FormArray).insert(currentIdx, new FormArray([]));
      }

      const formGroupToPush = this.genPlanGroup(this.data.plans[i].id);
      formGroupToPush.patchValue(this.data.plans[i]);
      formGroupToPush.controls.frequency.setValue(this.periodList.find((p) => p.value === this.data.plans[i].frequency));
      formGroupToPush.controls.execution.valueChanges.pipe(skip(1)).subscribe((exe) => {
        formGroupToPush.controls.resultStatus.setValue(
          calculateF2ResultStatus(exe, formGroupToPush.controls.goal.value !== null ? formGroupToPush.controls.goal.value : 3)
        );
      });

      if (!this.editable) {
        // formGroupToPush.disable();
        formGroupToPush.controls.frequency.disable();
        formGroupToPush.controls.execution.disable();
        formGroupToPush.controls.goal.disable();
      }

      // Verify if the goal has to be disabled
      const fgToPushControls = formGroupToPush.controls;
      if (this.data.plans[i].monthAvailability[this.actualMonth - 1].editable){
        fgToPushControls.execution.enable();
        fgToPushControls.goal.enable();
      } else {
        if (formGroupToPush.value.status !== 2){
          // fgToPushControls.goal.reset();
          fgToPushControls.goal.setValue(0);
          fgToPushControls.execution.setValue(0);
          fgToPushControls.execution.disable({ emitEvent: true });
          fgToPushControls.goal.disable({ emitEvent: true });
          // TODO: Optimize
          this.f2Service
              .updateF2Single({
                resultId: this.data.plans[i].resultId,
                resultStatus: this.data.plans[i].resultStatus,
                goal: 0,
                execution: 0,
                comment: '',
                observations:  this.data.plans[i].observations || '',
              }, !!this.data.plans[i].resultStatus ? undefined : 0)
              .then((r) => {
                this.activitiesIdsToUpdate.delete(this.data.plans[i].id);
                this.unsavedChanges.emit(false);
                setTimeout(() => (this.showSavingStatus = false), 2000);
              })
              .catch((error) => {
                this.toast.showError('Hubo un problema guardando la data de su F2. Intente nuevamente');
                this.unsavedChanges.emit(false);
              });
        }
      }
      // if ((fgToPushControls.status.value === 0 || fgToPushControls.status.value === 3) && this.authService.isDepartmentLeader ) {
      // If the goal value is not null...
      if (fgToPushControls.status.value === 2 && this.authService.isInstitutional) {
        fgToPushControls.goal.enable();
        fgToPushControls.execution.enable();
      } else if ((fgToPushControls.status.value === 1 || fgToPushControls.status.value === 2)
          && (this.authService.isDepartmentLeader || this.authService.isUnitLeader)) {
        fgToPushControls.goal.disable();
        fgToPushControls.execution.disable();
      }

      fgToPushControls.goal.valueChanges.subscribe((goal) => {
        fgToPushControls.resultStatus.setValue(calculateF2ResultStatus(fgToPushControls.execution.value, goal));
      });

      // Finally the activity formGroup is added to objective
      ((this.planFormObjectiveGroups.controls.objectives as FormArray).at(currentIdx) as FormArray).push(formGroupToPush);

      // Si no es el último y el que viene es otro objetivo, cambio de index
      if (i + 1 !== this.data.plans.length && this.data.plans[i].index !== this.data.plans[i + 1].index) {
        currentIdx++;
      }
    }

    this.objectiveGroupsGet.controls.forEach((obj) => {
      (obj as FormArray).controls.forEach((act) =>
        act.valueChanges
          .pipe(
            takeUntil(this.destroyed$),
            tap((activity) => {
              this.showSavingStatus = true;
              this.unsavedChanges.emit(true);
              this.activitiesIdsToUpdate.add(activity.id);
            }),
            debounceTime(6000)
          )
          .subscribe((activity) => this.updateActivityAutoSave(activity))
      );
    });
  }

  getF2GoalColor(resultStatus, objective) {
    const frequency = objective.value.frequency?.value;
    if (objective.value.goal === 0 && objective.value.execution === 0 && objective.value.resultStatus === 0) {
      return getF2GoalColor(2);
    }

    if (resultStatus === null) {
      return null;
    }

    switch (frequency) {
      // Semestral
      case 1:
        if (objective.value.results[5] !== null && this.actualMonth === 6) {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results[11] !== null && this.actualMonth === 12) {
          return getF2GoalColor(resultStatus);
        }
        return 'a507-f2-legend--inProgress';
      // Anual
      case 2:
        if (objective.value.results[11] !== null && this.actualMonth === 12) {
          return getF2GoalColor(resultStatus);
        }
        return 'a507-f2-legend--inProgress';
      // Trimestral
      case 3:
        if (objective.value.results[2] !== null && this.actualMonth === 3) {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results[5] !== null && this.actualMonth === 6) {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results[8] !== null && this.actualMonth === 9) {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results[11] !== null && this.actualMonth === 12) {
          return getF2GoalColor(resultStatus);
        }
        return 'a507-f2-legend--inProgress';
      // Bimestral
      case 4:
        if (objective.value.results[1] !== null && this.actualMonth === 2) {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results[3] !== null && this.actualMonth === 4) {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results[5] !== null && this.actualMonth === 6) {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results[7] !== null && this.actualMonth === 8) {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results[9] !== null && this.actualMonth === 10) {
          return getF2GoalColor(resultStatus);
        } else if (objective.value.results[11] !== null && this.actualMonth === 12) {
          return getF2GoalColor(resultStatus);
        }
        return 'a507-f2-legend--inProgress';
      default:
        return getF2GoalColor(resultStatus);
    }

    // return getF2GoalColor(resultStatus || 0);
  }

  get getF2Status() {
    return this.getSingleObjectiveFromGroup(0)?.at(0).value.status;
  }

  // Handling the Indicator Formula Module
  saveF2() {
    this.sendingData = true;
    const resultsToUpdate = [];
    this.objectiveGroupsGet.value.forEach((obj) => {
      obj.forEach((act) =>
        resultsToUpdate.push({
          resultId: act.resultId,
          goal: act.indicator.goal !== null && this.indicatorFormulaGoal === true && this.checkAnnualException(act) ? act.indicator.goal : act.goal,
          execution: act.execution,
          observations: act.observations,
          comment: '',
        })
      );
    });

    this.f2Service
      .updateF2stoSent(resultsToUpdate)
      .then((r) => {
        this.toast.showSuccess('F2 Enviada correctamente');
        setTimeout(() => {
          if (this.authService.isDepartmentLeader) {
            this.router.navigate([`department/f2/${this.data.f1Id}`]);
          } else {
            this.router.navigate([`unit/f2/${this.data.f1Id}`]);
          }
        }, 2000);
      })
      .catch((error) => {
        this.toast.showError('Hubo un problema cargando la data de su F2. Intente nuevamente');
      })
      .finally(() => {
        this.sendingData = false;
      });
  }

  updateActivityAutoSave(activity) {
    const activityToSave = {
      resultId: activity.resultId,
      resultStatus: activity.resultStatus,
      goal: activity.indicator.goal !== null && this.indicatorFormulaGoal === true
      && (activity.monthAvailability[this.actualMonth - 1].editable || this.getF2Status !== 2)
      && this.checkAnnualException(activity) ? activity.indicator.goal : activity.goal,
      execution: activity.execution,
      comment: activity.comment,
      observations: activity.observations,
    };
    const status = activity.status;

    this.f2Service
      .updateF2Single(activityToSave, status)
      .then((r) => {
        this.activitiesIdsToUpdate.delete(activity.id);
        this.unsavedChanges.emit(false);
        setTimeout(() => (this.showSavingStatus = false), 2000);
      })
      .catch((error) => {
        this.toast.showError('Hubo un problema guardando la data de su F2. Intente nuevamente');
        this.unsavedChanges.emit(false);
      });
  }

  returnF2s() {
    this.sendingData = true;
    this.modalService.openModal(
      'comment-modal',
      {
        accept: (message) => {
          this.sendingData = true;
          const resultsToUpdate = [];
          this.objectiveGroupsGet.value.forEach((obj) => {
            obj.forEach((act) =>
              resultsToUpdate.push({
                resultId: act.resultId,
                goal: act.indicator.goal ? act.indicator.goal : act.goal,
                execution: act.execution,
                observations: act.observations,
                comment: message,
              })
            );
          });

          this.f2Service
            .returnF2s(resultsToUpdate)
            .then((res) => {
              this.toast.showSuccess('Se ha devuelto de manera exitosa');
              setTimeout(() => {
                if (this.authService.isDepartmentLeader) {
                  this.router.navigate([`department/f2`]);
                } else {
                  this.router.navigate([`unit/f2`]);
                }
              }, 2000);
            })
            .catch((err) => {
              // console.log(err);
              this.toast.showError('Ha ocurrido un error al devolver el F2');
            })
            .finally(() => {
              this.sendingData = false;
            });
        },
      },
      'normal'
    );
  }

  addNote(observationController: AbstractControl) {
    this.modalService.openModal(
      'observation-modal',
      {
        observations: observationController.value,
        editable: this.getF2Status !== 2 || this.authService.isInstitutional,
        accept: (message) => {
          if (this.getF2Status !== 2 || this.authService.isInstitutional) {
            observationController.setValue(message);
          }
        },
      },
      'normal'
    );
  }

  async approveF2s() {
    this.sendingData = true;

    const resultsToUpdate = [];
    this.objectiveGroupsGet.value.forEach((obj) => {
      obj.forEach((act) =>
        resultsToUpdate.push({
          resultId: act.resultId,
          goal: act.indicator.goal ? act.indicator.goal : act.goal,
          execution: act.execution,
          observations: act.observations,
          comment: '',
        })
      );
    });

    await this.f2Service.updateF2s(resultsToUpdate);
    this.f2Service
      .approveF2s(resultsToUpdate)
      .then((r) => {
        this.toast.showSuccess('F2 Enviada correctamente');
        setTimeout(() => {
          if (this.authService.isDepartmentLeader) {
            this.router.navigate([`department/f2/${this.data.f1Id}`]);
          } else {
            this.router.navigate([`unit/f2/${this.data.f1Id}`]);
          }
        }, 2000);
      })
      .catch((error) => {
        this.toast.showError('Hubo un problema cargando la data de su F2. Intente nuevamente');
      })
      .finally(() => {
        this.sendingData = false;
      });
  }

  getObjectivesLength() {
    return (this.planFormObjectiveGroups.controls.objectives as FormArray).length;
  }

  async export() {
    if (this.getF2Status === 0){
      this.toast.showInfo('Debes llenar el mes para poder exportarlo, una vez que lo hagas recarga la página', 5000);
    } else {
      this.f3sObjectives = await this.f3service.getF3Objectives(this.data.f1.id);
      const dataForPdf = await this.generateDataForPdf();
      this.pdfService.generateF1MonthlyOverviewPDF(
          this.data,
          dataForPdf,
          this.f3sObjectives,
          this.actualMonth,
          this.initial,
          this.final,
          this.forcedDate,
          this.approvalBy,
          this.leftFooter,
          this.annualConstants,
          this.director
      );
      this.initial = null;
      this.final = null;
      this.forcedDate = null;
    }
  }

  async generateDataForPdf() {
    const gen = (objId) => {
      const ng = new FormGroup({
        id: new FormControl(objId),
        objectives: new FormControl('', [Validators.required, Validators.minLength(0)]),
        activities: new FormControl('', [Validators.required, Validators.minLength(0)]),
        results: new FormGroup({
          ene: new FormControl('', []),
          feb: new FormControl('', []),
          mar: new FormControl('', []),
          abr: new FormControl('', []),
          may: new FormControl('', []),
          jun: new FormControl('', []),
          jul: new FormControl('', []),
          ago: new FormControl('', []),
          sep: new FormControl('', []),
          oct: new FormControl('', []),
          nov: new FormControl('', []),
          dic: new FormControl('', []),
        }),
        monthAvailability: new FormControl(null, []),
      });
      return ng;
    };

    const objectives = await this.f2Service.getF1Objectives(this.data.f1Id);
    // console.log(objectives);
    const f1ToList = objectives.rows.map((obj, index) => ({
      id: obj.id,
      index: obj.index,
      objectives: obj.title,
      activities: obj.activities,
      createdBy: obj.createdBy,
      createdById: obj.createdById,
      results: this.fillResults(obj.results),
      indicator: {
        upperText: obj.indicatorUpperText,
        bottomText: obj.indicatorBottomText,
        goal: obj.indicatorGoal,
      },
      monthAvailability: obj.monthAvailability,
      annualGoal: obj.annualGoal,
      frequency: obj.frequency,
      status: obj.results.length ? obj.results[0].status : null,
      endDate: obj.endDate
        ? `${new Date(obj.endDate).getFullYear()}-${
            new Date(obj.endDate).getMonth() + 1 < 10
              ? '0' + (new Date(obj.endDate).getMonth() + 1)
              : new Date(obj.endDate).getMonth() + 1
          }-${new Date(obj.endDate).getDate() < 10 ? '0' + new Date(obj.endDate).getDate() : new Date(obj.endDate).getDate()}`
        : null,
    }));

    f1ToList.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));

    const form = this.fb.group({ objectives: new FormArray([]) });
    let currentIdx = 0;
    for (let i = 0; i < f1ToList.length; i++) {
      if (!(form.controls.objectives as FormArray).at(currentIdx)) {
        (form.controls.objectives as FormArray).insert(currentIdx, new FormArray([]));
      }

      const formGroupToPush = gen(f1ToList[i].id);
      formGroupToPush.patchValue(f1ToList[i]);
      ((form.controls.objectives as FormArray).at(currentIdx) as FormArray).push(formGroupToPush);

      // Si no es el último y el que viene es otro objetivo, cambio de index
      if (i + 1 !== f1ToList.length && f1ToList[i].index !== f1ToList[i + 1].index) {
        currentIdx++;
      }
    }

    return form.value;
  }

  fillResults(results: any[]) {
    const RESULTS_ARRAY = {
      ene: null,
      feb: null,
      mar: null,
      abr: null,
      may: null,
      jun: null,
      jul: null,
      ago: null,
      sep: null,
      oct: null,
      nov: null,
      dic: null,
    };
    const resArray = Object.keys(RESULTS_ARRAY);
    const objArr = {};
    resArray.forEach((r, index) => {
      const monthVal = results.find((a) => Number(a.month) - 1 === index);
      objArr[r] = monthVal
        ? {
            resultStatus: monthVal.resultStatus,
            goal: monthVal.goal,
            execution: monthVal.execution,
            observations: this.actualMonth
              ? this.actualMonth - 1 === index
                ? monthVal.observations
                : ''
              : monthVal.observations,
          }
        : null;
    });
    return objArr;
  }

  get formInvalid(): boolean {
    return (this.planFormObjectiveGroups.controls.objectives as FormArray).invalid;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
  }

  get canReturnF2(): boolean {
    if (this.authService.isUnitLeader) {
      return this.getF2Status === F2Status.SENT;
    } else if (this.authService.isInstitutional) {
      return this.getF2Status === F2Status.SENT || this.getF2Status === F2Status.TO_REVIEW;
    } else {
      return false;
    }
  }

  private checkAnnualException(activity: any): boolean {
    if (activity.frequency?.value !== 2){
      return true;
    }
    // tslint:disable-next-line:triple-equals
    return this.actualMonth === 12 && activity.frequency?.value === 2 && this.data.f1.plan.year == 2022;
  }
}
