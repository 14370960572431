import { ToastService } from 'src/app/services/toast.service';
import { F1Service } from './../../../services/f1.service';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OperativePlanService } from 'src/app/services/operative-plan.service';
import { OPTableModel } from './OpTable';
import { PopoverController } from '@ionic/angular';
import { PopoverSelectCreatedByPageComponent } from '../../modals/popover-select-createdBy-page/popover-select-createdBy-page.component';
import {AuthService} from '../../../services/auth.service';
import * as moment from 'moment';

moment.locale('es-us');

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'op-table',
  templateUrl: './op-table.component.html',
  styleUrls: ['./op-table.component.scss'],
})
export class OpTableComponent implements OnInit {
  @Input() dataTable: OPTableModel;
  @Input() routerDestination: string;
  @Input() oPlanStatus = false;
  @Input() routerDestinatioAttrb = 'id';
  @Input() canChangeF1Indexes: boolean;
  @Input() canChangeOpePlanIndexes: boolean;
  @Input() currentApprovableMonth: { label: string, value: string };
  @Output() notifyParent: EventEmitter<any> = new EventEmitter(false);

  columnMode = 'flex';
  showTable: boolean;

  constructor(
    public opService: OperativePlanService,
    public f1Service: F1Service,
    public toast: ToastService,
    private router: Router,
    private popoverController: PopoverController,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.columnMode = 'flex';
    this.showTable = true;

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.showTable = false;
        setTimeout(() => this.showTable = true, 100);
      }
    });
  }

  async presentPopover(ev: any, fId: string, isDirector?: boolean, isF1?: boolean, isDate?: boolean, isFirstSemester?: boolean, isF3?: boolean, year?: string, isF2?: boolean) {

    // console.log(this.dataTable.dataRows);
    const popover = await this.popoverController.create({
      component: PopoverSelectCreatedByPageComponent,
      cssClass: 'header-popover noLogout',
      event: ev,
      translucent: true,
      mode: 'ios',
      componentProps: { fId, isDirector, isF1, isDate, isFirstSemester, isF3, year, isF2 }
    });
    await popover.present();

    const { data, role } = await popover.onDidDismiss();

    if (!role) {
      this.notifyParent.emit(true);
    }
  }

  onClickRow(event: any) {
    if (event.type === 'click') {
      const rowActualIndex = this.dataTable.dataRows.findIndex(row => row.id === event.row.id);
      const rowNext = this.dataTable.dataRows[rowActualIndex + 1];
      const rowPrev = this.dataTable.dataRows[rowActualIndex - 1];

      const isFirst = rowActualIndex === 0;
      const isLast = rowActualIndex === this.dataTable.dataRows.length - 1;

      // console.log(event.row);

      const isFromAPreviousYear = (period: any = event.row.period): boolean => {
        return period && !period.includes(moment().format('YYYY'));
      };

      if (this.currentApprovableMonth && event.row.f2.month > +this.currentApprovableMonth.value && !isFromAPreviousYear()){
        this.toast.showError('El mes de ' + this.currentApprovableMonth.label + ' aún debe ser aprobado');
        return;
      }

      if (event.column?.name === 'updatedByName') {
        if (event.row.status === 2 && this.authService.isInstitutional){
          this.presentPopover(null, event.row.f2.id, false, false, false, false, false, undefined, true);
        } else {
          if (this.routerDestinatioAttrb === 'f2'){
            this.router.navigate([`${this.routerDestination}/${event.row.id}/${event.row.unitId}`]);
          } else {
            this.router.navigate([`${this.routerDestination}/${event.row[this.routerDestinatioAttrb]}`]);
          }
        }
      } else if (event.column?.name === 'updatedByDirector') {
        if (event.row.status === 2 && this.authService.isInstitutional){
          this.presentPopover(null, event.row.f2.id, true, false, false, false, false, undefined, true);
        } else {
          if (this.routerDestinatioAttrb === 'f2'){
            this.router.navigate([`${this.routerDestination}/${event.row.id}/${event.row.unitId}`]);
          } else {
            this.router.navigate([`${this.routerDestination}/${event.row[this.routerDestinatioAttrb]}`]);
          }
        }
      } else if (event.column?.name === 'responsableName') {
        if (this.authService.isInstitutional){
          this.presentPopover(null, event.row.unitId, true, true, false, true, false, event.row.period);
        } else {
          this.router.navigate([`${this.routerDestination}/${event.row[this.routerDestinatioAttrb]}`]);
        }
      }  else if (event.column?.name === 'responsableName2') {
        if (this.authService.isInstitutional){
          this.presentPopover(null, event.row.unitId, true, true, false, false, false, event.row.period);
        } else {
          this.router.navigate([`${this.routerDestination}/${event.row[this.routerDestinatioAttrb]}`]);
        }
      } else if (event.column?.name === 'sendToFirstRevisionAt') {
        if (this.authService.isInstitutional){
          this.presentPopover(null, event.row.f1.id, true, true, true);
        } else {
          this.router.navigate([`${this.routerDestination}/${event.row[this.routerDestinatioAttrb]}`]);
        }
      } else if (event.column?.name === 'f3Sem1CreatedName') {
        if (this.authService.isInstitutional){
          this.presentPopover(null, event.row.f1.id, true, false, false, true, true);
        } else {
          this.router.navigate([`${this.routerDestination}/${event.row[this.routerDestinatioAttrb]}`]);
        }
      } else if (event.column?.name === 'f3Sem2CreatedName') {
        if (this.authService.isInstitutional){
          this.presentPopover(null, event.row.f1.id, true, false, false, false, true);
        } else {
          this.router.navigate([`${this.routerDestination}/${event.row[this.routerDestinatioAttrb]}`]);
        }
      } else if (event.column?.name === 'directorName') {
        if (this.authService.isInstitutional){
          this.presentPopover(null, event.row.f1.unitId, true, true, false, false, false, event.row.period);
        } else {
          this.router.navigate([`${this.routerDestination}/${event.row[this.routerDestinatioAttrb]}`]);
        }
      } else if (event.column?.name === 'position-up') {
        if (!isFirst) {
          if (this.canChangeF1Indexes) {
            // Change in bdd
            this.f1Service.changeF1sIndexes(
              { f1s: [{ id: event.row.f1.id, index: rowActualIndex - 1 }, { id: rowPrev.f1.id, index: rowActualIndex }] },
              event.row.f1.planId
            ).catch(err => this.toast.showError('Ha ocurrido un error al cambiar de posición el f1'));
            // Change local
            this.dataTable.dataRows[rowActualIndex] = this.dataTable.dataRows
              .splice(rowActualIndex - 1, 1, this.dataTable.dataRows[rowActualIndex])[0];
            this.dataTable.dataRows = [...this.dataTable.dataRows];
          } else if (this.canChangeOpePlanIndexes) {
            // Change in bdd
            this.opService.changeOpePlansIndexes(
              { plans: [{ id: event.row.id, index: rowActualIndex - 1 }, { id: rowPrev.id, index: rowActualIndex }] }
            ).catch(err => this.toast.showError('Ha ocurrido un error al cambiar de posición el f1'));
            // Change local
            this.dataTable.dataRows[rowActualIndex] = this.dataTable.dataRows
              .splice(rowActualIndex - 1, 1, this.dataTable.dataRows[rowActualIndex])[0];
            this.dataTable.dataRows = [...this.dataTable.dataRows];
          }
        }
      } else if (event.column?.name === 'position-down') {
        if (!isLast) {
          if (this.canChangeF1Indexes) {
            this.f1Service.changeF1sIndexes(
              { f1s: [{ id: event.row.f1.id, index: rowActualIndex + 1 }, { id: rowNext.f1.id, index: rowActualIndex }] },
              event.row.f1.planId
            ).catch(err => this.toast.showError('Ha ocurrido un error al cambiar de posición el f1'));
            // Change local
            this.dataTable.dataRows[rowActualIndex] = this.dataTable.dataRows
              .splice(rowActualIndex + 1, 1, this.dataTable.dataRows[rowActualIndex])[0];
            this.dataTable.dataRows = [...this.dataTable.dataRows];
          } else if (this.canChangeOpePlanIndexes) {
            // Change in bdd
            this.opService.changeOpePlansIndexes(
              { plans: [{ id: event.row.id, index: rowActualIndex + 1 }, { id: rowNext.id, index: rowActualIndex }] }
            ).catch(err => this.toast.showError('Ha ocurrido un error al cambiar de posición el f1'));
            // Change local
            this.dataTable.dataRows[rowActualIndex] = this.dataTable.dataRows
              .splice(rowActualIndex + 1, 1, this.dataTable.dataRows[rowActualIndex])[0];
            this.dataTable.dataRows = [...this.dataTable.dataRows];
          }
        }
      } else {
        if (this.routerDestinatioAttrb === 'f2'){
          this.router.navigate([`${this.routerDestination}/${event.row.id}/${event.row.unitId}`]);
        } else {
          this.router.navigate([`${this.routerDestination}/${event.row[this.routerDestinatioAttrb]}`]);
        }
      }
    }
  }
}
