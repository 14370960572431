import { ToastService } from 'src/app/services/toast.service';
import { OperativePlanService } from 'src/app/services/operative-plan.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { getError } from 'src/app/models/Errors';
import {AuthService} from '../../../services/auth.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'generate-plans-modal',
  templateUrl: './generate-plans-modal.component.html',
  styleUrls: ['./generate-plans-modal.component.scss'],
})
export class GeneratePlansModalComponent implements OnInit {
  @Input() data: Observable<any>;
  @Output() innerClose = new EventEmitter();

  yearsList = [
    { value: 2019, label: '2019' },
    { value: 2020, label: '2020' },
    { value: 2021, label: '2021' },
    { value: 2022, label: '2022' },
    { value: 2023, label: '2023' },
    { value: 2024, label: '2024' },
    { value: 2025, label: '2025' },
    { value: 2026, label: '2026' },
    { value: 2027, label: '2027' },
    { value: 2028, label: '2028' },
    { value: 2029, label: '2029' },
    { value: 2030, label: '2030' },
    { value: 2031, label: '2031' },
    { value: 2032, label: '2032' },
    { value: 2033, label: '2033' },
    { value: 2034, label: '2034' },
    { value: 2035, label: '2035' },
  ];
  yearSelected: any;

  constructor(
    private opePlanService: OperativePlanService,
    private toast: ToastService,
    private authService: AuthService
  ) { }

  ngOnInit() { }

  async generate() {
    try {
      await this.opePlanService.generateAnualPlan(this.yearSelected.value);
      this.toast.showSuccess('Planes operativos generados con éxito');
      this.close();
      if (this.authService.currentUserValue.role === 2){
        setTimeout( () => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      this.toast.showError(getError(error));
      this.close();
    }
  }

  close() {
    this.innerClose.emit(true);
  }
}
