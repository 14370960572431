import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../models/User';
import CryptoJS from 'crypto-es';
import { Observable } from 'rxjs';

function validURL(textval) {
  var urlregex = new RegExp(
    "(https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)"
  );
  return urlregex.test(textval);
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http: HttpClient
  ) { }

  async getAllUsers(): Promise<User[]> {
    const res = await this.http.get<any>(environment.apiUrl + '/api/v1/users?paginated=false').toPromise();
    return res.users;
  }

  // Users with role 1
  async getAllNormal(): Promise<User[]> {
    const res = await this.http.get<any>(environment.apiUrl + '/api/v1/users?paginated=false').pipe(map(r => {
      return r.users.filter(a => a.role === 1);
    })).toPromise();
    return res;
  }

  async getAllNotLeaders(): Promise<User[]> {
    const res = await this.http.get<any>(environment.apiUrl + '/api/v1/users/not-leaders?paginated=false').toPromise();
    // console.log(res);
    return res.users;
  }

  async deleteUser(userId: number): Promise<any> {
    const res = await this.http.delete<any>(`${environment.apiUrl}/api/v1/user/${userId}`).toPromise();
    return res.users;
  }

  registerUser(user: any): Observable<any> {
    const newUser = {
      confirmedPassword: CryptoJS.HmacSHA512(user.password, environment.cryptoKey).toString(CryptoJS.enc.Hex),
      email: user.email,
      lastname: user.lastname,
      name: user.name,
      password: CryptoJS.HmacSHA512(user.password, environment.cryptoKey).toString(CryptoJS.enc.Hex),
      role: +user.role.value,
      photo: user.photo,
      units: user.units
    };

    if (newUser.units.length === 0) {
      delete newUser.units;
    }

    return this.http.post(environment.apiUrl + '/api/v1/user', newUser, { reportProgress: true, observe: 'events' })
      .pipe(map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: progress };
          case HttpEventType.Response:
            return event.body;
          default:
            return `Unhandled event: ${event.type}`;
        }
      }));
  }

  async getUser(userId: number): Promise<User> {
    const res = await this.http.get<any>(environment.apiUrl + '/api/v1/user/' + userId).toPromise();
    return res.user;
  }

  updateUser(userId: number, user: any): Observable<any> {
    const editedDeputy = {
      confirmedPassword: CryptoJS.HmacSHA512(user.password, environment.cryptoKey).toString(CryptoJS.enc.Hex),
      email: user.email,
      gender: 'M',
      lastname: user.lastname,
      name: user.name,
      password: CryptoJS.HmacSHA512(user.password, environment.cryptoKey).toString(CryptoJS.enc.Hex),
      role: user.role.value,
      photo: user.photo,
      units: user.units
    };


    if (user.password === '') {
      delete editedDeputy.confirmedPassword;
      delete editedDeputy.password;
    }

    if (user.photo === '') {
      delete editedDeputy.photo;
    }

    if (validURL(user.photo)) {
      delete editedDeputy.photo;
    }


    return this.http.put(`${environment.apiUrl}/api/v1/user/${userId}`, editedDeputy, { reportProgress: true, observe: 'events' })
      .pipe(map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: progress };
          case HttpEventType.Response:
            return event.body;
          default:
            return `Unhandled event: ${event.type}`;
        }
      }))
  }
}
