import { F2Status } from './../../../../models/F2';
import { OperativePlanService } from './../../../../services/operative-plan.service';
import { Component, OnInit } from '@angular/core';
import { f2ArrayToDatatable, OPTableModel } from 'src/app/components/table/op-table/OpTable';
import { F2, getDistinctF2sByMonth, getDistinctF2sByMonthByF } from 'src/app/models/F2';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { F2Service } from 'src/app/services/f2.service';
import { PdfService } from '../../../../services/pdf.service';
import { F1Service } from 'src/app/services/f1.service';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { F3Service } from 'src/app/services/f3.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { CloseIconService } from '../../../../services/close-icon.service';
import { PopoverController } from '@ionic/angular';
import { PopoverPdfPageComponent } from '../../../../components/modals/popover-pdf-page/popover-pdf-page.component';
import { UnitsService } from 'src/app/services/units.service';
import { SettingsService } from 'src/app/services/settings.service';
import { ToastService } from 'src/app/services/toast.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import {YearConstant} from '../../../../models/Setting';

@Component({
  selector: 'app-unit-f2-home',
  templateUrl: './unit-f2-home.component.html',
  styleUrls: ['./unit-f2-home.component.scss'],
})
export class UnitF2HomeComponent implements OnInit {
  private destroyed$ = new Subject<boolean>();

  user: User;
  f2s: F2[];

  dataTable: OPTableModel;
  needInstituionalApprovement: boolean;
  // actualYear = { label: '2025', value: '2025' };
  actualYear = { label: new Date().getUTCFullYear().toString(), value: new Date().getUTCFullYear().toString() };
  yearsList: { label: string; value: string }[] = [];
  actualMonth: { label: string; value: string };
  monthsList: { label: string; value: string }[] = [
    { value: '1', label: 'Enero' },
    { value: '2', label: 'Febrero' },
    { value: '3', label: 'Marzo' },
    { value: '4', label: 'Abril' },
    { value: '5', label: 'Mayo' },
    { value: '6', label: 'Junio' },
    { value: '7', label: 'Julio' },
    { value: '8', label: 'Agosto' },
    { value: '9', label: 'Septiembre' },
    { value: '10', label: 'Octubre' },
    { value: '11', label: 'Noviembre' },
    { value: '12', label: 'Diciembre' },
  ];

  f2sByUnitDepartment: F2[];
  f2Id: number;
  unitName: string;

  private initial = null;
  private final = null;
  private leftFooter = '';
  private forcedDate = null;
  private approvalBy = null;
  private approvalId = null;
  toBeApprovedMonth: { label: string; value: string };
  toBeFilledMonth: { label: string; value: string };
  lastApproved: { label: string; value: string };
  private annualConstants: YearConstant = null;
  private director: any;

  constructor(
    public authService: AuthService,
    private f2Service: F2Service,
    private f1Service: F1Service,
    private f3Service: F3Service,
    private opService: OperativePlanService,
    private unitsService: UnitsService,
    private pdfService: PdfService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private closeIcon: CloseIconService,
    private popoverController: PopoverController,
    private preferencesService: SettingsService,
    private toast: ToastService,
    private modalService: ModalService,
    private settingsService: SettingsService,
  ) {}

  async presentPopover(ev: any) {
    if (!this.annualConstants){
     this.toast.showError(`No es posible generar este PDF debido a que este Plan Operativo de ${this.actualYear.value} no tiene su respectivo pie de página configurado. Comunícate con un administrador.`, 5000);
      return;
    }
    const distinctF2s = getDistinctF2sByMonth(this.f2s);
    const newF2s = JSON.parse(JSON.stringify(distinctF2s));
    newF2s.sort((a, b) => {
      return new Date(b.approvalAt).getTime() - new Date(a.approvalAt).getTime();
    });

    // console.log(this.dataTable.dataRows);
    const popover = await this.popoverController.create({
      component: PopoverPdfPageComponent,
      cssClass: 'header-popover noLogout',
      event: ev,
      translucent: true,
      mode: 'ios',
      componentProps: {
        type: 'f2', approvalDate: newF2s[0].approvalAt,
        approvalBy: this.dataTable.dataRows[0].updatedBy,
        leftFooter: this.annualConstants.f2Footer,
        director: this.dataTable.dataRows[0].director
      },
    });
    await popover.present();
    const { data, role } = await popover.onDidDismiss();
    this.initial = data?.initial;
    this.final = data?.final;
    this.leftFooter = data?.leftFooter;
    this.forcedDate = data?.forcedDate;
    this.approvalBy = data?.approvalBy;
    this.approvalId = data?.approvalById;
    this.director = data?.director;
    // if (this.forcedDate) {
    //   for (const f2 of this.dataTable.dataRows) {
    //     await this.f2Service.updateApprovalAtF2(f2.f2.id, this.forcedDate, this.approvalId);
    //   }
    // }

    this.initialSetup(true);

    if (!role) {
      this.export();
    }
  }

  async ngOnInit() {
    this.preferencesService.getByKeySetting('needInstitutionalApprovement').then((res) => {
      this.needInstituionalApprovement = res.setting.value === 'true' ? true : false;
    });

    this.initialSetup();
  }

  async ionViewDidEnter() {
    this.initialSetup();
  }

  async initialSetup(dontRefresh = false) {
    // console.log('is this actual', this.actualMonth);
    await this.settingsService.getAnnualConstants(this.actualYear.value).then((res) => {
      this.annualConstants = res.code;
    }).catch((err) => {
     this.toast.showError(`No es posible generar este PDF debido a que este Plan Operativo de ${this.actualYear.value} no tiene su respectivo pie de página configurado. Comunícate con un administrador.`, 5000);
    });
    this.activatedRoute.params.subscribe(async (params) => {
      this.f2Id = +params.f2Id;
      this.user = this.authService.currentUserValue;
      if (this.f2Id) {
        this.closeIcon.open();
        this.f2s = await this.f2Service.getUnitF2sByYear(this.f2Id, this.actualYear.value);
        this.unitName = (await this.unitsService.getUnit(this.f2Id)).name;
        this.user.unitLeader = { id: 0, name: '', leaderId: 0 };
        this.user.unitLeader.id = this.f2Id;
      } else {
        this.f2s = await this.f2Service.getUnitF2sByYear(this.user.unitLeader.id, this.actualYear.value);
      }
      // this.toPDF = await this.opePlanService.getOperativePlan(this.planId);
      // this.updateF1s();
      const distinctF2s = getDistinctF2sByMonth(this.f2s);
      this.f2sByUnitDepartment = this.filterF2sByDepartmentUnit(distinctF2s);

      this.dataTable = {
        header: [
          { displayValue: 'Período', value: 'period' },
          { displayValue: 'Departamento', value: 'deparmentInfo' },
          { displayValue: 'Responsable', value: 'updatedByDirector' },
          { displayValue: 'Elaborado por', value: 'updatedByName' },
          { displayValue: 'Fecha de 1ra revisión', value: 'sendToFirstRevisionAt' },
          { displayValue: 'Fecha de aprobación', value: 'approvalAt' },
          { displayValue: 'Estatus', value: 'status' },
        ],
        dataRows: [],
      };

      if (dontRefresh) {
        this.selectedMonthFilter(this.actualMonth);
      } else {
        this.toBeApprovedMonth = this.monthsList[+distinctF2s[distinctF2s.reverse()
            .findIndex( (currF2) =>  currF2.status !== 2 )]?.month - 1];
        this.lastApproved = this.monthsList[+distinctF2s[distinctF2s.reverse()
            .findIndex( (currF2) =>  currF2.status === 2 )]?.month - 1];
        this.toBeFilledMonth = this.monthsList[+distinctF2s[distinctF2s.reverse()
            .findIndex( (currF2) =>  currF2.status === 0 )]?.month - 1];
        if (this.toBeApprovedMonth === undefined && this.lastApproved) {
          this.toBeApprovedMonth = this.monthsList[+this.lastApproved.value - 1];
        }
        this.selectedMonthFilter(this.toBeApprovedMonth);
      }

      this.dataTable.dataRows.sort((a, b) =>
        a.f2.objective.f1.index > b.f2.objective.f1.index ? 1 : b.f2.objective.f1.index > a.f2.objective.f1.index ? -1 : 0
      );
      this.dataTable.dataRows.sort((a, b) => (a.f2.month > b.f2.month ? -1 : b.f2.month > a.f2.month ? 1 : 0));
      this.dataTable.dataRows.sort((a, b) =>
        a.f2.objective.f1.plan.year > b.f2.objective.f1.plan.year
          ? 1
          : b.f2.objective.f1.plan.year > a.f2.objective.f1.plan.year
          ? -1
          : 0
      );

      this.yearsList = (await this.opService.getAllYears()).map((year) => ({ label: year.toString(), value: year.toString() }));
      // console.log(this.yearsList);
    });
  }

  filterF2sByDepartmentUnit(filterF2sByDepartmentUnit: F2[]): F2[] {
    const f2s = [];
    for (const f2 of filterF2sByDepartmentUnit) {
      if (
        !f2s.find((f2aux) => f2aux.objective.f1.unitId === f2.objective.f1.unitId) ||
        !f2s.find((f2aux) => f2aux.objective.f1.departmentId === f2.objective.f1.departmentId)
      ) {
        f2s.push(f2);
      }
    }
    return f2s;
  }
  async selectedYearFilter(data: { label: string; value: string }) {
    this.actualYear = data;
    if (data) {
      this.actualMonth = null;
      this.f2s = await this.f2Service.getUnitF2sByYear(this.user.unitLeader.id, this.actualYear.value, this.actualMonth?.value);
      await this.settingsService.getAnnualConstants(this.actualYear.value).then((res) => {
        this.annualConstants = res.code;
      }).catch((err) => {
       this.toast.showError(`No es posible generar este PDF debido a que este Plan Operativo de ${this.actualYear.value} no tiene su respectivo pie de página configurado. Comunícate con un administrador.`, 5000);
      });
      const distinctF2s = getDistinctF2sByMonth(this.f2s);
      this.f2sByUnitDepartment = this.filterF2sByDepartmentUnit(distinctF2s);
      this.dataTable.dataRows = f2ArrayToDatatable(distinctF2s, this.actualYear.value);

      this.dataTable.dataRows.sort((a, b) =>
        a.f2.objective.f1.index > b.f2.objective.f1.index ? 1 : b.f2.objective.f1.index > a.f2.objective.f1.index ? -1 : 0
      );
      this.dataTable.dataRows.sort((a, b) => (a.f2.month > b.f2.month ? -1 : b.f2.month > a.f2.month ? 1 : 0));
      this.dataTable.dataRows.sort((a, b) =>
        a.f2.objective.f1.plan.year > b.f2.objective.f1.plan.year
          ? 1
          : b.f2.objective.f1.plan.year > a.f2.objective.f1.plan.year
          ? -1
          : 0
      );
    }
  }

  async selectedMonthFilter(data: { label: string; value: string }) {
    this.actualMonth = data;
    this.f2s = await this.f2Service.getUnitF2sByYear(this.user.unitLeader.id, this.actualYear.value, this.actualMonth?.value);
    const distinctF2s = getDistinctF2sByMonth(this.f2s);
    this.f2sByUnitDepartment = this.filterF2sByDepartmentUnit(distinctF2s);
    this.dataTable.dataRows = f2ArrayToDatatable(distinctF2s, this.actualYear.value);

    this.dataTable.dataRows.sort((a, b) =>
      a.f2.objective.f1.index > b.f2.objective.f1.index ? 1 : b.f2.objective.f1.index > a.f2.objective.f1.index ? -1 : 0
    );
    this.dataTable.dataRows.sort((a, b) => (a.f2.month > b.f2.month ? -1 : b.f2.month > a.f2.month ? 1 : 0));
    this.dataTable.dataRows.sort((a, b) =>
      a.f2.objective.f1.plan.year > b.f2.objective.f1.plan.year
        ? 1
        : b.f2.objective.f1.plan.year > a.f2.objective.f1.plan.year
        ? -1
        : 0
    );
  }

  async export() {
    if ((this.actualMonth && this.toBeFilledMonth === undefined)
        || (this.actualMonth && this.toBeFilledMonth && (+this.actualMonth?.value < +this.toBeFilledMonth?.value) )
        || (+this.actualYear.value < new Date().getUTCFullYear())){
      const f2s = [];

      this.f2sByUnitDepartment.sort((a, b) =>
          a.objective.f1.index > b.objective.f1.index ? 1 : b.objective.f1.index > a.objective.f1.index ? -1 : 0
      );
      this.f2sByUnitDepartment.sort((a, b) => (a.month > b.month ? -1 : b.month > a.month ? 1 : 0));
      this.f2sByUnitDepartment.sort((a, b) =>
          a.objective.f1.plan.year > b.objective.f1.plan.year ? 1 : b.objective.f1.plan.year > a.objective.f1.plan.year ? -1 : 0
      );

      for (const f2 of this.f2sByUnitDepartment) {
        const f3sObjectives = await this.f3Service.getF3Objectives(f2.objective.f1Id);
        const dataForPdf = await this.generateDataForPdf(f2);

        const plans = this.f2s.map((f22) => ({
          ...f22,
          indicator: {
            upperText: f22.objective.indicatorUpperText,
            bottomText: f22.objective.indicatorBottomText,
            goal: f22.objective.indicatorGoal,
          },
        }));
        const data = { plans, f1: f2.objective.f1, updatedBy: f2.updatedBy, approvalAt: f2.approvalAt, director: f2.director };
        f2s.push({ data, dataForPdf, f3sObjectives });
      }
      // tslint:disable-next-line:max-line-length
      this.pdfService.generateMultiF1MonthlyOverviewPDF(
          f2s,
          +this.actualMonth?.value || 12,
          this.initial,
          this.final,
          this.forcedDate,
          this.approvalBy,
          this.leftFooter,
          this.annualConstants,
          this.director
      );
      this.initial = null;
      this.final = null;
      this.forcedDate = null;
      // const fullF2sParsed = getDistinctF2sByMonthByF(this.f2s).map((f2Objs: any[]) => {
      //   return [this.f1Service.f2ObjectivesToObjectiveGroups(f2Objs[0])];
      // });

      // this.pdfService.generateMultiF2PDF(getDistinctF2sByMonthByF(this.f2s), fullF2sParsed, this.actualYear.label);
    } else {
      this.toast.showInfo('Debes llenar todos los meses, para poder imprimir todo el año', 5000);
    }
  }

  async generateDataForPdf(f2: F2) {
    const gen = (objId) => {
      const ng = new FormGroup({
        id: new FormControl(objId),
        objectives: new FormControl('', [Validators.required, Validators.minLength(0)]),
        activities: new FormControl('', [Validators.required, Validators.minLength(0)]),
        results: new FormGroup({
          ene: new FormControl('', []),
          feb: new FormControl('', []),
          mar: new FormControl('', []),
          abr: new FormControl('', []),
          may: new FormControl('', []),
          jun: new FormControl('', []),
          jul: new FormControl('', []),
          ago: new FormControl('', []),
          sep: new FormControl('', []),
          oct: new FormControl('', []),
          nov: new FormControl('', []),
          dic: new FormControl('', []),
        }),
        monthAvailability: new FormControl('', [])
      });
      return ng;
    };

    const objectives = await this.f2Service.getF1Objectives(f2.objective.f1Id);
    const f1ToList = objectives.rows.map((obj, index) => ({
      id: obj.id,
      index: obj.index,
      objectives: obj.title,
      activities: obj.activities,
      results: this.fillResults(obj.results),
      indicator: {
        upperText: obj.indicatorUpperText,
        bottomText: obj.indicatorBottomText,
        goal: obj.indicatorGoal,
      },
      monthAvailability: obj.monthAvailability,
      annualGoal: obj.annualGoal,
      frequency: obj.frequency,
      status: obj.results.length ? obj.results[0].status : null,
      endDate: obj.endDate
        ? `${new Date(obj.endDate).getFullYear()}-${
            new Date(obj.endDate).getMonth() + 1 < 10
              ? '0' + (new Date(obj.endDate).getMonth() + 1)
              : new Date(obj.endDate).getMonth() + 1
          }-${new Date(obj.endDate).getDate() < 10 ? '0' + new Date(obj.endDate).getDate() : new Date(obj.endDate).getDate()}`
        : null,
    }));

    f1ToList.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0));

    const form = this.fb.group({ objectives: new FormArray([]) });
    let currentIdx = 0;
    for (let i = 0; i < f1ToList.length; i++) {
      if (!(form.controls.objectives as FormArray).at(currentIdx)) {
        (form.controls.objectives as FormArray).insert(currentIdx, new FormArray([]));
      }

      const formGroupToPush = gen(f1ToList[i].id);
      formGroupToPush.patchValue(f1ToList[i]);
      ((form.controls.objectives as FormArray).at(currentIdx) as FormArray).push(formGroupToPush);

      // Si no es el último y el que viene es otro objetivo, cambio de index
      if (i + 1 !== f1ToList.length && f1ToList[i].index !== f1ToList[i + 1].index) {
        currentIdx++;
      }
    }

    return form.value;
  }

  fillResults(results: any[]) {
    const RESULTS_ARRAY = {
      ene: null,
      feb: null,
      mar: null,
      abr: null,
      may: null,
      jun: null,
      jul: null,
      ago: null,
      sep: null,
      oct: null,
      nov: null,
      dic: null,
    };
    const resArray = Object.keys(RESULTS_ARRAY);
    const objArr = {};
    resArray.forEach((r, index) => {
      const monthVal = results.find((a) => Number(a.month) - 1 === index);
      objArr[r] = monthVal
        ? {
            resultStatus: monthVal.resultStatus,
            goal: monthVal.goal,
            execution: monthVal.execution,
            observations: monthVal.observations,
          }
        : null;
    });
    return objArr;
  }

  get someF2InToReview(): boolean {
    const someInToReview = this.dataTable?.dataRows?.some((f2) => f2.status === F2Status.TO_REVIEW);
    return someInToReview;
  }

  get canSendToReviewF2s(): boolean {
    const someInToFill = this.dataTable?.dataRows?.some((f2) => f2.status === F2Status.TO_FILL);
    const allApproved = this.dataTable?.dataRows?.every((f2) => f2.status === F2Status.APPROVED);
    const allInToReview = this.dataTable?.dataRows?.every((f2) => f2.status === F2Status.TO_REVIEW);
    const someInToBeSent = this.dataTable?.dataRows?.some((f2) => f2.status === F2Status.TO_SEND);

    return this.actualMonth && !someInToFill && !allApproved && !allInToReview && someInToBeSent;
  }

  async sendToReviewF2s() {
    const f2sToChange = await this.f2Service.getUnitF2sByYear(
      this.user.unitLeader.id,
      this.actualYear.value,
      this.actualMonth?.value
    );

    this.f2Service
      .sendToReviewF2s(f2sToChange)
      .then((r) => {
        // console.log(r);
        this.toast.showSuccess('F2 enviadas a revisión de manera exitosa');
        this.selectedMonthFilter(this.actualMonth);
      })
      .catch((error) => {
        this.toast.showError('Hubo un problema al enviar las F2s. Intente nuevamente');
      });
  }

  get canSendToApprovementF2s(): boolean {
    const someInToReview = this.dataTable?.dataRows?.some((f2) => f2.status === F2Status.TO_REVIEW);

    return this.actualMonth && someInToReview;
  }

  async sendToApprovementF2s() {
    const f2sToChange = await this.f2Service.getUnitF2sByYear(
      this.user.unitLeader.id,
      this.actualYear.value,
      this.actualMonth?.value
    );

    this.f2Service
      .sendToApprovementF2s(f2sToChange)
      .then((r) => {
        // console.log(r);
        this.toast.showSuccess('F2 enviadas a aprobación de manera exitosa');
        this.selectedMonthFilter(this.actualMonth);
      })
      .catch((error) => {
        this.toast.showError('Hubo un problema al enviar las F2s. Intente nuevamente');
      });
  }

  get canApproveF2s(): boolean {
    const canSendDepartmentF2s = this.dataTable?.dataRows
      ?.filter((f2) => f2.deparmentInfo !== 'Pertenece a la Unidad')
      .every((f2) => f2.status === F2Status.TO_APPROVE || f2.status === F2Status.TO_REVIEW || f2.status === F2Status.RETURNED);
    const allApproved = this.dataTable?.dataRows?.every((f2) => f2.status === F2Status.APPROVED);
    const toBeFill = this.dataTable?.dataRows?.some((f2) => f2.status === F2Status.TO_FILL);

    return this.actualMonth && canSendDepartmentF2s && !allApproved && !toBeFill;
  }

  async approveF2s() {
    if (this.someF2InToReview) {
      if (this.needInstituionalApprovement) {
        this.toast.showError('Para aprobar los f2s se deben haber mandado para aprobación desde desarrollo institucional');
      } else {
        this.modalService.openModal(
          'confirmation-modal',
          {
            description:
              '¿Está seguro que aprobar este plan operativo? Hay F1s que no han sido revisados por Desarrollo institucional',
            accept_text: 'Aprobar',
            accept: async () => {
              const f2sToChange = await this.f2Service.getUnitF2sByYear(
                this.user.unitLeader.id,
                this.actualYear.value,
                this.actualMonth?.value
              );

              this.f2Service
                .approveF2s(f2sToChange)
                .then((r) => {
                  // console.log(r);
                  this.toast.showSuccess('F2 aprobadas de manera exitosa');
                  this.selectedMonthFilter(this.actualMonth);
                })
                .catch((error) => {
                  this.toast.showError('Hubo un problema al aprobar las F2s. Intente nuevamente');
                });
            },
          },
          'small'
        );
      }
    } else {
      const f2sToChange = await this.f2Service.getUnitF2sByYear(
        this.user.unitLeader.id,
        this.actualYear.value,
        this.actualMonth?.value
      );

      this.f2Service
        .approveF2s(f2sToChange)
        .then((r) => {
          // console.log(r);
          this.toast.showSuccess('F2 aprobadas de manera exitosa');
          this.selectedMonthFilter(this.actualMonth);
        })
        .catch((error) => {
          this.toast.showError('Hubo un problema al aprobar las F2s. Intente nuevamente');
        });
    }
  }

  mustUpdate($event: any) {
    this.initialSetup(true);
  }
}
