import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Unit } from 'src/app/models/Units';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UnitsService {

  API_ROUTE = '/api/v1/unit';

  constructor(
    private http: HttpClient
  ) { }

  async getAllUnits(): Promise<Unit[]> {
    const res = await this.http.get<any>(environment.apiUrl + '/api/v1/units?paginated=false').toPromise();
    return res.units;
  }

  async deleteUnit(userId: number): Promise<any> {
    const res = await this.http.delete<any>(`${environment.apiUrl}/api/v1/unit/${userId}`).toPromise();
    return res.units;
  }

  registerUnit(unit: Unit, generateF1s: boolean): Observable<any> {
    const newUnit = {
      leaderId: unit.leaderId,
      assistantId: unit.assistantId,
      name: unit.name,
      createF1: generateF1s,
    };

    return this.http.post(environment.apiUrl + this.API_ROUTE, newUnit);
  }

  async getUnit(unitId: number): Promise<Unit> {
    const res = await this.http.get<any>(environment.apiUrl + '/api/v1/unit/' + unitId).toPromise();
    return res.unit;
  }

  updateUnit(unitId: number, unit: Unit): Observable<any> {
    const editedUnit: Unit = {
      id: unitId,
      name: unit.name,
      leaderId: unit.leaderId,
      assistantId: unit.assistantId
    };

    return this.http.put(`${environment.apiUrl + this.API_ROUTE + '/' + unitId}`, editedUnit);
  }
}
