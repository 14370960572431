import { F1 } from './../../models/F1';
import {Component, OnInit, OnDestroy, HostListener} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Observable, Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEditable } from 'src/app/models/OperativePlan';
import { AuthService } from 'src/app/services/auth.service';
import { CloseIconService } from 'src/app/services/close-icon.service';
import { F1Service } from 'src/app/services/f1.service';
import { OperativePlanService } from 'src/app/services/operative-plan.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { IDeactivatableComponent } from '../../utils/deactivatable-component';

@Component({
  selector: 'app-f1-edit',
  templateUrl: './f1-edit.component.html',
  styleUrls: ['./f1-edit.component.scss'],
})
export class F1EditComponent implements OnInit, OnDestroy, IDeactivatableComponent {
  private destroyed$ = new Subject<boolean>();

  f1Id: number;
  f1: F1;
  f1ToList: {
    f1Id: number,
    f1: F1,
    department: string,
    header: { displayValue: string, value: string, flex: number, minWidth?: string, textAlign?: string }[],
    plans: {
      id: number,
      objectives: string,
      createdBy: any,
      createdById: number,
      activities: string,
      indicator: {
        upperText: string,
        bottomText: string,
        goal: number,
      },
      annualGoal: string,
      frequency: number,
      responsable: string,
      endDate: string
    }[]
  };
  planEditable: boolean;
  areUnsavedChanges = false;

  constructor(
    private closeIcon: CloseIconService,
    public authService: AuthService,
    public opService: OperativePlanService,
    private f1Service: F1Service,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.initialSetup();
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    // For simplicity we use a flag. You should implement the logic to figure out if there are any unsaved changes or not

    let canDeactivate = true;

    if (this.areUnsavedChanges) {
      canDeactivate = window.confirm('¡Tienes cambios pendientes por guardar! Si aceptas podrías perder los cambios.');
    }

    return canDeactivate;
  }

  ionViewDidEnter() {
    this.initialSetup();
  }

  initialSetup() {
    this.closeIcon.open();

    this.activatedRoute.params.pipe(takeUntil(this.destroyed$)).subscribe(async params => {
      this.f1Id = +params.f1Id;
      this.f1 = await this.f1Service.getF1(this.f1Id);
      this.planEditable = isEditable(this.f1, this.authService);

      this.f1ToList = {
        f1Id: this.f1Id,
        f1: this.f1,
        department: this.f1.department ? `Departamento: '${this.f1.department.name}'` : `Unidad: '${this.f1.unit.name}'`,
        header: [
          { displayValue: 'Objetivos', value: 'objectives', flex: .8 },
          { displayValue: 'Actividades', value: 'activities', flex: 1 },
          { displayValue: 'Indicador', value: 'indicator', flex: 1 },
          { displayValue: 'Meta anual', value: 'annualGoal', flex: 0.2, minWidth: '40px', textAlign: 'center' },
          { displayValue: 'Frecuencia', value: 'frequency', flex: 0.5 },
          { displayValue: 'Responsable', value: 'responsable', flex: .8 },
          { displayValue: 'Fecha de finalización', value: 'endDate', flex: .5 }
        ],
        plans: this.f1.objectives.map(obj => ({
          id: obj.id,
          index: obj.index,
          objectives: obj.title,
          activities: obj.activities,
          indicator: {
            upperText: obj.indicatorUpperText,
            bottomText: obj.indicatorBottomText,
            goal: obj.indicatorGoal,
          },
          createdBy: obj.createdBy,
          createdById: obj.createdById,
          annualGoal: obj.annualGoal,
          frequency: obj.frequency,
          responsable: obj.responsable,
          endDate: obj.endDate ? `${(new Date(obj.endDate)).getFullYear()}-${((new Date(obj.endDate)).getMonth() + 1) < 10 ? '0' + ((new Date(obj.endDate)).getMonth() + 1) : ((new Date(obj.endDate)).getMonth() + 1)}-${(new Date(obj.endDate)).getDate() < 10 ? '0' + (new Date(obj.endDate)).getDate() : (new Date(obj.endDate)).getDate()}` : null
        }))
      };
    });
  }

  showComment() {
    this.modalService.openModal('comment-modal', { comment: this.f1ToList.f1.comment }, 'normal');
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  getUnsavedChanges(evt: boolean) {
    this.areUnsavedChanges = evt;
  }
}
