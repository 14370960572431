import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit, OnDestroy {

  @Input() data: Observable<{ description: string, icon?: string, accept_text?: string, accept: () => any }>;
  @Output() innerClose = new EventEmitter();

  acceptFunction: () => any;
  private destroyed$ = new Subject<boolean>();

  constructor() { }

  ngOnInit() {
    // console.log(this.data);
  }

  async accept() {
    this.data.pipe(takeUntil(this.destroyed$)).subscribe(r => {
      if (r) {
        this.acceptFunction = r.accept();
      }
    });

    setTimeout(() => {
      this.close();
    }, 300);

  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  close() {
    this.destroyed$.next(true);
    this.innerClose.emit(true);
  }

}
