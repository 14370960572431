/* tslint:disable */
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { PERIOD_LIST } from '../models/F1';
import { AuthService } from './auth.service';
import { logoBASE64 } from '../../assets/pdf/logo';
import { YearConstant } from '../models/Setting';

const versioning = {
  F1: {
    code: "AN_DDI_DP_01",
    version: "02",
    date: "4-enero-2022"
  },
  F2: {
    code: "AN_DDI_DP_02",
    version: "02",
    date: "4-enero-2022"
  },
  F3: {
    code: "AN_DDI_DP_03",
    version: "02",
    date: "4-enero-2022"
  }
}

function findInlineHeight(cell, maxWidth, usedWidth = 0) {
  const calcLines = (inlines) => {
    if (inlines === undefined) {
      return {
        height: 0,
        width: 0,
      };
    }
    let currentMaxHeight = 0;
    for (const currentNode of inlines) {
      usedWidth += currentNode.width;
      if (usedWidth > maxWidth) {
        currentMaxHeight += currentNode.height;
        usedWidth = currentNode.width;
      } else {
        currentMaxHeight = Math.max(currentNode.height, currentMaxHeight);
      }
    }
    return {
      height: currentMaxHeight,
      width: usedWidth,
    };
  };
  if (cell._offsets) {
    usedWidth += cell._offsets.total;
  }
  if (cell._inlines && cell._inlines.length) {
    return calcLines(cell._inlines);
  }  else if (cell.stack && cell.stack[0]) {
    return cell.stack.map(item => {
      return calcLines(item._inlines);
    }).reduce((prev, next) => {
      return {
        height: prev.height + next.height,
        width: Math.max(prev.width + next.width)
      };
    });
  } else if (cell.table) {
    let currentMaxHeight = 0;
    for (const currentTableBodies of cell.table.body) {
      const innerTableHeights = currentTableBodies.map((innerTableCell) => {
        // tslint:disable-next-line:no-shadowed-variable
        const findInlineHeight = this.findInlineHeight(
            innerTableCell,
            maxWidth,
            usedWidth
        );

        usedWidth = findInlineHeight.width;
        return findInlineHeight.height;
      });
      currentMaxHeight = Math.max(...innerTableHeights, currentMaxHeight);
    }
    return {
      height: currentMaxHeight,
      width: usedWidth,
    };
  } else if (cell._height) {
    usedWidth += cell._width;
    return {
      height: cell._height,
      width: usedWidth,
    };
  }

  return {
    height: null,
    width: usedWidth,
  };
}

function applyVerticalAlignment(node, rowIndex, align) {
  const allCellHeights = node.table.body[rowIndex].map(
      (innerNode, columnIndex) => {
        const mFindInlineHeight = findInlineHeight(
            innerNode,
            node.table.widths[columnIndex]._calcWidth
        );
        return mFindInlineHeight.height;
      }
  );
  const maxRowHeight = Math.max(...allCellHeights);
  node.table.body[rowIndex].forEach((cell, ci) => {
    if (allCellHeights[ci] && maxRowHeight > allCellHeights[ci]) {
      let topMargin;
      if (align === 'bottom') {
        topMargin = maxRowHeight - allCellHeights[ci];
      } else if (align === 'center') {
        topMargin = (maxRowHeight - allCellHeights[ci]) / 2;
      }
      if (cell._margin) {
        cell._margin[1] = topMargin;
      } else {
        cell._margin = [0, topMargin, 0, 0];
      }
    }
  });
}

@Injectable({
  providedIn: 'root'
})

export class PdfService {

  pdfMake: any;
  htmlToPdfmake: any;
  moment = moment;

  monthsList: { label: string, value: string }[] = [
    { value: '1', label: 'Enero' },
    { value: '2', label: 'Febrero' },
    { value: '3', label: 'Marzo' },
    { value: '4', label: 'Abril' },
    { value: '5', label: 'Mayo' },
    { value: '6', label: 'Junio' },
    { value: '7', label: 'Julio' },
    { value: '8', label: 'Agosto' },
    { value: '9', label: 'Septiembre' },
    { value: '10', label: 'Octubre' },
    { value: '11', label: 'Noviembre' },
    { value: '12', label: 'Diciembre' },
  ];

  periodList = PERIOD_LIST;

  statusF2List = [
    { displayValue: 'Buena', value: 0, color: '#29650d' },
    { displayValue: 'Moderada', value: 1, color: '#fae20e' },
    { displayValue: 'Baja', value: 2, color: '#b8170c' },
    { displayValue: 'Sobre Ejecución', value: 3, color: '#f36a1e' },
  ];

  getF2GoalColor(resultStatus, frequency, ele) {

    const color = this.statusF2List[resultStatus]?.color === undefined ? '#c2c2c2' : this.statusF2List[resultStatus]?.color

    if(color === '#c2c2c2'){
      return '#c2c2c2'
    }

    switch (frequency){
        // Semestral
      case 1:
        if (ele === 'jun'){
          return color;
        } else if (ele === 'dic'){
          return color;
        }
        break;
        // Anual
      case 2:
        if (ele === 'dic'){
          return color;
        }
        break;
        // Trimestral
      case 3:
        if (ele === 'mar' ){
          return color;
        } else if (ele === 'jun'){
          return color;
        } else if (ele === 'sep'){
          return color;
        } else if (ele === 'dic'){
          return color;
        }
        break;
        // Bimestral
      case 4:
        if (ele === 'feb' ){
          return color;
        } else if (ele === 'abr'){
          return color;
        } else if (ele === 'jun'){
          return color;
        } else if (ele === 'ago'){
          return color;
        } else if (ele === 'oct'){
          return color;
        } else if (ele === 'dic'){
          return color;
        }
        break;
      default:
        return color;
    }
  }

  async loadPdfMaker() {
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      const htmlToPdfmakeModule = await import('html-to-pdfmake');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
      this.htmlToPdfmake = htmlToPdfmakeModule.default;
    }
  }

  constructor(private authService: AuthService) { this.moment.locale('es-us'); }

  async generateF1PDF(data: any, objectives: any, initial?: number, final?: number, forcedDate?: Date, approvalBy?: string, versioning?: YearConstant, director?: string) {
    // console.log(objectives);
    await this.loadPdfMaker();

    const def: any = {
      pageSize: 'LETTER',
      pageMargins: [30, 140, 30, 40],
      pageOrientation: 'landscape',
      footer(currentPage, pageCount, pageSize) {
        let showApproved = false;
        if(currentPage == pageCount){
          showApproved = true;
        }
        if (false) {
          return {
              layout: 'noBorders',
              margin: [90, 8, 90, 8 ],
              table: {
                headerRows: 0,
                widths: [ '*', '*' ],
                body: [
                    [
                      {
                        text:
                          [
                            {text: !!data.f1.approvalAt ? `${data.f1.unit.leader.name.toUpperCase() + ' '
                              + data.f1.unit.leader.lastname.toUpperCase() + '\n' }` : 'SIN APROBAR\n', fontSize: 9,
                              alignment: 'left', bold: true},
                            {text: !!data.f1.approvalAt ? 'DIRECTOR DE UNIDAD' : '', fontSize: 9, alignment: 'left'}
                          ]
                      },
                      {
                        text:
                          [
                            {text: currentPage.toString() + ' de ' + pageCount.toString(), alignment: 'right'}
                          ]
                      },
                    ]
                ]
              }
            }
        } else {
          return {
            layout: 'noBorders',
            margin: [90, 8, 25, 8 ],
            table: {
              headerRows: 0,
              widths: [ '*', 50 ],
              body: [
                [
                  {
                    text:
                        [
                          {text: '', fontSize: 9,
                            alignment: 'left', bold: true},
                          {text: '', fontSize: 9, alignment: 'left'}
                        ]
                  },
                  {
                    text:
                        [
                          {text: ( (initial | 0) + currentPage - (initial ? 1 : 0)).toString() + ' de ' + ( final && final >= pageCount + initial - 1 ? final : pageCount ).toString(), alignment: 'right', fontSize: 9}
                        ]
                  },
                ]
              ]
            }
          }
        }
        // } else {
        //   return null;
        // }
      },
      header(currentPage, pageCount, pageSize) {
        // you can apply any logic and return any valid pdfmake element
        let unit = "";
        let date = "";
        let leader = "";
        let createdBy = "";
        let department = "";
            unit = data.f1.unit.name;
            date = data.f1.approvalAt;
            leader = data.f1.director ? data.f1.director.name + ' ' + data.f1.director.lastname : data.f1.unit.leader.name + ' ' + data.f1.unit.leader.lastname;
            createdBy = data.f1.createdBy.name + ' ' + data.f1.createdBy.lastname;
            department = data.f1.department?.name;
        if (currentPage) {
          return [
            {
              columns: [
                {
                  image: logoBASE64,
                  width: 55,
                  margin: [25, 15, 15, 0 ],
                },
                {
                  text: [
                    {
                      text: 'ASAMBLEA NACIONAL\n',
                      margin: [0, 35, 0, 0],
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: 'SECRETARÍA GENERAL\n',
                      margin: [0, 2, 0, 0],
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: 'PLAN OPERATIVO ANUAL (POA - F1)\n',
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: `                                                                                             ${data.f1.plan.year}                                                                                                       `,
                      background: '#c2c2c2',
                      fontSize: 12,
                      bold: true,
                      alignment: 'center',
                      width: 1
                    }
                  ],
                  margin: [95, 8, 95, 0 ],
                },
                {
                  text: [
                    {
                      text: `\nCódigo ${versioning.f1Header.code}\n`,
                      margin: [0, 50, 0, 0],
                      fontSize: 6,
                      alignment: 'left'
                    },
                    {
                      text: `Versión ${versioning.f1Header.version}\n`,
                      margin: [0, 2, 0, 0],
                      fontSize: 6,
                      alignment: 'left'
                    },
                    {
                      text: `Fecha de versión:\n${versioning.f1Header.date}\n`,
                      fontSize: 6,
                      alignment: 'left'
                    },
                  ],
                  width: 90,
                  padding: [15, 15, 25, 0 ],
                  margin: [5, 25, 25, 0]
                },
              ],
              margin: [0, 20, 0, 0]
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 80 ],
                body: [[
                    { text: [
                      {text: `Unidad ejecutora: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${unit}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                    { text: [
                      {text: `Fecha: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${date ? moment(date).format('DD/MM/yyyy') : 'Sin aprobar'}`, fontSize: 9, italics: true
                        , alignment: 'left', decoration: 'underline'}
                    ]},
                  ]]
              }
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 300 ],
                body: [
                  [
                    { text: [
                        {text: `Nombre del jefe y sello de la dependencia: `, fontSize: 9, bold: true, alignment: 'left'},
                        {text: `${leader}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                      ]},
                    { text: [
                        {text: department ? `Nombre del departamento: ` : '', fontSize: 9, bold: true, alignment: 'left'},
                        {text: department ? `${department}` : '', fontSize: 9, italics: true
                          , alignment: 'left', decoration: 'underline'}
                      ]},
                  ]
                ]
              }
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 300 ],
                body: [[
                  { text: [
                      {text: `Elaborado por: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${createdBy}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: `Pág. `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${(initial | 0) + currentPage - (initial ? 1 : 0)}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'},
                      {text: ` de `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${final && final >= pageCount + initial - 1 ? final : pageCount}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ],
                  },
                ]]
              }
            }


          ];
        } else {
          return null;
        }
      },
      content: [
        {
          layout: { paddingTop(index, node) {
              applyVerticalAlignment(node, index, 'center');
              return 0;
            }, }, // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 2,
            widths: [ 175, 180, 80, 30, 60, 70, 70 ],
            dontBreakRows: true,

            body: [
              [
                { stack: [
                    { text: [
                      { text: 'Recursos: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
                      { text: 'El presupuesto es asignado y administrado por la Dirección General de Administración y Finanzas.',
                        fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
                    ]},
                    { text: [
                        { text: 'Fuente: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
                        { text: 'Datos provenientes de cada dirección.',
                          fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
                    ]}
                  ],
                  colSpan: 7,
                  fillColor: '#f6ef52'
                },
                  // colSpan needs explicit columns definitions
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
              ],
              [ { text: 'OBJETIVOS', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'ACTIVIDADES', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'INDICADOR', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'META ANUAL %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'FRECUENCIA', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'RESPONSABLE', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'FECHA DE FINALIZACIÓN', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' } ],
            ],
          },
        }
      ]
    };

    objectives.objectives.forEach( (f1, index) => {
      f1
          .filter( curr => curr.frequency )
          .forEach( (objective, innerIndex) => {
        def.content[0].table.body.push(
            [
          // @ts-ignore
            { stack: [ { text: innerIndex === 0 ? `${index + 1}.- ${objective.objectives}` : '', margin: [0, 2, 0, 2], unbreakable: true } ], alignment: 'justify', unbreakable: true, fontSize: 9 },
          // @ts-ignore
            { stack: [ { text: !!objective.activities ? objective.activities : '--', margin: [0, 2, 0, 2] } ], alignment: 'justify', unbreakable: true, fontSize: 9 },
          // @ts-ignore
            { stack: [ { text: `${!!objective.indicator.upperText ? objective.indicator.upperText : '--'}/ ${!!objective.indicator.bottomText ? (objective.indicator.goal || typeof(objective.indicator.goal) === "number" ? objective.indicator.goal.toString() : '#') + ' ' + objective.indicator.bottomText : '--'} x100`, margin: [0, 2, 0, 2] } ], alignment: 'center',
              unbreakable: true, fontSize: 9 },
          // @ts-ignore
            { stack: [ { text: !!objective.annualGoal || typeof(objective.annualGoal) === "number" ? objective.annualGoal : '--', margin: [0, 2, 0, 2] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
          // @ts-ignore
            { stack: [ { text: this.periodList.find(p => p.value === objective.frequency.value)?.displayValue || '--', margin: [0, 2, 0, 2]}  ],
              alignment: 'center',
              unbreakable: true, fontSize: 9 },
          // @ts-ignore
            { stack: [ { text: !!objective.responsable ? objective.responsable : '--', margin: [0, 2, 0, 2] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
          // @ts-ignore
            { stack: [ { text: !!objective.endDate ? this.moment(objective.endDate).format('ll') : 'Sin asignar', margin: [0, 2, 0, 2] } ], alignment: 'center',
              unbreakable: true, fontSize: 9 }
          ]
        );
      })
      // @ts-ignore
    });

    // def.content.push(
    //     // @ts-ignore
    //     [
    //       {
    //         layout: 'noBorders',
    //         margin: [75, 16, 75, 0 ],
    //         unbreakable: true,
    //         table: {
    //           headerRows: 0,
    //           widths: [ '*' ],
    //           dontBreakRows: true,
    //           body: [
    //             [{
    //               stack: [
    //                 {text: !!data.f1.approvalAt ? `${data.f1.unit.leader.name.toUpperCase() + ' '
    //                   + data.f1.unit.leader.lastname.toUpperCase() }` : 'SIN APROBAR', fontSize: 9,
    //                   alignment: 'left', bold: true}
    //               ]
    //             }],
    //             [{
    //               stack: [
    //                 {text: !!data.f1.approvalAt ? 'DIRECTOR DE UNIDAD' : '', fontSize: 9, alignment: 'left'}
    //               ]
    //             }],
    //           ]
    //         },
    //       }
    //     ]
    // )



    this.pdfMake.createPdf(def).download(`F1.pdf`);
  }

  async generateMultiF3PDF(data: any, parsedObjectives: any, initial?: number, final?: number, forcedDate?: Date, approvalBy?: string, semesterIsChecked?: boolean, leftFooter?: string, versioning?: YearConstant) {
    let counter = 0;
    await this.loadPdfMaker();

    const currentUser =  this.authService.currentUserValue.name
        + ' '
        + this.authService.currentUserValue.lastname;

    const def = {
      pageSize: 'LETTER',
      pageMargins: [30, 140, 30, 40],
      pageOrientation: 'landscape',
      footer(currentPage, pageCount, pageSize) {
        let showApproved = false;
        let f1 = null;
        for(let l = 0; l < def.content.length; l++){
          if(def.content[l + 1]?.hasOwnProperty('stack')){
            if(def.content[l + 1].stack[0].pageFooter &&
                (
                    currentPage === def.content[l + 2]?.positions[0]?.pageNumber - 1 || currentPage == pageCount
                )
            ){
              showApproved = true;
              f1 = def.content[l + 1].stack[0].pageFooter
            }
          }
        }
        if (false) {
          return {
            layout: 'noBorders',
            margin: [90, 8, 90, 8 ],
            table: {
              headerRows: 0,
              widths: [ '*', '*' ],
              body: [
                [
                  {
                    text:
                        [
                          {text: !!f1.approvalAt ? `${f1.unit.leader.name.toUpperCase() + ' '
                            + f1.unit.leader.lastname.toUpperCase() + '\n' }` : 'SIN APROBAR\n', fontSize: 9,
                            alignment: 'left', bold: true},
                          {text: !!f1.approvalAt ? 'DIRECTOR DE UNIDAD' : '', fontSize: 9, alignment: 'left'}
                        ]
                  },
                  {
                    text:
                        [
                          {text: currentPage.toString() + ' de ' + pageCount.toString(), alignment: 'right'}
                        ]
                  },
                ]
              ]
            }
          }
        } else {
          return {
            layout: 'noBorders',
            margin: [25, 8, 25, 8 ],
            table: {
              headerRows: 0,
              widths: [ '*', '*', '*' ],
              body: [
                [
                  {
                    text:
                        [
                          {text: leftFooter ? leftFooter : '', fontSize: 9,
                            alignment: 'left', bold: true},
                        ]
                  },
                  {
                    text:
                        [
                          {text: '"Documento No Controlado" a excepción del original', fontSize: 9,
                            alignment: 'center'},
                        ]
                  },
                  {
                    text:
                        [
                          {text: ( (initial | 0) + currentPage - (initial ? 1 : 0)).toString() + ' de ' + ( final && final >= pageCount + initial - 1 ? final : pageCount ).toString(), alignment: 'right', fontSize: 9}
                        ]
                  },
                ]
              ]
            }
          }
        }
        // } else {
        //   return null;
        // }
      },
      header(currentPage, pageCount, pageSize) {
        // you can apply any logic and return any valid pdfmake element
        let unit = "";
        let date = "";
        let leader = "";
        let createdBy = "";
        let department = "";
        let year = "";
        for(let l = 0; l < def.content.length; l++){
          if(def.content[l].pageHeader && currentPage >= def.content[l].positions[0].pageNumber){
            unit = def.content[l].pageHeader.unit;
            date = def.content[l].pageHeader.date;
            leader = def.content[l].pageHeader.leader;
            createdBy = def.content[l].pageHeader.createdBy;
            department = def.content[l].pageHeader.department;
            year = def.content[l].pageHeader.year;
          }
        }

        if (currentPage) {
          return [
            {
              columns: [
                {
                  image: logoBASE64,
                  width: 55,
                  margin: [25, 15, 15, 0 ],
                },
                {
                  text: [
                    {
                      text: 'ASAMBLEA NACIONAL\n',
                      margin: [0, 35, 0, 0],
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: 'SECRETARÍA GENERAL\n',
                      margin: [0, 2, 0, 0],
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: 'INFORME DE EVALUACIÓN SEMESTRAL DE ACTIVIDADES Y PROYECTOS DEL ÁREA TÉCNICA Y ADMINISTRATIVA\n',
                      fontSize: 10,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: `                                                                                             ${year}                                                                                                       `,
                      background: '#c2c2c2',
                      fontSize: 12,
                      bold: true,
                      alignment: 'center',
                      width: 1
                    }
                  ],
                  margin: [65, 8, 65, 0 ],
                },
                {
                  text: [
                    {
                      text: `\nCódigo ${versioning.f3Header.code}\n`,
                      margin: [0, 50, 0, 0],
                      fontSize: 6,
                      alignment: 'left'
                    },
                    {
                      text: `Versión ${versioning.f3Header.version}\n`,
                      margin: [0, 2, 0, 0],
                      fontSize: 6,
                      alignment: 'left'
                    },
                    {
                      text: `Fecha de versión:\n${versioning.f3Header.date}\n`,
                      fontSize: 6,
                      alignment: 'left'
                    },
                  ],
                  width: 90,
                  padding: [15, 15, 25, 0 ],
                  margin: [5, 25, 25, 0]
                },
              ],
              margin: [0, 20, 0, 0]
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 80 ],
                body: [[
                  { text: [
                      {text: `Unidad ejecutora: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${unit}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: `Fecha: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${date ? moment(date).format('DD/MM/yyyy') : 'Vacío'}`, fontSize: 9, italics: true
                        , alignment: 'left', decoration: 'underline'}
                    ]},
                ]]
              }
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 300 ],
                body: [
                  [
                    { text: [
                        {text: `Nombre del jefe y sello de la dependencia: `, fontSize: 9, bold: true, alignment: 'left'},
                        {text: `${leader}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                      ]},
                    { text: [
                        {text: department ? `Nombre del departamento: ` : '', fontSize: 9, bold: true, alignment: 'left'},
                        {text: department ? `${department}` : '', fontSize: 9, italics: true
                          , alignment: 'left', decoration: 'underline'}
                      ]},
                  ]
                ]
              }
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 300],
                body: [[
                  { text: [
                      {text: `Elaborado por: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${createdBy || 'Vacío'}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: `Pág. `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${(initial | 0) + currentPage - (initial ? 1 : 0)}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'},
                      {text: ` de `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${final && final >= pageCount + initial - 1 ? final : pageCount}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ],
                  },
                ]]
              }
            }
          ];
        } else {
          return null;
        }
      },
      content: []
    };

    parsedObjectives.forEach( (f1, index) => {
      def.content.push(
          {
            layout: { paddingTop(index, node) {
                applyVerticalAlignment(node, index, 'center');
                return 0;
              }, }, // optional
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 2,
              widths: [ 160, 160, 80, 30, 60, 70, 30, 30, 30 ],
              dontBreakRows: true,

              body: [
                [
                  { stack: [
                      { text: [
                          { text: 'Recursos: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
                          { text: 'El presupuesto es asignado y administrado por la Dirección General de Administración y Finanzas.',
                            fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
                        ]},
                      { text: [
                          { text: 'Fuente: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
                          { text: 'Datos provenientes de cada dirección.',
                            fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
                        ]}
                    ],
                    colSpan: 9,
                    fillColor: '#f6ef52'
                  },
                  // colSpan needs explicit columns definitions
                  { text: '' },
                  { text: '' },
                  { text: '' },
                  { text: '' },
                  { text: '' },
                  { text: '' },
                  { text: '' },
                  { text: '' },
                ],
                [ { text: 'OBJETIVOS', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                  { text: 'ACTIVIDADES', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                  { text: 'INDICADOR', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                  { text: 'META ANUAL %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                  { text: 'FRECUENCIA', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                  { text: 'RESPONSABLE', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                  { text: 'I Sem %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                  { text: 'II Sem %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                  { text: 'TOTAL %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' } ],
              ],
            },
          }
      );
      f1.objectives.forEach( (objectives, pos) => {
        objectives.forEach( (objective, innerIndex) => {
          def.content[def.content.length - 1].pageHeader = {
            unit: f1.unit.name,
            date: semesterIsChecked ? f1.f3Sem2ExportedAt || f1.f3Sem1ExportedAt : f1.f3Sem1ExportedAt,
            leader: semesterIsChecked && f1.responsable2 ? f1.responsable2.name + ' ' + f1.responsable2.lastname : (f1.responsable ? f1.responsable.name + ' ' + f1.responsable.lastname : f1.unit.leader.name + ' ' + f1.unit.leader.lastname),
            createdBy: semesterIsChecked ? (f1.f3Sem2CreatedBy ? f1.f3Sem2CreatedBy?.name + ' ' + f1.f3Sem2CreatedBy?.lastname : (f1.f3Sem1CreatedBy ? f1.f3Sem1CreatedBy?.name + ' ' + f1.f3Sem1CreatedBy?.lastname : 'Vacío')) : (f1.f3Sem1CreatedBy ? f1.f3Sem1CreatedBy?.name + ' ' + f1.f3Sem1CreatedBy?.lastname : 'Vacío'),
            department: f1.department?.name,
            year: f1.plan.year
          }

          def.content[def.content.length - 1].table.body.push(
              [
                // @ts-ignore
                { stack: [ { text: innerIndex === 0 ? `${counter + 1}.- ${objective.title}` : '', margin: [0, 2, 0, 2] } ], alignment: 'justify', unbreakable: true, fontSize: 9 },
                // @ts-ignore
                { stack: [ { text: !!objective.activities ? objective.activities : '--', margin: [0, 2, 0, 2] } ], alignment: 'justify', unbreakable: true, fontSize: 9 },
                // @ts-ignore
                { stack: [ { text: `${!!objective.indicator.upperText ? objective.indicator.upperText : '--'}/ ${!!objective.indicator.bottomText ? (objective.indicator.goal || typeof(objective.indicator.goal) === "number" ? objective.indicator.goal.toString() : '#') + ' ' + objective.indicator.bottomText : '--'} x100`, margin: [0, 2, 0, 2] } ], alignment: 'center',
                  unbreakable: true, fontSize: 9 },
                // @ts-ignore
                { stack: [ { text: !!objective.annualGoal || typeof(objective.annualGoal) === "number" ? objective.annualGoal : '--', margin: [0, 2, 0, 2] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
                // @ts-ignore
                { stack: [ { text: this.periodList.find(p => p.value === objective.frequency.value)?.displayValue || '--', margin: [0, 2, 0, 2]}  ],
                  alignment: 'center',
                  unbreakable: true, fontSize: 9 },
                // @ts-ignore
                { stack: [ { text: !!objective.responsable ? objective.responsable : '--', margin: [0, 2, 0, 2] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
                // @ts-ignore
                { stack: [ { text: !!objective.objective.f3?.semester1 ? objective.objective.f3?.semester1 : '0', margin: [0, 2, 0, 2] } ], alignment: 'center',
                  unbreakable: true, fontSize: 9, bold: true },
                // @ts-ignore
                { stack: [ { text: !!objective.objective.f3?.semester2 && semesterIsChecked ? objective.objective.f3?.semester2 : '0' , margin: [0, 2, 0, 2] } ],
                  alignment: 'center', unbreakable: true, fontSize: 9, bold: true },
                // @ts-ignore
                { stack: [ { text: (!isNaN(objective.objective.f3?.semester1) && !isNaN(objective.objective.f3?.semester2)) && semesterIsChecked ? objective.objective.f3?.total : '--', margin: [0, 2, 0, 2] } ], alignment: 'center',
                  unbreakable: true, fontSize: 9, bold: true },
              ]
          )
        })
        counter++;
      });
      def.content.push(
          [
            {
              pageFooter: f1,
              layout: 'noBorders',
              margin: [0, 0, 0, 0 ],
              unbreakable: true,
              table: {
                headerRows: 0,
                widths: [ '*' ],
                dontBreakRows: true,
                body: [
                  [{
                    stack: [
                      {text: '', fontSize: 9,
                        alignment: 'left', bold: true}
                    ]
                  }],
                ]
              },
              pageBreak: (parsedObjectives.length - 1 === index) ? null : 'after'
            }
          ]
      )
      //});
      // @ts-ignore
    });



    this.pdfMake.createPdf(def).download(`F3 Multi.pdf`);
  }

  async generateMultiF1PDF(data: any, parsedObjectives: any, initial?: number, final?: number, forcedDate?: Date, approvalBy?: string, leftFooter?: string) {
    let counter = 0;
    await this.loadPdfMaker();

    const currentUser =  this.authService.currentUserValue.name
        + ' '
        + this.authService.currentUserValue.lastname;

    const def = {
      pageSize: 'LETTER',
      pageMargins: [30, 140, 30, 35],
      pageOrientation: 'landscape',
      footer(currentPage, pageCount, pageSize) {
        let showApproved = false;
        let f1 = null;
        for(let l = 0; l < def.content.length; l++){
          if(def.content[l + 1]?.hasOwnProperty('stack')){
            if(def.content[l + 1].stack[0].pageFooter &&
                (
                    currentPage === def.content[l + 2]?.positions[0]?.pageNumber - 1 || currentPage == pageCount
                )
            ){
              showApproved = true;
              f1 = def.content[l + 1].stack[0].pageFooter
            }
          }
        }
        if (false) {
          return {
            layout: 'noBorders',
            margin: [90, 8, 90, 8 ],
            table: {
              headerRows: 0,
              widths: [ '*', '*' ],
              body: [
                [
                  {
                    text:
                        [
                          {text: !!f1.approvalAt ? `${f1.unit.leader.name.toUpperCase() + ' '
                            + f1.unit.leader.lastname.toUpperCase() + '\n' }` : 'SIN APROBAR\n', fontSize: 9,
                            alignment: 'left', bold: true},
                          {text: !!f1.approvalAt ? 'DIRECTOR DE UNIDAD' : '', fontSize: 9, alignment: 'left'}
                        ]
                  },
                  {
                    text:
                        [
                          {text: currentPage.toString() + ' de ' + pageCount.toString(), alignment: 'right'}
                        ]
                  },
                ]
              ]
            }
          }
        } else {
          return {
            layout: 'noBorders',
            margin: [25, 8, 25, 8 ],
            table: {
              headerRows: 0,
              widths: [ '*', '*', '*' ],
              body: [
                [
                  {
                    text:
                        [
                          {text: leftFooter ? leftFooter : '', fontSize: 9,
                            alignment: 'left', bold: true}
                        ]
                  },
                  {
                    text:
                        [
                          {text: '', fontSize: 9,
                            alignment: 'center'}
                        ]
                  },
                  {
                    text:
                        [
                          {text: ( (initial | 0) + currentPage - (initial ? 1 : 0)).toString() + ' de ' + ( final && final >= pageCount + initial - 1 ? final : pageCount ).toString(), alignment: 'right', fontSize: 9}
                        ]
                  },
                ]
              ]
            }
          }
        }
        // } else {
        //   return null;
        // }
      },
      header(currentPage, pageCount, pageSize) {
        // you can apply any logic and return any valid pdfmake element
        let unit = "";
        let date = "";
        let leader = "";
        let createdBy = "";
        let department = "";
        let year = "";
        for(let l = 0; l < def.content.length; l++){
          if(def.content[l].pageHeader && currentPage >= def.content[l].positions[0].pageNumber){
            unit = def.content[l].pageHeader.unit;
            date = def.content[l].pageHeader.date;
            leader = def.content[l].pageHeader.leader;
            createdBy = def.content[l].pageHeader.createdBy;
            department = def.content[l].pageHeader.department;
            year = def.content[l].pageHeader.year;
          }
        }

        if (currentPage) {
          return [
            {
              columns: [
                {
                  image: logoBASE64,
                  width: 55,
                  margin: [25, 15, 15, 0 ],
                },
                {
                  text: [
                    {
                      text: 'ASAMBLEA NACIONAL\n',
                      margin: [0, 35, 0, 0],
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: 'SECRETARÍA GENERAL\n',
                      margin: [0, 2, 0, 0],
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: 'PLAN OPERATIVO ANUAL (POA - F1)\n',
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: `                                                                                             ${year}                                                                                                       `,
                      background: '#c2c2c2',
                      fontSize: 12,
                      bold: true,
                      alignment: 'center',
                      width: 1
                    }
                  ],
                  margin: [95, 8, 95, 0 ],
                },
                {
                  text: [
                    {
                      text: `\nCódigo ${versioning.F1.code}\n`,
                      margin: [0, 50, 0, 0],
                      fontSize: 6,
                      alignment: 'left'
                    },
                    {
                      text: `Versión ${versioning.F1.version}\n`,
                      margin: [0, 2, 0, 0],
                      fontSize: 6,
                      alignment: 'left'
                    },
                    {
                      text: `Fecha de versión:\n${versioning.F1.date}\n`,
                      fontSize: 6,
                      alignment: 'left'
                    },
                  ],
                  width: 90,
                  padding: [15, 15, 25, 0 ],
                  margin: [5, 25, 25, 0]
                },
              ],
              margin: [0, 20, 0, 0]
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 100 ],
                body: [[
                  { text: [
                      {text: `Unidad ejecutora: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${unit}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: `Fecha: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${ date ? moment(date).format('DD/MM/yyyy') : 'Sin aprobar'}`, fontSize: 9, italics: true
                        , alignment: 'left', decoration: 'underline'}
                    ]},
                ]]
              }
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 300 ],
                body: [
                  [
                    { text: [
                        {text: `Nombre del jefe y sello de la dependencia: `, fontSize: 9, bold: true, alignment: 'left'},
                        {text: `${leader}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                      ]},
                    { text: [
                        {text: department ? `Nombre del departamento: ` : '', fontSize: 9, bold: true, alignment: 'left'},
                        {text: department ? `${department}` : '', fontSize: 9, italics: true
                          , alignment: 'left', decoration: 'underline'}
                      ]},
                  ]
                ]
              }
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 300],
                body: [[
                  { text: [
                      {text: `Elaborado por: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${createdBy}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: `Pág. `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${(initial | 0) + currentPage - (initial ? 1 : 0)}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'},
                      {text: ` de `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${final && final >= pageCount + initial - 1 ? final : pageCount}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ],
                  },
                ]]
              }
            }
          ];
        } else {
          return null;
        }
      },
      content: []
    };

    parsedObjectives.forEach( (f1, index) => {
        def.content.push(
            {
              layout: { paddingTop(index, node) {
                  applyVerticalAlignment(node, index, 'center');
                  return 0;
                }, }, // optional
              table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                headerRows: 2,
                widths: [ 175, 180, 80, 30, 60, 70, 70 ],
                dontBreakRows: true,

                body: [
                  [
                    { stack: [
                        { text: [
                            { text: 'Recursos: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
                            { text: 'El presupuesto es asignado y administrado por la Dirección General de Administración y Finanzas.',
                              fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
                          ]},
                        { text: [
                            { text: 'Fuente: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
                            { text: 'Datos provenientes de cada dirección.',
                              fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
                          ]}
                      ],
                      colSpan: 7,
                      fillColor: '#f6ef52'
                    },
                    // colSpan needs explicit columns definitions
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                  ],
                  [ { text: 'OBJETIVOS', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'ACTIVIDADES', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'INDICADOR', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'META ANUAL %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'FRECUENCIA', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'RESPONSABLE', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'FECHA DE FINALIZACIÓN', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' } ],
                ],
              },
            }
        );
          f1.objectives.forEach( (objectives, pos) => {
            objectives
                .filter( curr => curr.frequency )
                .forEach( (objective, innerIndex) => {
              def.content[def.content.length - 1].pageHeader = {
                unit: f1.unit.name,
                date: f1.approvalAt,
                leader: f1.unit.leader.name + ' ' + f1.unit.leader.lastname,
                createdBy: f1.createdBy?.name + ' ' + f1.createdBy?.lastname,
                department: f1.department?.name,
                year: f1.plan.year
              }

              def.content[def.content.length - 1].table.body.push(
                  [
                    // @ts-ignore
                    { stack: [ { text: innerIndex === 0 ? `${counter + 1}.- ${objective.title}` : '', margin: [0, 2, 0, 2] } ], alignment: 'justify', unbreakable: true, fontSize: 9 },
                    // @ts-ignore
                    { stack: [ { text: !!objective.activities ? objective.activities : '--', margin: [0, 2, 0, 2] } ], alignment: 'justify', unbreakable: true, fontSize: 9 },
                    // @ts-ignore
                    { stack: [ { text: `${!!objective.indicator.upperText ? objective.indicator.upperText : '--'}/ ${!!objective.indicator.bottomText ? (objective.indicator.goal || typeof(objective.indicator.goal) === "number" ? objective.indicator.goal.toString() : '#') + ' ' + objective.indicator.bottomText : '--'} x100`, margin: [0, 2, 0, 2] } ], alignment: 'center',
                      unbreakable: true, fontSize: 9 },
                    // @ts-ignore
                    { stack: [ { text: !!objective.annualGoal || typeof(objective.annualGoal) === "number" ? objective.annualGoal : '--', margin: [0, 2, 0, 2] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
                    // @ts-ignore
                    { stack: [ { text: this.periodList.find(p => p.value === objective.frequency.value)?.displayValue || '--', margin: [0, 2, 0, 2]}  ],
                      alignment: 'center',
                      unbreakable: true, fontSize: 9 },
                    // @ts-ignore
                    { stack: [ { text: !!objective.responsable ? objective.responsable : '--', margin: [0, 2, 0, 2] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
                    // @ts-ignore
                    { stack: [ { text: !!objective.endDate ? this.moment(objective.endDate).format('ll') : 'Sin asignar', margin: [0, 2, 0, 2] } ], alignment: 'center',
                      unbreakable: true, fontSize: 9 }
                  ]
              )
            })
            counter++;
          });
        def.content.push(
            [
              {
                pageFooter: f1,
                layout: 'noBorders',
                margin: [0, 0, 0, 0 ],
                unbreakable: true,
                table: {
                  headerRows: 0,
                  widths: [ '*' ],
                  dontBreakRows: true,
                  body: [
                    [{
                      stack: [
                        {text: '', fontSize: 9,
                          alignment: 'left', bold: true}
                      ]
                    }],
                  ]
                },
                pageBreak: (parsedObjectives.length - 1 === index) ? null : 'after'
              }
            ]
        )
      //});
      // @ts-ignore
    });



    this.pdfMake.createPdf(def).download(`F1 Multi.pdf`);
  }


  // async generateMultiF2PDF(data: any, parsedObjectives: any, year: string) {
  //   console.log(data, parsedObjectives);
  //   let counter = 0;
  //   await this.loadPdfMaker();
  //
  //   const currentUser =  this.authService.currentUserValue.name
  //       + ' '
  //       + this.authService.currentUserValue.lastname;
  //
  //   const def = {
  //     pageSize: 'LEGAL',
  //     pageMargins: [50, 155, 50, 75],
  //     pageOrientation: 'landscape',
  //     footer(currentPage, pageCount, pageSize) {
  //       let showApproved = false;
  //       let f1 = null;
  //       for(let l = 0; l < def.content.length; l++){
  //         if(def.content[l + 1]?.hasOwnProperty('stack')){
  //           if(def.content[l + 1].stack[0].pageFooter &&
  //               (
  //                   currentPage === def.content[l + 2]?.positions[0]?.pageNumber - 1 || currentPage == pageCount
  //               )
  //           ){
  //             showApproved = true;
  //             f1 = def.content[l + 1].stack[0].pageFooter
  //           }
  //         }
  //       }
  //       if (false) {
  //         return {
  //           layout: 'noBorders',
  //           margin: [90, 8, 90, 8 ],
  //           table: {
  //             headerRows: 0,
  //             widths: [ '*', '*' ],
  //             body: [
  //               [
  //                 {
  //                   text:
  //                       [
  //                         {text: !!f1.approvalAt ? `${f1.unit.leader.name.toUpperCase() + ' '
  //                           + f1.unit.leader.lastname.toUpperCase() + '\n' }` : 'SIN APROBAR\n', fontSize: 9,
  //                           alignment: 'left', bold: true},
  //                         {text: !!f1.approvalAt ? 'DIRECTOR DE UNIDAD' : '', fontSize: 9, alignment: 'left'}
  //                       ]
  //                 },
  //                 {
  //                   text:
  //                       [
  //                         {text: currentPage.toString() + ' de ' + pageCount.toString(), alignment: 'right'}
  //                       ]
  //                 },
  //               ]
  //             ]
  //           }
  //         }
  //       } else {
  //         return {
  //           layout: 'noBorders',
  //           margin: [90, 8, 90, 8 ],
  //           table: {
  //             headerRows: 0,
  //             widths: [ '*', 50 ],
  //             body: [
  //               [
  //                 {
  //                   text:
  //                       [
  //                         {text: '', fontSize: 9,
  //                           alignment: 'left', bold: true},
  //                         {text: '', fontSize: 9, alignment: 'left'}
  //                       ]
  //                 },
  //                 {
  //                   text:
  //                       [
  //                         {text: currentPage.toString() + ' de ' + pageCount.toString(), alignment: 'right'}
  //                       ]
  //                 },
  //               ]
  //             ]
  //           }
  //         }
  //       }
  //       // } else {
  //       //   return null;
  //       // }
  //     },
  //     header(currentPage, pageCount, pageSize) {
  //       // you can apply any logic and return any valid pdfmake element
  //       let unit = "";
  //       let date = "";
  //       let leader = "";
  //       let createdBy = "";
  //       let department = "";
  //       for(let l = 0; l < def.content.length; l++){
  //         if(def.content[l].pageHeader && currentPage >= def.content[l].positions[0].pageNumber){
  //           unit = def.content[l].pageHeader.unit;
  //           date = def.content[l].pageHeader.date;
  //           leader = def.content[l].pageHeader.leader;
  //           createdBy = def.content[l].pageHeader.createdBy;
  //           department = def.content[l].pageHeader.department;
  //         }
  //       }
  //
  //       if (currentPage) {
  //         return [
  //           {
  //             columns: [
  //               {
  //                 image: logoBASE64,
  //                 width: 55,
  //                 margin: [15, 15, 15, 0 ],
  //               },
  //               {
  //                 text: [
  //                   {
  //                     text: 'ASAMBLEA NACIONAL\n',
  //                     margin: [0, 35, 0, 0],
  //                     fontSize: 12,
  //                     bold: true,
  //                     alignment: 'center'
  //                   },
  //                   {
  //                     text: 'SECRETARÍA GENERAL\n',
  //                     margin: [0, 2, 0, 0],
  //                     fontSize: 12,
  //                     bold: true,
  //                     alignment: 'center'
  //                   },
  //                   {
  //                     text: 'PLAN OPERATIVO MENSUAL (POM - F02)\n',
  //                     fontSize: 12,
  //                     bold: true,
  //                     alignment: 'center'
  //                   },
  //                   {
  //                     text: `                                                                                                                                     ${year}                                                                                                                                       `,
  //                     background: '#c2c2c2',
  //                     fontSize: 12,
  //                     bold: true,
  //                     alignment: 'center',
  //                     width: 1
  //                   }
  //                 ],
  //                 margin: [95, 8, 95, 0 ],
  //               },
  //               {
  //                 text: [
  //                   {
  //                     text: '\nCódigo AN/DDI/02\n',
  //                     margin: [0, 50, 0, 0],
  //                     fontSize: 7,
  //                     alignment: 'left'
  //                   },
  //                   {
  //                     text: 'Versión 02\n',
  //                     margin: [0, 2, 0, 0],
  //                     fontSize: 7,
  //                     alignment: 'left'
  //                   },
  //                   {
  //                     text: 'Fecha: 03/03/21\n',
  //                     fontSize: 7,
  //                     alignment: 'left'
  //                   },
  //                 ],
  //                 width: 90,
  //                 padding: [15, 15, 25, 0 ],
  //                 margin: [5, 25, 25, 0]
  //               },
  //             ],
  //             margin: [0, 20, 0, 0]
  //           },
  //           {
  //             layout: 'noBorders',
  //             margin: [90, 4, 90, 0 ],
  //             table: {
  //               headerRows: 0,
  //               widths: [ '*', 300 ],
  //               body: [[
  //                 { text: [
  //                     {text: `Unidad ejecutora: `, fontSize: 9, bold: true, alignment: 'left'},
  //                     {text: `${unit}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
  //                   ]},
  //                 { text: [
  //                     {text: `Fecha: `, fontSize: 9, bold: true, alignment: 'left'},
  //                     {text: `${moment(date).format('DD/MM/yyyy')}`, fontSize: 9, italics: true
  //                       , alignment: 'left', decoration: 'underline'}
  //                   ]},
  //               ]]
  //             }
  //           },
  //           {
  //             layout: 'noBorders',
  //             margin: [90, 4, 90, 0 ],
  //             table: {
  //               headerRows: 0,
  //               widths: [ '*', 300 ],
  //               body: [
  //                 [
  //                   { text: [
  //                       {text: `Nombre del jefe y sello de la dependencia: `, fontSize: 9, bold: true, alignment: 'left'},
  //                       {text: `${leader}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
  //                     ]},
  //                   { text: [
  //                       {text: department ? `Nombre del departamento: ` : '', fontSize: 9, bold: true, alignment: 'left'},
  //                       {text: department ? `${department}` : '', fontSize: 9, italics: true
  //                         , alignment: 'left', decoration: 'underline'}
  //                     ]},
  //                 ]
  //               ]
  //             }
  //           },
  //           {
  //             layout: 'noBorders',
  //             margin: [90, 4, 90, 0 ],
  //             table: {
  //               headerRows: 0,
  //               widths: [ '*', 300 ],
  //               body: [[
  //                 { text: [
  //                     {text: `Elaborado por: `, fontSize: 9, bold: true, alignment: 'left'},
  //                     {text: `${createdBy}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
  //                   ]},
  //                 { text: [
  //                     {text: `Pág. `, fontSize: 9, bold: true, alignment: 'left'},
  //                     {text: `${currentPage}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'},
  //                     {text: ` de `, fontSize: 9, bold: true, alignment: 'left'},
  //                     {text: `${pageCount}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
  //                   ],
  //                 },
  //               ]]
  //             }
  //           }
  //         ];
  //       } else {
  //         return null;
  //       }
  //     },
  //     content: []
  //   };
  //
  //   parsedObjectives.forEach( (f2, currentIndex) => {
  //     // def.content.push(
  //     // );
  //       def.content.push(
  //           {
  //             layout: { paddingTop(index, node) {
  //                 applyVerticalAlignment(node, index, 'center');
  //                 return 0;
  //               }, }, // optional
  //             table: {
  //               // headers are automatically repeated if the table spans over multiple pages
  //               // you can declare how many rows should be treated as headers
  //               headerRows: 2,
  //               widths: [ 120, 80, 80, 60, 60, 80, '*', '*', '*' ],
  //               dontBreakRows: true,
  //
  //               body: [
  //                 [
  //                   { stack: [
  //                       { text: [
  //                           { text: 'Recursos: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
  //                           { text: 'El presupuesto es asignado y administrado por la Dirección General de Administración y Finanzas.',
  //                             fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
  //                         ]},
  //                       { text: [
  //                           { text: 'Fuente: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
  //                           { text: 'Datos provenientes de cada dirección.',
  //                             fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
  //                         ]}
  //                     ],
  //                     colSpan: 9,
  //                     fillColor: '#f6ef52'
  //                   },
  //                   // colSpan needs explicit columns definitions
  //                   { text: '' },
  //                   { text: '' },
  //                   { text: '' },
  //                   { text: '' },
  //                   { text: '' },
  //                   { text: '' },
  //                   { text: '' },
  //                   { text: '' },
  //                 ],
  //                 [ { text: 'OBJETIVOS', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
  //                   { text: 'ACTIVIDADES', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
  //                   { text: 'INDICADOR', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
  //                   { text: 'META ANUAL %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
  //                   { text: 'FRECUENCIA', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
  //                   { text: 'RESPONSABLE', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
  //                   { text: 'META', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
  //                   { text: 'EJECUCIÓN', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
  //                   { text: 'RESULTADO', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' } ]
  //               ],
  //             },
  //           }
  //       );
  //     let lastF1;
  //     f2.forEach( (months, pos) => {
  //       months.forEach( (objectives, index) => {
  //         objectives.forEach( (objective, innerIndex) => {
  //           lastF1 = objective.objective.f1;
  //           def.content[def.content.length - 1].pageHeader = {
  //             unit: objective.objective.f1.unit.name,
  //             date: objective.createdAt,
  //             leader: objective.objective.f1.unit.leader.name + ' ' + objective.objective.f1.unit.leader.lastname,
  //             createdBy: objective.objective.createdBy.name + ' ' + objective.objective.createdBy.lastname,
  //             department: objective.objective.f1.department?.name
  //           }
  //           // console.log(def.content);
  //           def.content[def.content.length - 1].table.body.push(
  //               [
  //                 // @ts-ignore
  //                 { stack: [ { text: innerIndex === 0 ? `${counter + 1}.- ${objective.title}` : '', margin: [0, 8, 0, 8] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
  //                 // @ts-ignore
  //                 { stack: [ { text: !!objective.activities ? objective.activities : '--', margin: [0, 8, 0, 8] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
  //                 // @ts-ignore
  //                 { stack: [ { text: `${!!objective.indicator.upperText ? objective.indicator.upperText : '--'}/ ${!!objective.indicator.bottomText ? (objective.indicator.goal ? objective.indicator.goal.toString() : '#') + ' ' + objective.indicator.bottomText : '--'} x100`, margin: [0, 8, 0, 8] } ], alignment: 'center',
  //                   unbreakable: true, fontSize: 9 },
  //                 // @ts-ignore
  //                 { stack: [ { text: !!objective.annualGoal ? objective.annualGoal : '--', margin: [0, 8, 0, 8] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
  //                 // @ts-ignore
  //                 { stack: [ { text: !!objective.frequency ? this.periodList.find(p => p.value === objective.frequency.value)?.displayValue || '--' : '--', margin: [0, 8, 0, 8]}  ],
  //                   alignment: 'center',
  //                   unbreakable: true, fontSize: 9 },
  //                 // @ts-ignore
  //                 { stack: [ { text: !!objective.responsable ? objective.responsable : '--', margin: [0, 8, 0, 8] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
  //                 // @ts-ignore
  //                 { stack: [ { text: !!objective.goal ? objective.goal : '--', margin: [0, 8, 0, 8] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
  //                 // @ts-ignore
  //                 { stack: [ { text: !!objective.execution ? objective.execution : '--', margin: [0, 8, 0, 8] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
  //                 // @ts-ignore
  //                 { stack: [ { text: this.statusF2List.find( p => p.value === objective.resultStatus)?.displayValue || '--', margin: [0, 8, 0, 8] } ],
  //                   alignment: 'center', unbreakable: true, fontSize: 9, bold: true,
  //                   color: this.statusF2List.find( p => p.value ===  objective.resultStatus)?.color || '#000' },
  //               ]
  //           );
  //         })
  //         counter++;
  //       });
  //       def.content.push(
  //           [
  //             {
  //               pageFooter: lastF1,
  //               layout: 'noBorders',
  //               margin: [0, 0, 0, 0 ],
  //               unbreakable: true,
  //               table: {
  //                 headerRows: 0,
  //                 widths: [ '*' ],
  //                 dontBreakRows: true,
  //                 body: [
  //                   [{
  //                     stack: [
  //                       {text: '', fontSize: 9,
  //                         alignment: 'left', bold: true}
  //                     ]
  //                   }],
  //                 ]
  //               },
  //               pageBreak: data.length - 1 === currentIndex ? null : 'after'
  //             }
  //           ]
  //       )
  //       // @ts-ignore
  //     });
  //   });
  //
  //
  //
  //   this.pdfMake.createPdf(def).download(`F2 Multi.pdf`);
  // }



  // async generateF2PDF(data: any, objectives: any, f3s: any) {
  //   await this.loadPdfMaker();
  //   // console.log(data);
  //
  //   const currentUser =  this.authService.currentUserValue.name
  //       + ' '
  //       + this.authService.currentUserValue.lastname;
  //
  //   const def = {
  //     pageSize: 'LETTER',
  //     pageMargins: [50, 170, 50, 75],
  //     pageOrientation: 'landscape',
  //     footer(currentPage, pageCount, pageSize) {
  //       // if (currentPage > 1) {
  //       return [
  //         {text: currentPage.toString() + ' de ' + pageCount.toString(), alignment: 'right', margin: [0, 30, 75, 0]}
  //       ];
  //       // } else {
  //       //   return null;
  //       // }
  //     },
  //     header(currentPage, pageCount, pageSize) {
  //       // you can apply any logic and return any valid pdfmake element
  //       if (currentPage) {
  //         return [
  //           {
  //             columns: [
  //               {
  //                 image: logoBASE64,
  //                 width: 80,
  //                 margin: [15, 15, 15, 0 ],
  //               },
  //               {
  //                 text: [
  //                   {
  //                     text: 'ASAMBLEA NACIONAL\n',
  //                     margin: [0, 35, 0, 0],
  //                     fontSize: 12,
  //                     bold: true,
  //                     alignment: 'center'
  //                   },
  //                   {
  //                     text: 'SECRETARÍA GENERAL\n',
  //                     margin: [0, 2, 0, 0],
  //                     fontSize: 12,
  //                     bold: true,
  //                     alignment: 'center'
  //                   },
  //                   {
  //                     text: 'PLAN OPERATIVO ANUAL (POA - F02)\n',
  //                     fontSize: 12,
  //                     bold: true,
  //                     alignment: 'center'
  //                   },
  //                   {
  //                     text: `                                                                                             ${data.f1.plan.year}                                                                                             `,
  //                     background: '#c2c2c2',
  //                     fontSize: 12,
  //                     bold: true,
  //                     alignment: 'center',
  //                     width: 1
  //                   }
  //                 ],
  //                 margin: [95, 8, 75, 0 ],
  //               },
  //               {
  //                 text: [
  //                   {
  //                     text: '\nCódigo AN/DDI/02\n',
  //                     margin: [0, 50, 0, 0],
  //                     fontSize: 8,
  //                     alignment: 'left'
  //                   },
  //                   {
  //                     text: 'Versión 02\n',
  //                     margin: [0, 2, 0, 0],
  //                     fontSize: 8,
  //                     alignment: 'left'
  //                   },
  //                   {
  //                     text: 'Fecha: 03/03/21\n',
  //                     fontSize: 8,
  //                     alignment: 'left'
  //                   },
  //                 ],
  //                 width: 100,
  //                 padding: [15, 15, 25, 0 ],
  //                 margin: [10, 25, 0, 0]
  //               },
  //             ],
  //             // margin: [0, 20, 0, 0]
  //           },
  //           {
  //             layout: 'noBorders',
  //             margin: [75, 8, 75, 0 ],
  //             table: {
  //               headerRows: 0,
  //               widths: [ '*', '*' ],
  //               body: [[
  //                 { text: [
  //                     {text: `Unidad ejecutora: `, fontSize: 9, bold: true, alignment: 'left'},
  //                     {text: `${data.f1.unit.name}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
  //                   ]},
  //                 { text: [
  //                     {text: `Fecha: `, fontSize: 9, bold: true, alignment: 'right'},
  //                     {text: `${moment(data.f1.createdAt).format('DD/MM/yyyy')}`, fontSize: 9, italics: true
  //                       , alignment: 'right', decoration: 'underline'}
  //                   ]},
  //               ]]
  //             }
  //           },
  //           {
  //             layout: 'noBorders',
  //             margin: [75, 8, 75, 0 ],
  //             table: {
  //               headerRows: 0,
  //               widths: [ '*' ],
  //               margin: [75, 0, 75, 0 ],
  //               body: [[
  //                 { text: [
  //                     {text: `Nombre del jefe y sello de la dependencia: `, fontSize: 9, bold: true, alignment: 'left'},
  //                     {text: !!data.f1.department ? `${data.f1.department.leader.name + ' '
  //                           + data.f1.department.leader.lastname}` :
  //                           `${data.f1.unit.leader.name + ' '
  //                           + data.f1.unit.leader.lastname}`, fontSize: 9, italics: true,
  //                       alignment: 'center', decoration: 'underline'}
  //                   ]},
  //               ]]
  //             }
  //           },
  //           {
  //             layout: 'noBorders',
  //             margin: [75, 8, 75, 0 ],
  //             table: {
  //               headerRows: 0,
  //               widths: [ '*', '*' ],
  //               body: [[
  //                 { text: [
  //                     {text: `Elaborado por: `, fontSize: 9, bold: true, alignment: 'left'},
  //                     {text: !!data.plans[0].createdBy ? `${data.plans[0].createdBy.name + ' '
  //                       + data.plans[0].createdBy.lastname}` : `${data.f1.responsable.name + ' '
  //                       + data.f1.responsable.lastname}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
  //                   ]},
  //                 { text: [
  //                     {text: `Pág. `, fontSize: 9, bold: true, alignment: 'center'},
  //                     {text: `${currentPage}`, fontSize: 9, italics: true, alignment: 'center', decoration: 'underline'},
  //                     {text: ` de `, fontSize: 9, bold: true, alignment: 'center'},
  //                     {text: `${pageCount}`, fontSize: 9, italics: true, alignment: 'center', decoration: 'underline'}
  //                   ],
  //                 },
  //               ]]
  //             }
  //           }
  //
  //
  //         ];
  //       } else {
  //         return null;
  //       }
  //     },
  //     content: [
  //       {
  //         layout: { paddingTop(index, node) {
  //             applyVerticalAlignment(node, index, 'center');
  //             return 0;
  //           }, }, // optional
  //         table: {
  //           // headers are automatically repeated if the table spans over multiple pages
  //           // you can declare how many rows should be treated as headers
  //           headerRows: 2,
  //           widths: [ 120, 80, 80, 60, 60, 80, '*', '*', '*' ],
  //           dontBreakRows: true,
  //
  //           body: [
  //             [
  //               { stack: [
  //                   { text: [
  //                       { text: 'Recursos: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
  //                       { text: 'El presupuesto es asignado y administrado por la Dirección General de Administración y Finanzas.',
  //                         fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
  //                     ]},
  //                   { text: [
  //                       { text: 'Fuente: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
  //                       { text: 'Datos provenientes de cada dirección.',
  //                         fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
  //                     ]}
  //                 ],
  //                 colSpan: 9,
  //                 fillColor: '#f6ef52'
  //               },
  //               // colSpan needs explicit columns definitions
  //               { text: '' },
  //               { text: '' },
  //               { text: '' },
  //               { text: '' },
  //               { text: '' },
  //               { text: '' },
  //               { text: '' },
  //               { text: '' },
  //             ],
  //             [ { text: 'OBJETIVOS', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
  //               { text: 'ACTIVIDADES', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
  //               { text: 'INDICADOR', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
  //               { text: 'META ANUAL %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
  //               { text: 'FRECUENCIA', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
  //               { text: 'RESPONSABLE', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
  //               { text: 'META', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
  //               { text: 'EJECUCIÓN', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
  //               { text: 'RESULTADO', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' } ]
  //           ],
  //         },
  //       }
  //     ]
  //   };
  //
  //   objectives.objectives.forEach( (f2, index) => {
  //     // @ts-ignore
  //     f2.forEach( (objective, innerIndex) => {
  //         def.content[0].table.body.push(
  //             [
  //               // @ts-ignore
  //               { stack: [ { text: innerIndex === 0 ? `${index + 1}.- ${objective.objectives}` : '', margin: [0, 8, 0, 8] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
  //               // @ts-ignore
  //               { stack: [ { text: !!objective.activities ? objective.activities : '--', margin: [0, 8, 0, 8] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
  //               // @ts-ignore
  //               { stack: [ { text: `${!!objective.indicator.upperText ? objective.indicator.upperText : '--'}/ ${!!objective.indicator.bottomText ? (objective.indicator.goal ? objective.indicator.goal.toString() : '#') + ' ' + objective.indicator.bottomText : '--'} x100`, margin: [0, 8, 0, 8] } ], alignment: 'center',
  //                 unbreakable: true, fontSize: 9 },
  //               // @ts-ignore
  //               { stack: [ { text: !!objective.annualGoal ? objective.annualGoal : '--', margin: [0, 8, 0, 8] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
  //               // @ts-ignore
  //               { stack: [ { text: this.periodList.find(p => p.value === objective.frequency.value)?.displayValue || '--', margin: [0, 8, 0, 8]}  ],
  //                 alignment: 'center',
  //                 unbreakable: true, fontSize: 9 },
  //               // @ts-ignore
  //               { stack: [ { text: !!objective.responsable ? objective.responsable : '--', margin: [0, 8, 0, 8] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
  //               // @ts-ignore
  //               { stack: [ { text: !!objective.indicator.goal ? objective.indicator.goal : '--', margin: [0, 8, 0, 8] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
  //               // @ts-ignore
  //               { stack: [ { text: !!objective.execution ? objective.execution : '--', margin: [0, 8, 0, 8] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
  //               // @ts-ignore
  //               { stack: [ { text: this.statusF2List.find( p => p.value === objective.resultStatus)?.displayValue || '--', margin: [0, 8, 0, 8] } ],
  //                 alignment: 'center', unbreakable: true, fontSize: 9, bold: true,
  //                 color: this.statusF2List.find( p => p.value ===  objective.resultStatus).color },
  //             ]
  //         )
  //     })
  //   });
  //
  //   def.content.push(
  //       // @ts-ignore
  //       [
  //         {
  //           layout: 'noBorders',
  //           margin: [75, 16, 75, 0 ],
  //           unbreakable: true,
  //           table: {
  //             headerRows: 0,
  //             widths: [ '*' ],
  //             dontBreakRows: true,
  //             body: [
  //               [{
  //                 stack: [
  //                   {text: !!data.plans.length && data.plans[0].status === 2 ? `${data.f1.unit.leader.name.toUpperCase() + ' '
  //                     + data.f1.unit.leader.lastname.toUpperCase() }` : 'SIN APROBAR', fontSize: 9,
  //                     alignment: 'left', bold: true}
  //                 ]
  //               }],
  //               [{
  //                 stack: [
  //                   {text: !!data.plans.length && data.plans[0].status === 2 ? 'DIRECTOR DE UNIDAD' : '', fontSize: 9, alignment: 'left'}
  //                 ]
  //               }],
  //             ]
  //           },
  //         }
  //       ]
  //   )
  //
  //
  //   this.pdfMake.createPdf(def).download(`F2.pdf`);
  // }

  async generateF3PDF(data: any, objectives: any, initial?: number, final?: number, forcedDate?: number, approvalBy?: string, isSecondSemester?: boolean, leftFooter?: string, versioning?: YearConstant) {
    await this.loadPdfMaker();

    const def: any = {
      pageSize: 'LETTER',
      pageMargins: [30, 140, 30, 40],
      pageOrientation: 'landscape',
      footer(currentPage, pageCount, pageSize) {
        let showApproved = false;
        if(currentPage == pageCount){
          showApproved = true;
        }
        if (false) {
          return {
            layout: 'noBorders',
            margin: [90, 8, 90, 8 ],
            table: {
              headerRows: 0,
              widths: [ '*', '*' ],
              body: [
                [
                  {
                    text:
                        [
                          {text: !!data.f1.approvalAt ? `${data.f1.unit.leader.name.toUpperCase() + ' '
                            + data.f1.unit.leader.lastname.toUpperCase() + '\n' }` : 'SIN APROBAR\n', fontSize: 9,
                            alignment: 'left', bold: true},
                          {text: !!data.f1.approvalAt ? 'DIRECTOR DE UNIDAD' : '', fontSize: 9, alignment: 'left'}
                        ]
                  },
                  {
                    text:
                        [
                          {text: currentPage.toString() + ' de ' + pageCount.toString(), alignment: 'right'}
                        ]
                  },
                ]
              ]
            }
          }
        } else {
          return {
            layout: 'noBorders',
            margin: [25, 8, 25, 8 ],
            table: {
              headerRows: 0,
              widths: [ '*', '*', '*' ],
              body: [
                [
                  {
                    text:
                        [
                          {text: leftFooter ? leftFooter : '', fontSize: 9,
                            alignment: 'left', bold: true},
                        ]
                  },
                  {
                    text:
                        [
                          {text: '"Documento No Controlado" a excepción del original', fontSize: 9,
                            alignment: 'center'},
                        ]
                  },
                  {
                    text:
                        [
                          {text: ( (initial | 0) + currentPage - (initial ? 1 : 0)).toString() + ' de ' + ( final && final >= pageCount + initial - 1 ? final : pageCount ).toString(), alignment: 'right', fontSize: 9}
                        ]
                  },
                ]
              ]
            }
          }
        }
        // } else {
        //   return null;
        // }
      },
      header(currentPage, pageCount, pageSize) {
        // you can apply any logic and return any valid pdfmake element
        let unit = "";
        let date = "";
        let leader = "";
        let createdBy = "";
        let department = "";
        let year = "";
        for(let l = 0; l < def.content.length; l++){
          if(def.content[l].pageHeader && currentPage >= def.content[l].positions[0].pageNumber){
            unit = def.content[l].pageHeader.unit;
            date = def.content[l].pageHeader.date;
            leader = def.content[l].pageHeader.leader;
            createdBy = def.content[l].pageHeader.createdBy;
            department = def.content[l].pageHeader.department;
            year = def.content[l].pageHeader.year;
          }
        }

        if (currentPage) {
          return [
            {
              columns: [
                {
                  image: logoBASE64,
                  width: 55,
                  margin: [25, 15, 15, 0 ],
                },
                {
                  text: [
                    {
                      text: 'ASAMBLEA NACIONAL\n',
                      margin: [0, 35, 0, 0],
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: 'SECRETARÍA GENERAL\n',
                      margin: [0, 2, 0, 0],
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: 'INFORME DE EVALUACIÓN SEMESTRAL DE ACTIVIDADES Y PROYECTOS DEL ÁREA TÉCNICA Y ADMINISTRATIVA\n',
                      fontSize: 10,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: `                                                                                             ${data.f1.plan.year}                                                                                                       `,
                      background: '#c2c2c2',
                      fontSize: 12,
                      bold: true,
                      alignment: 'center',
                      width: 1
                    }
                  ],
                  margin: [65, 8, 65, 0 ],
                },
                {
                  text: [
                    {
                      text: `\nCódigo ${versioning.f3Header.code}\n`,
                      margin: [0, 50, 0, 0],
                      fontSize: 6,
                      alignment: 'left'
                    },
                    {
                      text: `Versión ${versioning.f3Header.version}\n`,
                      margin: [0, 2, 0, 0],
                      fontSize: 6,
                      alignment: 'left'
                    },
                    {
                      text: `Fecha de versión:\n${versioning.f3Header.date}\n`,
                      fontSize: 6,
                      alignment: 'left'
                    },
                  ],
                  width: 90,
                  padding: [15, 15, 25, 0 ],
                  margin: [5, 25, 25, 0]
                },
              ],
              margin: [0, 20, 0, 0]
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 80 ],
                body: [[
                  { text: [
                      {text: `Unidad ejecutora: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${unit}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: `Fecha: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${date ? moment(date).format('DD/MM/yyyy') : 'Sin aprobar'}`, fontSize: 9, italics: true
                        , alignment: 'left', decoration: 'underline'}
                    ]},
                ]]
              }
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 300 ],
                body: [                  [
                  { text: [
                      {text: `Nombre del jefe y sello de la dependencia: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${leader}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: department ? `Nombre del departamento: ` : '', fontSize: 9, bold: true, alignment: 'left'},
                      {text: department ? `${department}` : '', fontSize: 9, italics: true
                        , alignment: 'left', decoration: 'underline'}
                    ]},
                ]
                ]
              }
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 300 ],
                body: [[
                  { text: [
                      {text: `Elaborado por: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${createdBy || 'Vacío'}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: `Pág. `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${(initial | 0) + currentPage - (initial ? 1 : 0)}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'},
                      {text: ` de `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${final && final >= pageCount + initial - 1 ? final : pageCount}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ],
                  },
                ]]
              }
            }


          ];
        } else {
          return null;
        }
      },
      content: [
        {
          layout: { paddingTop(index, node) {
              applyVerticalAlignment(node, index, 'center');
              return 0;
            }, }, // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 2,
            widths: [ 160, 160, 80, 30, 60, 70, 30, 30, 30 ],
            dontBreakRows: true,

            body: [
              [
                { stack: [
                    { text: [
                        { text: 'Recursos: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
                        { text: 'El presupuesto es asignado y administrado por la Dirección General de Administración y Finanzas.',
                          fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
                      ]},
                    { text: [
                        { text: 'Fuente: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
                        { text: 'Datos provenientes de cada dirección.',
                          fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
                      ]}
                  ],
                  colSpan: 9,
                  fillColor: '#f6ef52'
                },
                // colSpan needs explicit columns definitions
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
              ],
              [ { text: 'OBJETIVOS', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'ACTIVIDADES', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'INDICADOR', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'META ANUAL %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'FRECUENCIA', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'RESPONSABLE', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'I Sem %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'II Sem %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'TOTAL %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' } ]
            ],
          },
        }
      ]
    };

    objectives.objectives.forEach( (f3, index) => {
      f3.forEach( (objective, innerIndex) => {
        def.content[def.content.length - 1].pageHeader = {
          unit: data.f1.unit.name,
          date: isSecondSemester ? data.f1.f3Sem2ExportedAt || data.f1.f3Sem1ExportedAt : data.f1.f3Sem1ExportedAt,
          leader: isSecondSemester && data.f1.responsable2 ? data.f1.responsable2.name + ' ' + data.f1.responsable2.lastname : (data.f1.responsable ? data.f1.responsable.name + ' ' + data.f1.responsable.lastname : data.f1.unit.leader.name + ' ' + data.f1.unit.leader.lastname),
          createdBy: isSecondSemester ? (data.f1.f3Sem2CreatedBy ? data.f1.f3Sem2CreatedBy?.name + ' ' + data.f1.f3Sem2CreatedBy?.lastname : (data.f1.f3Sem1CreatedBy ? data.f1.f3Sem1CreatedBy?.name + ' ' + data.f1.f3Sem1CreatedBy?.lastname : 'Vacío')) : (data.f1.f3Sem1CreatedBy ? data.f1.f3Sem1CreatedBy?.name + ' ' + data.f1.f3Sem1CreatedBy?.lastname : 'Vacío'),
          department: data.f1.department?.name,
          year: data.f1.plan.year
        }

        def.content[0].table.body.push(
            [
              // @ts-ignore
              { stack: [ { text: innerIndex === 0 ? `${index + 1}.- ${objective.objectives}` : '', margin: [0, 2, 0, 2] } ], alignment: 'justify', unbreakable: true, fontSize: 9 },
              // @ts-ignore
              { stack: [ { text: !!objective.activities ? objective.activities : '--', margin: [0, 2, 0, 2] } ], alignment: 'justify', unbreakable: true, fontSize: 9 },
              // @ts-ignore
              { stack: [ { text: `${!!objective.indicator.upperText ? objective.indicator.upperText : '--'}/ ${!!objective.indicator.bottomText ? (objective.indicator.goal || typeof(objective.indicator.goal) === "number" ? objective.indicator.goal.toString() : '#') + ' ' + objective.indicator.bottomText : '--'} x100`, margin: [0, 2, 0, 2] } ], alignment: 'center',
                unbreakable: true, fontSize: 9 },
              // @ts-ignore
              { stack: [ { text: !!objective.annualGoal || typeof(objective.annualGoal) === "number" ? objective.annualGoal : '--', margin: [0, 2, 0, 2] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
              // @ts-ignore
              { stack: [ { text: this.periodList.find(p => p.value === objective.frequency.value)?.displayValue || '--', margin: [0, 2, 0, 2]}  ],
                alignment: 'center',
                unbreakable: true, fontSize: 9 },
              // @ts-ignore
              { stack: [ { text: !!objective.responsable ? objective.responsable : '--', margin: [0, 2, 0, 2] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
              // @ts-ignore
              { stack: [ { text: !!objective.firstSemester ? objective.firstSemester : '0', margin: [0, 2, 0, 2] } ], alignment: 'center',
                unbreakable: true, fontSize: 9, bold: true },
              // @ts-ignore
              { stack: [ { text: !!objective.secondSemester && isSecondSemester ? objective.secondSemester : '0' , margin: [0, 2, 0, 2] } ],
                alignment: 'center', unbreakable: true, fontSize: 9, bold: true },
              // @ts-ignore
              { stack: [ { text: (!isNaN(objective.firstSemester) && !isNaN(objective.secondSemester)) && isSecondSemester ? objective.totalAdvance : '--', margin: [0, 2, 0, 2] } ], alignment: 'center',
                unbreakable: true, fontSize: 9, bold: true },
            ]
        );
      })
    });

    // def.content.push(
    //     // @ts-ignore
    //     [
    //       {
    //         pageFooter: data.f1,
    //         layout: 'noBorders',
    //         margin: [0, 0, 0, 0 ],
    //         unbreakable: true,
    //         table: {
    //           headerRows: 0,
    //           widths: [ '*' ],
    //           dontBreakRows: true,
    //           body: [
    //             [{
    //               stack: [
    //                 {text: '', fontSize: 9,
    //                   alignment: 'left', bold: true}
    //               ]
    //             }]
    //           ]
    //         },
    //       }
    //     ]
    // )


    this.pdfMake.createPdf(def).download(`F3.pdf`);
  }

  async generateF1MonthlyOverviewPDF(data: any, objectives: any, f3s: any, targetMonth: number, initial?: number, final?: number, forcedDate?: Date, approvalBy? : string, leftFooter?: string, versioning?: YearConstant, director?: string) {
    await this.loadPdfMaker();
    const currentUser =  this.authService.currentUserValue.name
        + ' '
        + this.authService.currentUserValue.lastname;

    const def: any = {
      pageSize: 'LEGAL',
      pageMargins: [30, 140, 30, 40],
      pageOrientation: 'landscape',
      footer(currentPage, pageCount, pageSize) {
        let showApproved = false;
        if(currentPage == pageCount){
          showApproved = true;
        }
        if (false) {
          return {
            layout: 'noBorders',
            margin: [90, 8, 90, 8 ],
            table: {
              headerRows: 0,
              widths: [ '*', '*' ],
              body: [
                [
                  {
                    text:
                        [
                          {text: !!data.f1.approvalAt ? `${data.f1.unit.leader.name.toUpperCase() + ' '
                            + data.f1.unit.leader.lastname.toUpperCase() + '\n' }` : 'SIN APROBAR\n', fontSize: 9,
                            alignment: 'left', bold: true},
                          {text: !!data.f1.approvalAt ? 'DIRECTOR DE UNIDAD' : '', fontSize: 9, alignment: 'left'}
                        ]
                  },
                  {
                    text:
                        [
                          {text: currentPage.toString() + ' de ' + pageCount.toString(), alignment: 'right'}
                        ]
                  },
                ]
              ]
            }
          }
        } else {
          return {
            layout: 'noBorders',
            margin: [25, 8, 25, 8 ],
            table: {
              headerRows: 0,
              widths: [ '*', '*', '*' ],
              body: [
                [
                  {
                    text:
                        [
                          {text: leftFooter ? leftFooter : '', fontSize: 9,
                            alignment: 'left', bold: true},
                        ]
                  },
                  {
                    text:
                        [
                          {text: '"Documento No Controlado" a excepción del original', fontSize: 9,
                            alignment: 'center'},
                        ]
                  },
                  {
                    text:
                        [
                          {text: ( (initial | 0) + currentPage - (initial ? 1 : 0)).toString() + ' de ' + ( final && final >= pageCount + initial - 1 ? final : pageCount ).toString(), alignment: 'right', fontSize: 9}
                        ]
                  },
                ]
              ]
            }
          }
        }
        // } else {
        //   return null;
        // }
      },
      header(currentPage, pageCount, pageSize) {
        // you can apply any logic and return any valid pdfmake element
        let unit = "";
        let date = "";
        let leader = "";
        let createdBy = "";
        let department = "";
        let year = "";
        for(let l = 0; l < def.content.length; l++){
          if(def.content[l].pageHeader && currentPage >= def.content[l].positions[0].pageNumber){
            unit = def.content[l].pageHeader.unit;
            date = def.content[l].pageHeader.date;
            leader = def.content[l].pageHeader.leader;
            createdBy = def.content[l].pageHeader.createdBy;
            department = def.content[l].pageHeader.department;
            year = def.content[l].pageHeader.year;
          }
        }

        if (currentPage) {
          return [
            {
              columns: [
                {
                  image: logoBASE64,
                  width: 55,
                  margin: [15, 15, 15, 0 ],
                },
                {
                  text: [
                    {
                      text: 'ASAMBLEA NACIONAL\n',
                      margin: [0, 35, 0, 0],
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: 'SECRETARÍA GENERAL\n',
                      margin: [0, 2, 0, 0],
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: 'INFORME DE AVANCE DE ACTIVIDADES Y PROYECTOS DEL MES\n',
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: `                                                                                                                                     ${year}                                                                                                                                       `,
                      background: '#c2c2c2',
                      fontSize: 12,
                      bold: true,
                      alignment: 'center',
                      width: 1
                    }
                  ],
                  margin: [95, 8, 75, 0 ],
                },
                {
                  text: [
                    {
                      text: `\nCódigo ${versioning.f2Header.code}\n`,
                      margin: [0, 50, 0, 0],
                      fontSize: 6,
                      alignment: 'left'
                    },
                    {
                      text: `Versión ${versioning.f2Header.version}\n`,
                      margin: [0, 2, 0, 0],
                      fontSize: 6,
                      alignment: 'left'
                    },
                    {
                      text: `Fecha de versión:\n${versioning.f2Header.date}\n`,
                      fontSize: 6,
                      alignment: 'left'
                    },
                  ],
                  width: 90,
                  padding: [15, 15, 25, 0 ],
                  margin: [5, 25, 25, 0]
                },
              ],
              margin: [0, 20, 0, 0]
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 80 ],
                body: [[
                  { text: [
                      {text: `Unidad ejecutora: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${unit}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: `Fecha: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${forcedDate ? moment(forcedDate).format('DD/MM/yyyy') : (date ? moment(date).format('DD/MM/yyyy') : 'Sin aprobar')}`, fontSize: 9, italics: true
                        , alignment: 'left', decoration: 'underline'}
                    ]},
                ]]
              }
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 375 ],
                body: [[
                  { text: [
                      {text: `Nombre del jefe y sello de la dependencia: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${leader}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: department ? `Nombre del departamento: ` : '', fontSize: 9, bold: true, alignment: 'left'},
                      {text: department ? `${department}` : '', fontSize: 9, italics: true
                        , alignment: 'left', decoration: 'underline'}
                    ]},
                ]]
              }
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 375 ],
                body: [[
                  { text: [
                      {text: `Elaborado por: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${approvalBy || 'Vacío'}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: `Pág. `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${(initial | 0) + currentPage - (initial ? 1 : 0)}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'},
                      {text: ` de `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${final && final >= pageCount + initial - 1 ? final : pageCount}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ],
                  },
                ]]
              }
            }


          ];
        } else {
          return null;
        }
      },
      content: [
        {
          layout: { paddingTop(index, node) {
              applyVerticalAlignment(node, index, 'center');
              return 0;
            },
            paddingLeft: function(i, node) { return 0; },
            paddingRight: function(i, node) { return 0; },
            paddingBottom: function(i, node) { return 0; },
          }, // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 3,
            widths: [ 120, 120, 38, 26, 44, 50, 27, 27, 27, 27, 27, 27, 30, 27, 27, 27, 27, 27, 27, 30, 30, 110 ],
            dontBreakRows: true,

            body: [
              [
                { stack: [
                    { text: [
                        { text: 'Recursos: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
                        { text: 'El presupuesto es asignado y administrado por la Dirección General de Administración y Finanzas.',
                          fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
                      ]},
                    { text: [
                        { text: 'Fuente: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
                        { text: 'Datos provenientes de cada dirección.',
                          fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
                      ]}
                  ],
                  colSpan: 22,
                  fillColor: '#f6ef52'
                },
                // colSpan needs explicit columns definitions
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
                { text: '' },
              ],
              [
                { text: 'OBJETIVOS', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'ACTIVIDADES', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'INDICADOR', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'META ANUAL %', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'FRECUENCIA', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'RESPONSABLE', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'DISTRIBUCIÓN DE LA META POR MES', fontSize: 7, bold: true, alignment: 'center', colSpan: 16, fillColor: '#275ead', color: '#ffffff' },
                // { text: '', fillColor: '#275ead' },
                // { text: '', fillColor: '#275ead' },
                // { text: '', fillColor: '#275ead' },
                // { text: '', fillColor: '#275ead' },
                // { text: '', fillColor: '#275ead' },
                // { text: '', fillColor: '#275ead' },
                // { text: 'E', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                // { text: 'F', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                // { text: 'M', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                // { text: 'A', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                // { text: 'MY', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                // { text: 'JN', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                // { text: 'AVANCE I\nSem %', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                // { text: 'JL', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                // { text: 'AG', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                // { text: 'S', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                // { text: 'O', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                // { text: 'N', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                // { text: 'D', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                // { text: 'AVANCE II\nSem %', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                // { text: 'TOTAL DEL AÑO\n%', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
              ],
              [
                { text: '', fillColor: '#275ead' },
                { text: '', fillColor: '#275ead' },
                { text: '', fillColor: '#275ead' },
                { text: '', fillColor: '#275ead' },
                { text: '', fillColor: '#275ead' },
                { text: '', fillColor: '#275ead' },
                { text: 'E', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'F', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'M', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'A', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'MY', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'JN', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'AVANCE I\nSem %', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'JL', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'AG', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'S', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'O', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'N', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'D', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'AVANCE II\nSem %', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'TOTAL DEL AÑO\n%', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                { text: 'OBSERVACIONES', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
              ],
            ],
          },
        }
      ]
    };

    objectives.objectives.forEach( (f1, index) => {
      f1.forEach( (objective, innerIndex) => {
        objective.plans = data.plan || data.plans.find((data) => objective.id === data.id);
        objective.f3 = (f3s.find( (data) => objective.id === data.id )).f3;
        objective.responsable = (f3s.find( (data) => objective.id === data.id )).responsable;
        def.content[def.content.length - 1].pageHeader = {
          unit: data.f1.unit.name,
          date: data.approvalAt,
          leader: director || data.f1.unit.leader.name + ' ' + data.f1.unit.leader.lastname,
          createdBy: data.f1.createdBy?.name + ' ' + data.f1.createdBy?.lastname,
          department: data.f1.department?.name,
          year: data.f1.plan.year
        }
        // @ts-ignore
        def.content[0].table.body.push(
            [
              // @ts-ignore
              { stack: [ { text: innerIndex === 0 ? `${index + 1}.- ${objective.objectives}` : '', margin: [2, 2, 2, 2] } ], alignment: 'justify', unbreakable: true, fontSize: 7},
              // @ts-ignore
              { stack: [ { text: !!objective.activities ? objective.activities : '--', margin: [2, 2, 2, 2] } ], alignment: 'justify', unbreakable: true, fontSize: 7 },
              // @ts-ignore
              { stack: [ { text: `${!!objective.plans.indicator.upperText ? objective.plans.indicator.upperText : '--'}/ ${!!objective.plans.indicator.bottomText ? (objective.plans.indicator.goal ? objective.plans.indicator.goal.toString() : '#') + ' ' + objective.plans.indicator.bottomText : '--'} x100`, margin: [2, 2, 2, 2] } ], alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7 },
              // @ts-ignore
              { stack: [ { text: !!objective.plans.annualGoal ? objective.plans.annualGoal + ' %' : '--', margin: [2, 2, 2, 2] } ], alignment: 'center', unbreakable: true, fontSize: 7 },
              // @ts-ignore
              { stack: [ { text: this.periodList.find(p => p.value === objective.plans.frequency)?.displayValue, margin: [2, 2, 2, 2]}  ],
                // @ts-ignore
                alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7 },
              // @ts-ignore
              { stack: [ { text: !!objective.responsable ? objective.responsable : '--', margin: [2, 2, 2, 2] } ], alignment: 'center', unbreakable: true, fontSize: 7 },
              // @ts-ignore
              { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7, bold: true },
              // @ts-ignore
              { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7, bold: true },
              // @ts-ignore
              { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7, bold: true },
              // @ts-ignore
              { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7, bold: true },
              // @ts-ignore
              { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7, bold: true },
              // @ts-ignore
              { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7, bold: true },
              // @ts-ignore
              { stack: [ { text: '\xa0' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7, bold: true },
              // @ts-ignore
              { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7, bold: true },
              // @ts-ignore
              { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7, bold: true },
              // @ts-ignore
              { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7, bold: true },
              // @ts-ignore
              { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7, bold: true },
              // @ts-ignore
              { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7, bold: true },
              // @ts-ignore
              { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7, bold: true },
              // @ts-ignore
              { stack: [ { text: '\xa0' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7, bold: true },
              // @ts-ignore
              { stack: [ { text: '\xa0' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                // @ts-ignore
                unbreakable: true, fontSize: 7, bold: true },
              // @ts-ignore
              { stack: [ { text:
                      (targetMonth === 12 && objective.results.dic?.observations?.substring(0, 350).trim())
                      || (targetMonth === 11 && objective.results.nov?.observations?.substring(0, 350).trim())
                      || (targetMonth === 10 && objective.results.oct?.observations?.substring(0, 350).trim())
                      || (targetMonth === 9 && objective.results.sep?.observations?.substring(0, 350).trim())
                      || (targetMonth === 8 && objective.results.ago?.observations?.substring(0, 350).trim())
                      || (targetMonth === 7 && objective.results.jul?.observations?.substring(0, 350).trim())
                      || (targetMonth === 6 && objective.results.jun?.observations?.substring(0, 350).trim())
                      || (targetMonth === 5 && objective.results.may?.observations?.substring(0, 350).trim())
                      || (targetMonth === 4 && objective.results.abr?.observations?.substring(0, 350).trim())
                      || (targetMonth === 3 && objective.results.mar?.observations?.substring(0, 350).trim())
                      || (targetMonth === 2 && objective.results.feb?.observations?.substring(0, 350).trim())
                      || (targetMonth === 1 && objective.results.ene?.observations?.substring(0, 350).trim())
                      || ''
                  , margin: [2, 2, 2, 2] } ], alignment: 'justify',
                // @ts-ignore
                unbreakable: true, fontSize: 7 },
            ],
            [
              { stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          text: 'META\n', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2', margin: [2, 0, 2, 0]
                        }]
                      ],
                    },
                    colSpan: 6
                  },
                  // { text: 'META\n', fontSize: 8, colSpan: 6, color: '#FF0000', fillColor: '#c2c2c2' },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          text: 'EJECUCIÓN', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2', margin: [2, 0, 2, 0]
                        }]
                      ],
                    },
                    colSpan: 6
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: 'RESULTADO', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2', margin: [2, 0, 2, 0]
                        }]
                      ],
                    },
                    colSpan: 6
                  }
                ], unbreakable: true, fillColor: '#c2c2c2' },
              { stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  }
                ], unbreakable: true, fillColor: '#c2c2c2' },
              { stack: [
                                    {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  },
                                    {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  }
                ], unbreakable: true, fillColor: '#c2c2c2' },
              { stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  }
                ], unbreakable: true, fillColor: '#c2c2c2' },
              { stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  }
                ], unbreakable: true, fillColor: '#c2c2c2' },
              { stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  }
                ], unbreakable: true, fillColor: '#c2c2c2' },
              {
                stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.ene && (targetMonth >= 1) ? (typeof(objective.results.ene?.goal) === 'number' ? objective.results.ene?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.ene && (targetMonth >= 1) ? (typeof(objective.results.ene?.execution) === 'number' ? objective.results.ene?.execution : '\xa0') : '\xa0',
                          fontSize: 8,
                          fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      paddingLeft: function(i, node) { return 4; },
                      paddingRight: function(i, node) { return 4; },
                      paddingTop: function(i, node) { return 2; },
                      paddingBottom: function(i, node) { return 1; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: (!!objective.results.ene && objective.results.ene?.goal) && (targetMonth >= 1) ? ((objective.results.ene.execution / objective.results.ene.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.ene?.goal) === 'number' && typeof(objective.results.ene?.execution) === 'number') && (targetMonth >= 1) ? '0%' : '\xa0',
                          fontSize: 7,
                          bold: true,
                          fillColor: targetMonth >= 1 && objective.monthAvailability[0].color ? (this.getF2GoalColor(objective.results.ene?.resultStatus , objective.plans.frequency, 'ene' ) || '#c2c2c2') : '#c2c2c2',
                          alignment:"center"
                        }]
                      ],
                    },
                  },
                ], unbreakable: true, fillColor: '#c2c2c2'
              },
              // { stack: [ { text: objective.results.ene && (targetMonth >= 1) ? (typeof(objective.results.ene?.goal) === 'number' ? objective.results.ene?.goal : '#') : '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
              {
                stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.feb && (targetMonth >= 2) ? (typeof(objective.results.feb?.goal) === 'number' ? objective.results.feb?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.feb && (targetMonth >= 2) ? (typeof(objective.results.feb?.execution) === 'number' ? objective.results.feb?.execution : '\xa0') : '\xa0',
                          fontSize: 8,
                          fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      paddingLeft: function(i, node) { return 4; },
                      paddingRight: function(i, node) { return 4; },
                      paddingTop: function(i, node) { return 2; },
                      paddingBottom: function(i, node) { return 1; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: (!!objective.results.feb && objective.results.feb?.goal) && (targetMonth >= 2) ? ((objective.results.feb.execution / objective.results.feb.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.feb?.goal) === 'number' && typeof(objective.results.feb?.execution) === 'number') && (targetMonth >= 2) ? '0%' : '\xa0',
                          fontSize: 7,
                          bold: true,
                          fillColor: targetMonth >= 2 && objective.monthAvailability[1].color ? (this.getF2GoalColor(objective.results.feb?.resultStatus , objective.plans.frequency, 'feb' ) || '#c2c2c2') : '#c2c2c2',
                          alignment:"center"
                        }]
                      ],
                    },
                  },
                ], unbreakable: true, fillColor: '#c2c2c2'
              },
              {
                stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.mar && (targetMonth >= 3) ? (typeof(objective.results.mar?.goal) === 'number' ? objective.results.mar?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.mar && (targetMonth >= 3) ? (typeof(objective.results.mar?.execution) === 'number' ? objective.results.mar?.execution : '\xa0') : '\xa0',
                          fontSize: 8,
                          fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      paddingLeft: function(i, node) { return 4; },
                      paddingRight: function(i, node) { return 4; },
                      paddingTop: function(i, node) { return 2; },
                      paddingBottom: function(i, node) { return 1; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: (!!objective.results.mar && objective.results.mar?.goal) && (targetMonth >= 3) ? ((objective.results.mar.execution / objective.results.mar.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.mar?.goal) === 'number' && typeof(objective.results.mar?.execution) === 'number') && (targetMonth >= 3) ? '0%' : '\xa0',
                          fontSize: 7,
                          bold: true,
                          fillColor: targetMonth >= 3 && objective.monthAvailability[2].color ? (this.getF2GoalColor(objective.results.mar?.resultStatus , objective.plans.frequency, 'mar' ) || '#c2c2c2') : '#c2c2c2',
                          alignment:"center"
                        }]
                      ],
                    },
                  },
                ], unbreakable: true, fillColor: '#c2c2c2'
              },
              {
                stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.abr && (targetMonth >= 4) ? (typeof(objective.results.abr?.goal) === 'number' ? objective.results.abr?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.abr && (targetMonth >= 4) ? (typeof(objective.results.abr?.execution) === 'number' ? objective.results.abr?.execution : '\xa0') : '\xa0',
                          fontSize: 8,
                          fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      paddingLeft: function(i, node) { return 4; },
                      paddingRight: function(i, node) { return 4; },
                      paddingTop: function(i, node) { return 2; },
                      paddingBottom: function(i, node) { return 1; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: (!!objective.results.abr && objective.results.abr?.goal) && (targetMonth >= 4) ? ((objective.results.abr.execution / objective.results.abr.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.abr?.goal) === 'number' && typeof(objective.results.abr?.execution) === 'number') && (targetMonth >= 4) ? '0%' : '\xa0',
                          fontSize: 7,
                          bold: true,
                          fillColor: targetMonth >= 4 && objective.monthAvailability[3].color ? (this.getF2GoalColor(objective.results.abr?.resultStatus , objective.plans.frequency, 'abr' ) || '#c2c2c2') : '#c2c2c2',
                          alignment:"center"
                        }]
                      ],
                    },
                  },
                ], unbreakable: true, fillColor: '#c2c2c2'
              },
              {
                stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.may && (targetMonth >= 5) ? (typeof(objective.results.may?.goal) === 'number' ? objective.results.may?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.may && (targetMonth >= 5) ? (typeof(objective.results.may?.execution) === 'number' ? objective.results.may?.execution : '\xa0') : '\xa0',
                          fontSize: 8,
                          fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      paddingLeft: function(i, node) { return 4; },
                      paddingRight: function(i, node) { return 4; },
                      paddingTop: function(i, node) { return 2; },
                      paddingBottom: function(i, node) { return 1; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: (!!objective.results.may && objective.results.may?.goal) && (targetMonth >= 5) ? ((objective.results.may.execution / objective.results.may.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.may?.goal) === 'number' && typeof(objective.results.may?.execution) === 'number') && (targetMonth >= 5) ? '0%' : '\xa0',
                          fontSize: 7,
                          bold: true,
                          fillColor: targetMonth >= 5 && objective.monthAvailability[4].color ? (this.getF2GoalColor(objective.results.may?.resultStatus , objective.plans.frequency, 'may' ) || '#c2c2c2') : '#c2c2c2',
                          alignment:"center"
                        }]
                      ],
                    },
                  },
                ], unbreakable: true, fillColor: '#c2c2c2'
              },
              {
                stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.jun && (targetMonth >= 6) ? (typeof(objective.results.jun?.goal) === 'number' ? objective.results.jun?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.jun && (targetMonth >= 6) ? (typeof(objective.results.jun?.execution) === 'number' ? objective.results.jun?.execution : '\xa0') : '\xa0',
                          fontSize: 8,
                          fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      paddingLeft: function(i, node) { return 4; },
                      paddingRight: function(i, node) { return 4; },
                      paddingTop: function(i, node) { return 2; },
                      paddingBottom: function(i, node) { return 1; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: (!!objective.results.jun && objective.results.jun?.goal) && (targetMonth >= 6) ? ((objective.results.jun.execution / objective.results.jun.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.jun?.goal) === 'number' && typeof(objective.results.jun?.execution) === 'number') && (targetMonth >= 6) ? '0%' : '\xa0',
                          fontSize: 7,
                          bold: true,
                          fillColor: targetMonth >= 6 && objective.monthAvailability[5].color ? (this.getF2GoalColor(objective.results.jun?.resultStatus , objective.plans.frequency, 'jun' ) || '#c2c2c2') : '#c2c2c2',
                          alignment:"center"
                        }]
                      ],
                    },
                  },
                ], unbreakable: true, fillColor: '#c2c2c2'
              },
              { stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 4; },
                      paddingRight: function(i, node) { return 4; },
                      paddingTop: function(i, node) { return 2; },
                      paddingBottom: function(i, node) { return 1; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          alignment:"center",
                          text: !!objective.f3 && targetMonth >= 6 ? (+objective.f3.semester1).toFixed(0) + ' %' : '--',
                          fontSize: 7,
                          bold: true,
                          fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  }
                ], unbreakable: true, fillColor: '#c2c2c2' },
              {
                stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.jul && (targetMonth >= 7) ? (typeof(objective.results.jul?.goal) === 'number' ? objective.results.jul?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.jul && (targetMonth >= 7) ? (typeof(objective.results.jul?.execution) === 'number' ? objective.results.jul?.execution : '\xa0') : '\xa0',
                          fontSize: 8,
                          fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      paddingLeft: function(i, node) { return 4; },
                      paddingRight: function(i, node) { return 4; },
                      paddingTop: function(i, node) { return 2; },
                      paddingBottom: function(i, node) { return 1; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: (!!objective.results.jul && objective.results.jul?.goal) && (targetMonth >= 7) ? ((objective.results.jul.execution / objective.results.jul.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.jul?.goal) === 'number' && typeof(objective.results.jul?.execution) === 'number') && (targetMonth >= 7) ? '0%' : '\xa0',
                          fontSize: 7,
                          bold: true,
                          fillColor: targetMonth >= 7  && objective.monthAvailability[6].color? (this.getF2GoalColor(objective.results.jul?.resultStatus , objective.plans.frequency, 'jul' ) || '#c2c2c2') : '#c2c2c2',
                          alignment:"center"
                        }]
                      ],
                    },
                  },
                ], unbreakable: true, fillColor: '#c2c2c2'
              },
              {
                stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.ago && (targetMonth >= 8) ? (typeof(objective.results.ago?.goal) === 'number' ? objective.results.ago?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.ago && (targetMonth >= 8) ? (typeof(objective.results.ago?.execution) === 'number' ? objective.results.ago?.execution : '\xa0') : '\xa0',
                          fontSize: 8,
                          fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      paddingLeft: function(i, node) { return 4; },
                      paddingRight: function(i, node) { return 4; },
                      paddingTop: function(i, node) { return 2; },
                      paddingBottom: function(i, node) { return 1; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: (!!objective.results.ago && objective.results.ago?.goal) && (targetMonth >= 8) ? ((objective.results.ago.execution / objective.results.ago.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.ago?.goal) === 'number' && typeof(objective.results.ago?.execution) === 'number') && (targetMonth >= 8) ? '0%' : '\xa0',
                          fontSize: 7,
                          bold: true,
                          fillColor: targetMonth >= 8  && objective.monthAvailability[7].color? (this.getF2GoalColor(objective.results.ago?.resultStatus , objective.plans.frequency, 'ago' ) || '#c2c2c2') : '#c2c2c2',
                          alignment:"center"
                        }]
                      ],
                    },
                  },
                ], unbreakable: true, fillColor: '#c2c2c2'
              },
              {
                stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.sep && (targetMonth >= 9) ? (typeof(objective.results.sep?.goal) === 'number' ? objective.results.sep?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.sep && (targetMonth >= 9) ? (typeof(objective.results.sep?.execution) === 'number' ? objective.results.sep?.execution : '\xa0') : '\xa0',
                          fontSize: 8,
                          fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      paddingLeft: function(i, node) { return 4; },
                      paddingRight: function(i, node) { return 4; },
                      paddingTop: function(i, node) { return 2; },
                      paddingBottom: function(i, node) { return 1; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: (!!objective.results.sep && objective.results.sep?.goal) && (targetMonth >= 9) ? ((objective.results.sep.execution / objective.results.sep.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.sep?.goal) === 'number' && typeof(objective.results.sep?.execution) === 'number') && (targetMonth >= 9) ? '0%' : '\xa0',
                          fontSize: 7,
                          bold: true,
                          fillColor: targetMonth >= 9  && objective.monthAvailability[8].color? (this.getF2GoalColor(objective.results.sep?.resultStatus , objective.plans.frequency, 'sep' ) || '#c2c2c2') : '#c2c2c2',
                          alignment:"center"
                        }]
                      ],
                    },
                  },
                ], unbreakable: true, fillColor: '#c2c2c2'
              },
              {
                stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.oct && (targetMonth >= 10) ? (typeof(objective.results.oct?.goal) === 'number' ? objective.results.oct?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.oct && (targetMonth >= 10) ? (typeof(objective.results.oct?.execution) === 'number' ? objective.results.oct?.execution : '\xa0') : '\xa0',
                          fontSize: 8,
                          fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      paddingLeft: function(i, node) { return 4; },
                      paddingRight: function(i, node) { return 4; },
                      paddingTop: function(i, node) { return 2; },
                      paddingBottom: function(i, node) { return 1; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: (!!objective.results.oct && objective.results.oct?.goal) && (targetMonth >= 10) ? ((objective.results.oct.execution / objective.results.oct.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.oct?.goal) === 'number' && typeof(objective.results.oct?.execution) === 'number') && (targetMonth >= 10) ? '0%' : '\xa0',
                          fontSize: 7,
                          bold: true,
                          fillColor: targetMonth >= 10 && objective.monthAvailability[9].color ? (this.getF2GoalColor(objective.results.oct?.resultStatus , objective.plans.frequency, 'oct' ) || '#c2c2c2') : '#c2c2c2',
                          alignment:"center"
                        }]
                      ],
                    },
                  },
                ], unbreakable: true, fillColor: '#c2c2c2'
              },
              {
                stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.nov && (targetMonth >= 11) ? (typeof(objective.results.nov?.goal) === 'number' ? objective.results.nov?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.nov && (targetMonth >= 11) ? (typeof(objective.results.nov?.execution) === 'number' ? objective.results.nov?.execution : '\xa0') : '\xa0',
                          fontSize: 8,
                          fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      paddingLeft: function(i, node) { return 4; },
                      paddingRight: function(i, node) { return 4; },
                      paddingTop: function(i, node) { return 2; },
                      paddingBottom: function(i, node) { return 1; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: (!!objective.results.nov && objective.results.nov?.goal) && (targetMonth >= 11) ? ((objective.results.nov.execution / objective.results.nov.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.nov?.goal) === 'number' && typeof(objective.results.nov?.execution) === 'number') && (targetMonth >= 11) ? '0%' : '\xa0',
                          fontSize: 7,
                          bold: true,
                          fillColor: targetMonth >= 11 && objective.monthAvailability[10].color ? (this.getF2GoalColor(objective.results.nov?.resultStatus , objective.plans.frequency, 'nov' ) || '#c2c2c2') : '#c2c2c2',
                          alignment:"center"
                        }]
                      ],
                    },
                  },
                ], unbreakable: true, fillColor: '#c2c2c2'
              },
              {
                stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.dic && (targetMonth >= 12) ? (typeof(objective.results.dic?.goal) === 'number' ? objective.results.dic?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: objective.results.dic && (targetMonth >= 12) ? (typeof(objective.results.dic?.execution) === 'number' ? objective.results.dic?.execution : '\xa0') : '\xa0',
                          fontSize: 8,
                          fillColor: '#c2c2c2'
                        }]
                      ]
                    }
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                      paddingLeft: function(i, node) { return 4; },
                      paddingRight: function(i, node) { return 4; },
                      paddingTop: function(i, node) { return 2; },
                      paddingBottom: function(i, node) { return 1; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          text: (!!objective.results.dic && objective.results.dic?.goal) && (targetMonth >= 12) ? ((objective.results.dic.execution / objective.results.dic.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.dic?.goal) === 'number' && typeof(objective.results.dic?.execution) === 'number') && (targetMonth >= 12) ? '0%' : '\xa0',
                          fontSize: 7,
                          bold: true,
                          fillColor: targetMonth >= 12 && objective.monthAvailability[11].color ? (this.getF2GoalColor(objective.results.dic?.resultStatus , objective.plans.frequency, 'dec' ) || '#c2c2c2') : '#c2c2c2',
                          alignment:"center"
                        }]
                      ],
                    },
                  },
                ], unbreakable: true, fillColor: '#c2c2c2'
              },
              { stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          alignment:"center",
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 4; },
                      paddingRight: function(i, node) { return 4; },
                      paddingTop: function(i, node) { return 2; },
                      paddingBottom: function(i, node) { return 1; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          alignment:"center",
                          text: !!objective.f3 && targetMonth >= 12 ? (+objective.f3.semester2).toFixed(0) + ' %' : '--',
                          fontSize: 7,
                          bold: true,
                          fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  }
                ], unbreakable: true, fillColor: '#c2c2c2' },
              { stack: [
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 1;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 0; },
                      paddingRight: function(i, node) { return 0; },
                      paddingTop: function(i, node) { return 0; },
                      paddingBottom: function(i, node) { return 0; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, true],
                          text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  },
                  {
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function(i, node) { return 4; },
                      paddingRight: function(i, node) { return 4; },
                      paddingTop: function(i, node) { return 2; },
                      paddingBottom: function(i, node) { return 1; },
                      fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                    },
                    table: {
                      widths:'*',
                      body: [
                        [{
                          border: [false, false, false, false],
                          alignment:"center",
                          text: !!objective.f3 && targetMonth >= 12 ? (+objective.f3.total).toFixed(0) + ' %' : '--',
                          fontSize: 7,
                          bold: true,
                          fillColor: '#c2c2c2'
                        }]
                      ],
                    },
                  }
                ], unbreakable: true, fillColor: '#c2c2c2' },
              { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            ],
            // [
            //   { stack: [ { text: 'EJECUCIÓN', fontSize: 8, colSpan: 6, color: '#FF0000', fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: objective.results.ene?.execution && (targetMonth >= 1) ? objective.results.ene?.execution : '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: objective.results.feb?.execution && (targetMonth >= 2) ? objective.results.feb?.execution : '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: objective.results.mar?.execution && (targetMonth >= 3) ? objective.results.mar?.execution : '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: objective.results.abr?.execution && (targetMonth >= 4) ? objective.results.abr?.execution : '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: objective.results.may?.execution && (targetMonth >= 5) ? objective.results.may?.execution : '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: objective.results.jun?.execution && (targetMonth >= 6) ? objective.results.jun?.execution : '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: objective.results.jul?.execution && (targetMonth >= 7) ? objective.results.jul?.execution : '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: objective.results.ago?.execution && (targetMonth >= 8) ? objective.results.ago?.execution : '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: objective.results.sep?.execution && (targetMonth >= 9) ? objective.results.sep?.execution : '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: objective.results.oct?.execution && (targetMonth >= 10) ? objective.results.oct?.execution : '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: objective.results.nov?.execution && (targetMonth >= 11) ? objective.results.nov?.execution : '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: objective.results.dic?.execution && (targetMonth >= 12) ? objective.results.dic?.execution : '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            // ],
            // [
            //   { stack: [ { text: 'RESULTADO ', fontSize: 8, colSpan: 6, color: '#FF0000', fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: (!!objective.results.ene && objective.results.ene?.goal) && (targetMonth >= 1) ? ((objective.results.ene.execution / objective.results.ene.goal) * 100).toFixed(0) + ' %' : '', fontSize: 7, bold: true  } ], unbreakable: true, fillColor: targetMonth >= 1 ? (this.getF2GoalColor(objective.results.ene?.resultStatus , objective.plans.frequency, 'ene' ) || '#c2c2c2') : '#c2c2c2'},
            //   { stack: [ { text: (!!objective.results.feb && objective.results.feb?.goal) && (targetMonth >= 2) ? ((objective.results.feb.execution / objective.results.feb.goal) * 100).toFixed(0) + ' %' : '', fontSize: 7, bold: true  } ], unbreakable: true, fillColor: targetMonth >= 2 ? (this.getF2GoalColor(objective.results.feb?.resultStatus , objective.plans.frequency, 'feb' ) || '#c2c2c2') : '#c2c2c2'},
            //   { stack: [ { text: (!!objective.results.mar && objective.results.mar?.goal) && (targetMonth >= 3) ? ((objective.results.mar.execution / objective.results.mar.goal) * 100).toFixed(0) + ' %' : '', fontSize: 7, bold: true  } ], unbreakable: true, fillColor: targetMonth >= 3 ? (this.getF2GoalColor(objective.results.mar?.resultStatus , objective.plans.frequency, 'mar' ) || '#c2c2c2') : '#c2c2c2'},
            //   { stack: [ { text: (!!objective.results.abr && objective.results.abr?.goal) && (targetMonth >= 4) ? ((objective.results.abr.execution / objective.results.abr.goal) * 100).toFixed(0) + ' %' : '', fontSize: 7, bold: true  } ], unbreakable: true, fillColor: targetMonth >= 4 ? (this.getF2GoalColor(objective.results.abr?.resultStatus , objective.plans.frequency, 'abr' ) || '#c2c2c2') : '#c2c2c2'},
            //   { stack: [ { text: (!!objective.results.may && objective.results.may?.goal) && (targetMonth >= 5) ? ((objective.results.may.execution / objective.results.may.goal) * 100).toFixed(0) + ' %' : '', fontSize: 7, bold: true  } ], unbreakable: true, fillColor: targetMonth >= 5 ? (this.getF2GoalColor(objective.results.may?.resultStatus , objective.plans.frequency, 'may' ) || '#c2c2c2') : '#c2c2c2'},
            //   { stack: [ { text: (!!objective.results.jun && objective.results.jun?.goal) && (targetMonth >= 6) ? ((objective.results.jun.execution / objective.results.jun.goal) * 100).toFixed(0) + ' %' : '', fontSize: 7, bold: true  } ], unbreakable: true, fillColor: targetMonth >= 6 ? (this.getF2GoalColor(objective.results.jun?.resultStatus , objective.plans.frequency, 'jun' ) || '#c2c2c2') : '#c2c2c2'},
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: (!!objective.results.jul && objective.results.jul?.goal) && (targetMonth >= 7) ? ((objective.results.jul.execution / objective.results.jul.goal) * 100).toFixed(0) + ' %' : '', fontSize: 7, bold: true  } ], unbreakable: true, fillColor: targetMonth >= 7 ? (this.getF2GoalColor(objective.results.jul?.resultStatus , objective.plans.frequency, 'jul' ) || '#c2c2c2') : '#c2c2c2'},
            //   { stack: [ { text: (!!objective.results.ago && objective.results.ago?.goal) && (targetMonth >= 8) ? ((objective.results.ago.execution / objective.results.ago.goal) * 100).toFixed(0) + ' %' : '', fontSize: 7, bold: true  } ], unbreakable: true, fillColor: targetMonth >= 8 ? (this.getF2GoalColor(objective.results.ago?.resultStatus , objective.plans.frequency, 'ago' ) || '#c2c2c2') : '#c2c2c2'},
            //   { stack: [ { text: (!!objective.results.sep && objective.results.sep?.goal) && (targetMonth >= 9) ? ((objective.results.sep.execution / objective.results.sep.goal) * 100).toFixed(0) + ' %' : '', fontSize: 7, bold: true  } ], unbreakable: true, fillColor: targetMonth >= 9 ? (this.getF2GoalColor(objective.results.sep?.resultStatus , objective.plans.frequency, 'sep' ) || '#c2c2c2') : '#c2c2c2'},
            //   { stack: [ { text: (!!objective.results.oct && objective.results.oct?.goal) && (targetMonth >= 10) ? ((objective.results.oct.execution / objective.results.oct.goal) * 100).toFixed(0) + ' %' : '', fontSize: 7, bold: true  } ], unbreakable: true, fillColor: targetMonth >= 10 ? (this.getF2GoalColor(objective.results.oct?.resultStatus , objective.plans.frequency, 'oct' ) || '#c2c2c2') : '#c2c2c2'},
            //   { stack: [ { text: (!!objective.results.nov && objective.results.nov?.goal) && (targetMonth >= 11) ? ((objective.results.nov.execution / objective.results.nov.goal) * 100).toFixed(0) + ' %' : '', fontSize: 7, bold: true  } ], unbreakable: true, fillColor: targetMonth >= 11 ? (this.getF2GoalColor(objective.results.nov?.resultStatus , objective.plans.frequency, 'nov' ) || '#c2c2c2') : '#c2c2c2'},
            //   { stack: [ { text: (!!objective.results.dic && objective.results.dic?.goal) && (targetMonth >= 12) ? ((objective.results.dic.execution / objective.results.dic.goal) * 100).toFixed(0) + ' %' : '', fontSize: 7, bold: true  } ], unbreakable: true, fillColor: targetMonth >= 12 ? (this.getF2GoalColor(objective.results.dic?.resultStatus , objective.plans.frequency, 'dic' ) || '#c2c2c2') : '#c2c2c2'},
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            //   { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
            // ]
        );
      });
    });




    this.pdfMake.createPdf(def).download(`F2 Resumen Mensual.pdf`);
  }

  async generateMultiF1MonthlyOverviewPDF(data: any, targetMonth: number = 12, initial?: number, final?: number, forcedDate?: Date, approvalBy?: string, leftFooter?: string, versioning?: YearConstant, director?: string) {
    let counter = 0;
    await this.loadPdfMaker();
    const currentUser =  this.authService.currentUserValue.name
        + ' '
        + this.authService.currentUserValue.lastname;

    const def: any = {
      pageSize: 'LEGAL',
      pageMargins: [30, 140, 30, 40],
      pageOrientation: 'landscape',
      footer(currentPage, pageCount, pageSize) {
        let showApproved = false;
        let f1 = null;
        for(let l = 0; l < def.content.length; l++){
          if(def.content[l + 1]?.hasOwnProperty('stack')){
            if(def.content[l + 1].stack[0].pageFooter &&
                (
                    currentPage === def.content[l + 2]?.positions[0]?.pageNumber - 1 || currentPage == pageCount
                )
            ){
              showApproved = true;
              f1 = def.content[l + 1].stack[0].pageFooter
            }
          }
        }
        if (false) {
          return {
            layout: 'noBorders',
            margin: [90, 8, 90, 8 ],
            table: {
              headerRows: 0,
              widths: [ '*', '*' ],
              body: [
                [
                  {
                    text:
                        [
                          {text: !!f1.approvalAt ? `${f1.unit.leader.name.toUpperCase() + ' '
                            + f1.unit.leader.lastname.toUpperCase() + '\n' }` : 'SIN APROBAR\n', fontSize: 9,
                            alignment: 'left', bold: true},
                          {text: !!f1.approvalAt ? 'DIRECTOR DE UNIDAD' : '', fontSize: 9, alignment: 'left'}
                        ]
                  },
                  {
                    text:
                        [
                          {text: currentPage.toString() + ' de ' + pageCount.toString(), alignment: 'right'}
                        ]
                  },
                ]
              ]
            }
          }
        } else {
          return {
            layout: 'noBorders',
            margin: [25, 8, 25, 8 ],
            table: {
              headerRows: 0,
              widths: [ '*', '*', '*' ],
              body: [
                [
                  {
                    text:
                        [
                          {text: leftFooter ? leftFooter : '', fontSize: 9,
                            alignment: 'left', bold: true},
                        ]
                  },
                  {
                    text:
                        [
                          {text: '"Documento No Controlado" a excepción del original', fontSize: 9,
                            alignment: 'center'},
                        ]
                  },
                  {
                    text:
                        [
                          {text: ( (initial | 0) + currentPage - (initial ? 1 : 0)).toString() + ' de ' + ( final && final >= pageCount + initial - 1 ? final : pageCount ).toString(), alignment: 'right', fontSize: 9}
                        ]
                  },
                ]
              ]
            }
          }
        }
        // } else {
        //   return null;
        // }
      },
      header(currentPage, pageCount, pageSize) {
        // you can apply any logic and return any valid pdfmake element
        let unit = "";
        let date = "";
        let leader = "";
        let createdBy = "";
        let department = "";
        let year = "";
        for(let l = 0; l < def.content.length; l++){
          if(def.content[l].pageHeader && currentPage >= def.content[l].positions[0].pageNumber){
            unit = def.content[l].pageHeader.unit;
            date = def.content[l].pageHeader.date;
            leader = def.content[l].pageHeader.leader;
            createdBy = def.content[l].pageHeader.createdBy;
            department = def.content[l].pageHeader.department;
            year = def.content[l].pageHeader.year;
          }
        }

        if (currentPage) {
          return [
            {
              columns: [
                {
                  image: logoBASE64,
                  width: 55,
                  margin: [25, 15, 15, 0 ],
                },
                {
                  text: [
                    {
                      text: 'ASAMBLEA NACIONAL\n',
                      margin: [0, 35, 0, 0],
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: 'SECRETARÍA GENERAL\n',
                      margin: [0, 2, 0, 0],
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: 'INFORME DE AVANCE DE ACTIVIDADES Y PROYECTOS DEL MES\n',
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: `                                                                                                                                     ${year}                                                                                                                                       `,
                      background: '#c2c2c2',
                      fontSize: 12,
                      bold: true,
                      alignment: 'center',
                      width: 1
                    }
                  ],
                  margin: [95, 8, 75, 0 ],
                },
                {
                  text: [
                    {
                      text: `\nCódigo ${versioning.f2Header.code}\n`,
                      margin: [0, 50, 0, 0],
                      fontSize: 6,
                      alignment: 'left'
                    },
                    {
                      text: `Versión ${versioning.f2Header.version}\n`,
                      margin: [0, 2, 0, 0],
                      fontSize: 6,
                      alignment: 'left'
                    },
                    {
                      text: `Fecha de versión:\n${versioning.f2Header.date}\n`,
                      fontSize: 6,
                      alignment: 'left'
                    },
                  ],
                  width: 90,
                  padding: [15, 15, 25, 0 ],
                  margin: [5, 25, 25, 0]
                },
              ],
              margin: [0, 20, 0, 0]
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 80 ],
                body: [[
                  { text: [
                      {text: `Unidad ejecutora: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${unit}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: `Fecha: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${date ? moment(date).format('DD/MM/yyyy') : 'Sin aprobar'}`, fontSize: 9, italics: true
                        , alignment: 'left', decoration: 'underline'}
                    ]},
                ]]
              }
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 375 ],
                body: [[
                  { text: [
                      {text: `Nombre del jefe y sello de la dependencia: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${leader}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: department ? `Nombre del departamento: ` : '', fontSize: 9, bold: true, alignment: 'left'},
                      {text: department ? `${department}` : '', fontSize: 9, italics: true
                        , alignment: 'left', decoration: 'underline'}
                    ]},
                ]]
              }
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 375 ],
                body: [[
                  { text: [
                      {text: `Elaborado por: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${createdBy}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: `Pág. `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${(initial | 0) + currentPage - (initial ? 1 : 0)}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'},
                      {text: ` de `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${final && final >= pageCount + initial - 1 ? final : pageCount}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ],
                  },
                ]]
              }
            }


          ];
        } else {
          return null;
        }
      },
      content: []
    };

    data.forEach( (currentF2, index) => {
      def.content.push(
            {
              layout: { paddingTop(index, node) {
                  applyVerticalAlignment(node, index, 'center');
                  return 0;
                },
                paddingLeft: function(i, node) { return 0; },
                paddingRight: function(i, node) { return 0; },
                paddingBottom: function(i, node) { return 0; },
              }, // optional
              table: {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                headerRows: 3,
                widths: [ 120, 120, 38, 26, 44, 50, 27, 27, 27, 27, 27, 27, 30, 27, 27, 27, 27, 27, 27, 30, 30, 110 ],
                dontBreakRows: true,

                body: [
                  [
                    { stack: [
                        { text: [
                            { text: 'Recursos: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
                            { text: 'El presupuesto es asignado y administrado por la Dirección General de Administración y Finanzas.',
                              fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
                          ]},
                        { text: [
                            { text: 'Fuente: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
                            { text: 'Datos provenientes de cada dirección.',
                              fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
                          ]}
                      ],
                      colSpan: 22,
                      fillColor: '#f6ef52'
                    },
                    // colSpan needs explicit columns definitions
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                  ],
                  [
                    { text: 'OBJETIVOS', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'ACTIVIDADES', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'INDICADOR', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'META ANUAL %', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'FRECUENCIA', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'RESPONSABLE', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'DISTRIBUCIÓN DE LA META POR MES', fontSize: 7, bold: true, alignment: 'center', colSpan: 16, fillColor: '#275ead', color: '#ffffff' },
                    // { text: '', fillColor: '#275ead' },
                    // { text: '', fillColor: '#275ead' },
                    // { text: '', fillColor: '#275ead' },
                    // { text: '', fillColor: '#275ead' },
                    // { text: '', fillColor: '#275ead' },
                    // { text: '', fillColor: '#275ead' },
                    // { text: 'E', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    // { text: 'F', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    // { text: 'M', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    // { text: 'A', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    // { text: 'MY', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    // { text: 'JN', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    // { text: 'AVANCE I\nSem %', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    // { text: 'JL', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    // { text: 'AG', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    // { text: 'S', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    // { text: 'O', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    // { text: 'N', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    // { text: 'D', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    // { text: 'AVANCE II\nSem %', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    // { text: 'TOTAL DEL AÑO\n%', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                  ],
                  [
                    { text: '', fillColor: '#275ead' },
                    { text: '', fillColor: '#275ead' },
                    { text: '', fillColor: '#275ead' },
                    { text: '', fillColor: '#275ead' },
                    { text: '', fillColor: '#275ead' },
                    { text: '', fillColor: '#275ead' },
                    { text: 'E', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'F', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'M', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'A', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'MY', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'JN', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'AVANCE I\nSem %', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'JL', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'AG', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'S', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'O', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'N', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'D', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'AVANCE II\nSem %', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'TOTAL DEL AÑO\n%', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    { text: 'OBSERVACIONES', fontSize: 7, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                  ],
                ],
              },
            }
      )
      let lastF1;
      currentF2.dataForPdf.objectives.forEach( (f1, index) => {
        f1.forEach( (objective, innerIndex) => {
          // console.log(currentF2);
          objective.plans = currentF2.data.plan || currentF2.data.plans.find((data) => objective.id === data.objectiveId);
          objective.f3 = (currentF2.f3sObjectives.find( (data) => data.id === objective.plans?.objectiveId ))?.f3;
          objective.responsable = objective.plans?.objective?.responsable;
          console.log('currentF2.data', currentF2)
          def.content[def.content.length - 1].pageHeader = {
            unit: currentF2.data.f1.unit.name,
            date: currentF2.data.approvalAt,
            leader: currentF2.data.director ? currentF2.data.director?.name + ' ' + currentF2.data.director?.lastname : currentF2.data.f1.unit.leader.name + ' ' + currentF2.data.f1.unit.leader.lastname,
            createdBy: currentF2.data.updatedBy ? currentF2.data.updatedBy?.name + ' ' + currentF2.data.updatedBy?.lastname : (objective.plans?.updatedBy ? objective.plans?.updatedBy?.name + ' ' + objective.plans?.updatedBy?.lastname : 'Vacío'),
            department: currentF2.data.f1.department?.name,
            year: currentF2.data.f1.plan.year
          }
          if(f1.length - 1 === innerIndex){
            lastF1 = currentF2.data.f1;
          }
          // @ts-ignore
          def.content[def.content.length - 1].table.body.push(
              [
                // @ts-ignore
                { stack: [ { text: innerIndex === 0 ? `${counter + 1}.- ${objective.objectives}` : '', margin: [2, 2, 2, 2] } ], alignment: 'justify', unbreakable: true, fontSize: 7},
                // @ts-ignore
                { stack: [ { text: !!objective.activities ? objective.activities : '--', margin: [2, 2, 2, 2] } ], alignment: 'justify', unbreakable: true, fontSize: 7 },
                // @ts-ignore
                { stack: [ { text: `${!!objective.plans.indicator.upperText ? objective.plans.indicator.upperText : '--'}/ ${!!objective.plans.indicator.bottomText ? (objective.plans.indicator.goal || typeof(objective.plans.indicator.goal) === 'number' ? objective.plans.indicator.goal.toString() : '#') + ' ' + objective.plans.indicator.bottomText : '--'} x100`, margin: [2, 2, 2, 2] } ], alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7 },
                // @ts-ignore
                { stack: [ { text: !!objective.plans.objective.annualGoal ? objective.plans.objective.annualGoal + ' %' : '--', margin: [2, 2, 2, 2] } ], alignment: 'center', unbreakable: true, fontSize: 7 },
                // @ts-ignore
                { stack: [ { text: this.periodList.find(p => p.value === objective.plans.objective.frequency)?.displayValue, margin: [2, 2, 2, 2]}  ],
                  // @ts-ignore
                  alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7 },
                // @ts-ignore
                { stack: [ { text: !!objective.responsable ? objective.responsable : '--', margin: [2, 2, 2, 2] } ], alignment: 'center', unbreakable: true, fontSize: 7 },
                // @ts-ignore
                { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7, bold: true },
                // @ts-ignore
                { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7, bold: true },
                // @ts-ignore
                { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7, bold: true },
                // @ts-ignore
                { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7, bold: true },
                // @ts-ignore
                { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7, bold: true },
                // @ts-ignore
                { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7, bold: true },
                // @ts-ignore
                { stack: [ { text: '\xa0' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7, bold: true },
                // @ts-ignore
                { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7, bold: true },
                // @ts-ignore
                { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7, bold: true },
                // @ts-ignore
                { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7, bold: true },
                // @ts-ignore
                { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7, bold: true },
                // @ts-ignore
                { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7, bold: true },
                // @ts-ignore
                { stack: [ { text: '' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7, bold: true },
                // @ts-ignore
                { stack: [ { text: '\xa0' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7, bold: true },
                // @ts-ignore
                { stack: [ { text: '\xa0' , margin: [2, 2, 2, 2] } ], alignment: 'center',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7, bold: true },
                // @ts-ignore
                { stack: [ { text:
                        (targetMonth === 12 && objective.results.dic?.observations?.substring(0, 350).trim())
                        || (targetMonth === 11 && objective.results.nov?.observations?.substring(0, 350).trim())
                        || (targetMonth === 10 && objective.results.oct?.observations?.substring(0, 350).trim())
                        || (targetMonth === 9 && objective.results.sep?.observations?.substring(0, 350).trim())
                        || (targetMonth === 8 && objective.results.ago?.observations?.substring(0, 350).trim())
                        || (targetMonth === 7 && objective.results.jul?.observations?.substring(0, 350).trim())
                        || (targetMonth === 6 && objective.results.jun?.observations?.substring(0, 350).trim())
                        || (targetMonth === 5 && objective.results.may?.observations?.substring(0, 350).trim())
                        || (targetMonth === 4 && objective.results.abr?.observations?.substring(0, 350).trim())
                        || (targetMonth === 3 && objective.results.mar?.observations?.substring(0, 350).trim())
                        || (targetMonth === 2 && objective.results.feb?.observations?.substring(0, 350).trim())
                        || (targetMonth === 1 && objective.results.ene?.observations?.substring(0, 350).trim())
                        || ''
                    , margin: [2, 2, 2, 2] } ], alignment: 'justify',
                  // @ts-ignore
                  unbreakable: true, fontSize: 7, bold: true },
              ],
              [
                { stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            text: 'META\n', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2', margin: [2, 0, 2, 0]
                          }]
                        ],
                      },
                      colSpan: 6
                    },
                    // { text: 'META\n', fontSize: 8, colSpan: 6, color: '#FF0000', fillColor: '#c2c2c2' },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            text: 'EJECUCIÓN', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2', margin: [2, 0, 2, 0]
                          }]
                        ],
                      },
                      colSpan: 6
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: 'RESULTADO', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2', margin: [2, 0, 2, 0]
                          }]
                        ],
                      },
                      colSpan: 6
                    }
                  ], unbreakable: true, fillColor: '#c2c2c2' },
                { stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    }
                  ], unbreakable: true, fillColor: '#c2c2c2' },
                { stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    }
                  ], unbreakable: true, fillColor: '#c2c2c2' },
                { stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    }
                  ], unbreakable: true, fillColor: '#c2c2c2' },
                { stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    }
                  ], unbreakable: true, fillColor: '#c2c2c2' },
                { stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    }
                  ], unbreakable: true, fillColor: '#c2c2c2' },
                {
                  stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.ene && (targetMonth >= 1) ? (typeof(objective.results.ene?.goal) === 'number' ? objective.results.ene?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.ene && (targetMonth >= 1) ? (typeof(objective.results.ene?.execution) === 'number' ? objective.results.ene?.execution : '\xa0') : '\xa0',
                            fontSize: 8,
                            fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        paddingLeft: function(i, node) { return 4; },
                        paddingRight: function(i, node) { return 4; },
                        paddingTop: function(i, node) { return 2; },
                        paddingBottom: function(i, node) { return 1; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: (!!objective.results.ene && objective.results.ene?.goal) && (targetMonth >= 1) ? ((objective.results.ene.execution / objective.results.ene.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.ene?.goal) === 'number' && typeof(objective.results.ene?.execution) === 'number') && (targetMonth >= 1) ? '0%' : '\xa0',
                            fontSize: 7,
                            bold: true,
                            fillColor: targetMonth >= 1  && objective.plans.monthAvailability[0].color? (this.getF2GoalColor(objective.results.ene?.resultStatus , objective.plans.objective.frequency, 'ene' ) || '#c2c2c2') : '#c2c2c2',
                            alignment:"center"
                          }]
                        ],
                      },
                    },
                  ], unbreakable: true, fillColor: '#c2c2c2'
                },
                // { stack: [ { text: objective.results.ene && (targetMonth >= 1) ? (typeof(objective.results.ene?.goal) === 'number' ? objective.results.ene?.goal : '#') : '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
                {
                  stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.feb && (targetMonth >= 2) ? (typeof(objective.results.feb?.goal) === 'number' ? objective.results.feb?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.feb && (targetMonth >= 2) ? (typeof(objective.results.feb?.execution) === 'number' ? objective.results.feb?.execution : '\xa0') : '\xa0',
                            fontSize: 8,
                            fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        paddingLeft: function(i, node) { return 4; },
                        paddingRight: function(i, node) { return 4; },
                        paddingTop: function(i, node) { return 2; },
                        paddingBottom: function(i, node) { return 1; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: (!!objective.results.feb && objective.results.feb?.goal) && (targetMonth >= 2) ? ((objective.results.feb.execution / objective.results.feb.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.feb?.goal) === 'number' && typeof(objective.results.feb?.execution) === 'number') && (targetMonth >= 2) ? '0%' : '\xa0',
                            fontSize: 7,
                            bold: true,
                            fillColor: targetMonth >= 2  && objective.plans.monthAvailability[1].color? (this.getF2GoalColor(objective.results.feb?.resultStatus , objective.plans.objective.frequency, 'feb' ) || '#c2c2c2') : '#c2c2c2',
                            alignment:"center"
                          }]
                        ],
                      },
                    },
                  ], unbreakable: true, fillColor: '#c2c2c2'
                },
                {
                  stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.mar && (targetMonth >= 3) ? (typeof(objective.results.mar?.goal) === 'number' ? objective.results.mar?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.mar && (targetMonth >= 3) ? (typeof(objective.results.mar?.execution) === 'number' ? objective.results.mar?.execution : '\xa0') : '\xa0',
                            fontSize: 8,
                            fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        paddingLeft: function(i, node) { return 4; },
                        paddingRight: function(i, node) { return 4; },
                        paddingTop: function(i, node) { return 2; },
                        paddingBottom: function(i, node) { return 1; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: (!!objective.results.mar && objective.results.mar?.goal) && (targetMonth >= 3) ? ((objective.results.mar.execution / objective.results.mar.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.mar?.goal) === 'number' && typeof(objective.results.mar?.execution) === 'number') && (targetMonth >= 3) ? '0%' : '\xa0',
                            fontSize: 7,
                            bold: true,
                            fillColor: targetMonth >= 3  && objective.plans.monthAvailability[2].color? (this.getF2GoalColor(objective.results.mar?.resultStatus , objective.plans.objective.frequency, 'mar' ) || '#c2c2c2') : '#c2c2c2',
                            alignment:"center"
                          }]
                        ],
                      },
                    },
                  ], unbreakable: true, fillColor: '#c2c2c2'
                },
                {
                  stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.abr && (targetMonth >= 4) ? (typeof(objective.results.abr?.goal) === 'number' ? objective.results.abr?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.abr && (targetMonth >= 4) ? (typeof(objective.results.abr?.execution) === 'number' ? objective.results.abr?.execution : '\xa0') : '\xa0',
                            fontSize: 8,
                            fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        paddingLeft: function(i, node) { return 4; },
                        paddingRight: function(i, node) { return 4; },
                        paddingTop: function(i, node) { return 2; },
                        paddingBottom: function(i, node) { return 1; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: (!!objective.results.abr && objective.results.abr?.goal) && (targetMonth >= 4) ? ((objective.results.abr.execution / objective.results.abr.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.abr?.goal) === 'number' && typeof(objective.results.abr?.execution) === 'number') && (targetMonth >= 4) ? '0%' : '\xa0',
                            fontSize: 7,
                            bold: true,
                            fillColor: targetMonth >= 4  && objective.plans.monthAvailability[3].color? (this.getF2GoalColor(objective.results.abr?.resultStatus , objective.plans.objective.frequency, 'abr' ) || '#c2c2c2') : '#c2c2c2',
                            alignment:"center"
                          }]
                        ],
                      },
                    },
                  ], unbreakable: true, fillColor: '#c2c2c2'
                },
                {
                  stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.may && (targetMonth >= 5) ? (typeof(objective.results.may?.goal) === 'number' ? objective.results.may?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.may && (targetMonth >= 5) ? (typeof(objective.results.may?.execution) === 'number' ? objective.results.may?.execution : '\xa0') : '\xa0',
                            fontSize: 8,
                            fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        paddingLeft: function(i, node) { return 4; },
                        paddingRight: function(i, node) { return 4; },
                        paddingTop: function(i, node) { return 2; },
                        paddingBottom: function(i, node) { return 1; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: (!!objective.results.may && objective.results.may?.goal) && (targetMonth >= 5) ? ((objective.results.may.execution / objective.results.may.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.may?.goal) === 'number' && typeof(objective.results.may?.execution) === 'number') && (targetMonth >= 5) ? '0%' : '\xa0',
                            fontSize: 7,
                            bold: true,
                            fillColor: targetMonth >= 5  && objective.plans.monthAvailability[4].color? (this.getF2GoalColor(objective.results.may?.resultStatus , objective.plans.objective.frequency, 'may' ) || '#c2c2c2') : '#c2c2c2',
                            alignment:"center"
                          }]
                        ],
                      },
                    },
                  ], unbreakable: true, fillColor: '#c2c2c2'
                },
                {
                  stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.jun && (targetMonth >= 6) ? (typeof(objective.results.jun?.goal) === 'number' ? objective.results.jun?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.jun && (targetMonth >= 6) ? (typeof(objective.results.jun?.execution) === 'number' ? objective.results.jun?.execution : '\xa0') : '\xa0',
                            fontSize: 8,
                            fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        paddingLeft: function(i, node) { return 4; },
                        paddingRight: function(i, node) { return 4; },
                        paddingTop: function(i, node) { return 2; },
                        paddingBottom: function(i, node) { return 1; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: (!!objective.results.jun && objective.results.jun?.goal) && (targetMonth >= 6) ? ((objective.results.jun.execution / objective.results.jun.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.jun?.goal) === 'number' && typeof(objective.results.jun?.execution) === 'number') && (targetMonth >= 6) ? '0%' : '\xa0',
                            fontSize: 7,
                            bold: true,
                            fillColor: targetMonth >= 6  && objective.plans.monthAvailability[5].color? (this.getF2GoalColor(objective.results.jun?.resultStatus , objective.plans.objective.frequency, 'jun' ) || '#c2c2c2') : '#c2c2c2',
                            alignment:"center"
                          }]
                        ],
                      },
                    },
                  ], unbreakable: true, fillColor: '#c2c2c2'
                },
                { stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 4; },
                        paddingRight: function(i, node) { return 4; },
                        paddingTop: function(i, node) { return 2; },
                        paddingBottom: function(i, node) { return 1; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            alignment:"center",
                            text: !!objective.f3 && targetMonth >= 6 ? (+objective.f3.semester1).toFixed(0) + ' %' : '--',
                            fontSize: 7,
                            bold: true,
                            fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    }
                  ], unbreakable: true, fillColor: '#c2c2c2' },
                {
                  stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.jul && (targetMonth >= 7) ? (typeof(objective.results.jul?.goal) === 'number' ? objective.results.jul?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.jul && (targetMonth >= 7) ? (typeof(objective.results.jul?.execution) === 'number' ? objective.results.jul?.execution : '\xa0') : '\xa0',
                            fontSize: 8,
                            fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        paddingLeft: function(i, node) { return 4; },
                        paddingRight: function(i, node) { return 4; },
                        paddingTop: function(i, node) { return 2; },
                        paddingBottom: function(i, node) { return 1; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: (!!objective.results.jul && objective.results.jul?.goal) && (targetMonth >= 7) ? ((objective.results.jul.execution / objective.results.jul.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.jul?.goal) === 'number' && typeof(objective.results.jul?.execution) === 'number') && (targetMonth >= 7) ? '0%' : '\xa0',
                            fontSize: 7,
                            bold: true,
                            fillColor: targetMonth >= 7  && objective.plans.monthAvailability[6].color? (this.getF2GoalColor(objective.results.jul?.resultStatus , objective.plans.objective.frequency, 'jul' ) || '#c2c2c2') : '#c2c2c2',
                            alignment:"center"
                          }]
                        ],
                      },
                    },
                  ], unbreakable: true, fillColor: '#c2c2c2'
                },
                {
                  stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.ago && (targetMonth >= 8) ? (typeof(objective.results.ago?.goal) === 'number' ? objective.results.ago?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.ago && (targetMonth >= 8) ? (typeof(objective.results.ago?.execution) === 'number' ? objective.results.ago?.execution : '\xa0') : '\xa0',
                            fontSize: 8,
                            fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        paddingLeft: function(i, node) { return 4; },
                        paddingRight: function(i, node) { return 4; },
                        paddingTop: function(i, node) { return 2; },
                        paddingBottom: function(i, node) { return 1; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: (!!objective.results.ago && objective.results.ago?.goal) && (targetMonth >= 8) ? ((objective.results.ago.execution / objective.results.ago.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.ago?.goal) === 'number' && typeof(objective.results.ago?.execution) === 'number') && (targetMonth >= 8) ? '0%' : '\xa0',
                            fontSize: 7,
                            bold: true,
                            fillColor: targetMonth >= 8  && objective.plans.monthAvailability[7].color? (this.getF2GoalColor(objective.results.ago?.resultStatus , objective.plans.objective.frequency, 'ago' ) || '#c2c2c2') : '#c2c2c2',
                            alignment:"center"
                          }]
                        ],
                      },
                    },
                  ], unbreakable: true, fillColor: '#c2c2c2'
                },
                {
                  stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.sep && (targetMonth >= 9) ? (typeof(objective.results.sep?.goal) === 'number' ? objective.results.sep?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.sep && (targetMonth >= 9) ? (typeof(objective.results.sep?.execution) === 'number' ? objective.results.sep?.execution : '\xa0') : '\xa0',
                            fontSize: 8,
                            fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        paddingLeft: function(i, node) { return 4; },
                        paddingRight: function(i, node) { return 4; },
                        paddingTop: function(i, node) { return 2; },
                        paddingBottom: function(i, node) { return 1; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: (!!objective.results.sep && objective.results.sep?.goal) && (targetMonth >= 9) ? ((objective.results.sep.execution / objective.results.sep.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.sep?.goal) === 'number' && typeof(objective.results.sep?.execution) === 'number') && (targetMonth >= 9) ? '0%' : '\xa0',
                            fontSize: 7,
                            bold: true,
                            fillColor: targetMonth >= 9  && objective.plans.monthAvailability[8].color? (this.getF2GoalColor(objective.results.sep?.resultStatus , objective.plans.objective.frequency, 'sep' ) || '#c2c2c2') : '#c2c2c2',
                            alignment:"center"
                          }]
                        ],
                      },
                    },
                  ], unbreakable: true, fillColor: '#c2c2c2'
                },
                {
                  stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.oct && (targetMonth >= 10) ? (typeof(objective.results.oct?.goal) === 'number' ? objective.results.oct?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.oct && (targetMonth >= 10) ? (typeof(objective.results.oct?.execution) === 'number' ? objective.results.oct?.execution : '\xa0') : '\xa0',
                            fontSize: 8,
                            fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        paddingLeft: function(i, node) { return 4; },
                        paddingRight: function(i, node) { return 4; },
                        paddingTop: function(i, node) { return 2; },
                        paddingBottom: function(i, node) { return 1; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: (!!objective.results.oct && objective.results.oct?.goal) && (targetMonth >= 10) ? ((objective.results.oct.execution / objective.results.oct.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.oct?.goal) === 'number' && typeof(objective.results.oct?.execution) === 'number') && (targetMonth >= 10) ? '0%' : '\xa0',
                            fontSize: 7,
                            bold: true,
                            fillColor: targetMonth >= 10 && objective.plans.monthAvailability[9].color ? (this.getF2GoalColor(objective.results.oct?.resultStatus , objective.plans.objective.frequency, 'oct' ) || '#c2c2c2') : '#c2c2c2',
                            alignment:"center"
                          }]
                        ],
                      },
                    },
                  ], unbreakable: true, fillColor: '#c2c2c2'
                },
                {
                  stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.nov && (targetMonth >= 11) ? (typeof(objective.results.nov?.goal) === 'number' ? objective.results.nov?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.nov && (targetMonth >= 11) ? (typeof(objective.results.nov?.execution) === 'number' ? objective.results.nov?.execution : '\xa0') : '\xa0',
                            fontSize: 8,
                            fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        paddingLeft: function(i, node) { return 4; },
                        paddingRight: function(i, node) { return 4; },
                        paddingTop: function(i, node) { return 2; },
                        paddingBottom: function(i, node) { return 1; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: (!!objective.results.nov && objective.results.nov?.goal) && (targetMonth >= 11) ? ((objective.results.nov.execution / objective.results.nov.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.nov?.goal) === 'number' && typeof(objective.results.nov?.execution) === 'number') && (targetMonth >= 11) ? '0%' : '\xa0',
                            fontSize: 7,
                            bold: true,
                            fillColor: targetMonth >= 11 && objective.plans.monthAvailability[10].color ? (this.getF2GoalColor(objective.results.nov?.resultStatus , objective.plans.objective.frequency, 'nov' ) || '#c2c2c2') : '#c2c2c2',
                            alignment:"center"
                          }]
                        ],
                      },
                    },
                  ], unbreakable: true, fillColor: '#c2c2c2'
                },
                {
                  stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.dic && (targetMonth >= 12) ? (typeof(objective.results.dic?.goal) === 'number' ? objective.results.dic?.goal : '#') : '\xa0', fontSize: 8, fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: objective.results.dic && (targetMonth >= 12) ? (typeof(objective.results.dic?.execution) === 'number' ? objective.results.dic?.execution : '\xa0') : '\xa0',
                            fontSize: 8,
                            fillColor: '#c2c2c2'
                          }]
                        ]
                      }
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                        paddingLeft: function(i, node) { return 4; },
                        paddingRight: function(i, node) { return 4; },
                        paddingTop: function(i, node) { return 2; },
                        paddingBottom: function(i, node) { return 1; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            text: (!!objective.results.dic && objective.results.dic?.goal) && (targetMonth >= 12) ? ((objective.results.dic.execution / objective.results.dic.goal) * 100).toFixed(0) + '%' : (typeof(objective.results.dic?.goal) === 'number' && typeof(objective.results.dic?.execution) === 'number') && (targetMonth >= 12) ? '0%' : '\xa0',
                            fontSize: 7,
                            bold: true,
                            fillColor: targetMonth >= 12 && objective.plans.monthAvailability[11].color ? (this.getF2GoalColor(objective.results.dic?.resultStatus , objective.plans.objective.frequency, 'dec' ) || '#c2c2c2') : '#c2c2c2',
                            alignment:"center"
                          }]
                        ],
                      },
                    },
                  ], unbreakable: true, fillColor: '#c2c2c2'
                },
                { stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            alignment:"center",
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 4; },
                        paddingRight: function(i, node) { return 4; },
                        paddingTop: function(i, node) { return 2; },
                        paddingBottom: function(i, node) { return 1; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            alignment:"center",
                            text: !!objective.f3 && targetMonth === 12 ? (+objective.f3.semester2).toFixed(0) + ' %' : '--',
                            fontSize: 7,
                            bold: true,
                            fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    }
                  ], unbreakable: true, fillColor: '#c2c2c2' },
                { stack: [
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 1;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 0; },
                        paddingRight: function(i, node) { return 0; },
                        paddingTop: function(i, node) { return 0; },
                        paddingBottom: function(i, node) { return 0; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, true],
                            text: '\xa0', fontSize: 8, color: '#FF0000', fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    },
                    {
                      layout: {
                        hLineWidth: function (i, node) {
                          return 0;
                        },
                        vLineWidth: function (i, node) {
                          return 0;
                        },
                        paddingLeft: function(i, node) { return 4; },
                        paddingRight: function(i, node) { return 4; },
                        paddingTop: function(i, node) { return 2; },
                        paddingBottom: function(i, node) { return 1; },
                        fillColor: function (rowIndex, node, columnIndex) { return '#000'; }
                      },
                      table: {
                        widths:'*',
                        body: [
                          [{
                            border: [false, false, false, false],
                            alignment:"center",
                            text: !!objective.f3 && targetMonth === 12 ? (+objective.f3.total).toFixed(0) + ' %' : '--',
                            fontSize: 7,
                            bold: true,
                            fillColor: '#c2c2c2'
                          }]
                        ],
                      },
                    }
                  ], unbreakable: true, fillColor: '#c2c2c2' },
                { stack: [ { text: '', fontSize: 8, fillColor: '#c2c2c2' } ], unbreakable: true, fillColor: '#c2c2c2' },
              ]          );
        });
        counter++;
        // @ts-ignore
      });
      def.content.push(
          // @ts-ignore
          [
            {
              pageFooter: lastF1,
              layout: 'noBorders',
              margin: [0, 0, 0, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*' ],
                body: [
                  [{
                    stack: [
                      {text: '', fontSize: 9,
                        alignment: 'left', bold: true}
                    ]
                  }]
                ]
              },
              pageBreak: data.length - 1 !== index ? 'after' : null
            }
          ]
      )
    })
    // const plansToUse = data.plan || data.plans[0];



    this.pdfMake.createPdf(def).download(`F2 Resumen Mensual.pdf`);
  }

  async generateMultiF3FromInstPDF(data: any, parsedObjectives: any, toPDF?: any, initial?: number, final?: number, forcedDate?: Date, approvalBy?: string, semesterIsChecked?: boolean, leftFooter?: string, versioning?: YearConstant) {
    let counter = 0;
    await this.loadPdfMaker();

    const currentUser =  this.authService.currentUserValue.name
        + ' '
        + this.authService.currentUserValue.lastname;

    const def = {
      pageSize: 'LETTER',
      pageMargins: [30, 140, 30, 40],
      pageOrientation: 'landscape',
      footer(currentPage, pageCount, pageSize) {
        let showApproved = false;
        let f1 = null;
        console.log(initial, final, pageCount);
        for(let l = 0; l < def.content.length; l++){
          if(def.content[l + 1]?.hasOwnProperty('stack')){
            if(def.content[l + 1].stack[0].pageFooter &&
                (
                    currentPage === def.content[l + 2]?.positions[0]?.pageNumber - 1 || currentPage == pageCount
                )
            ){
              showApproved = true;
              f1 = def.content[l + 1].stack[0].pageFooter
            }
          }
        }
        if (false) {
          return {
            layout: 'noBorders',
            margin: [90, 8, 90, 8 ],
            table: {
              headerRows: 0,
              widths: [ '*', '*' ],
              body: [
                [
                  {
                    text:
                        [
                          {text: !!f1.approvalAt ? `${f1.unit.leader.name.toUpperCase() + ' '
                            + f1.unit.leader.lastname.toUpperCase() + '\n' }` : 'SIN APROBAR\n', fontSize: 9,
                            alignment: 'left', bold: true},
                          {text: !!f1.approvalAt ? 'DIRECTOR DE UNIDAD' : '', fontSize: 9, alignment: 'left'}
                        ]
                  },
                  {
                    text:
                        [
                          {text: currentPage.toString() + ' de ' + pageCount.toString(), alignment: 'right', fontSize: 7}
                        ]
                  },
                ]
              ]
            }
          }
        } else {
          return {
            layout: 'noBorders',
            margin: [25, 8, 25, 8 ],
            table: {
              headerRows: 0,
              widths: [ '*', '*', '*' ],
              body: [
                [
                  {
                    text:
                        [
                          {text: leftFooter ? leftFooter : '', fontSize: 9,
                            alignment: 'left', bold: true},
                        ]
                  },
                  {
                    text:
                        [
                          {text: '"Documento No Controlado" a excepción del original', fontSize: 9,
                            alignment: 'center'},
                        ]
                  },
                  {
                    text:
                        [
                          {text: ( (initial | 0) + currentPage - (initial ? 1 : 0)).toString() + ' de ' + ( final && final >= pageCount + initial - 1 ? final : pageCount ).toString(), alignment: 'right', fontSize: 9}
                        ]
                  },
                ]
              ]
            }
          }
        }
        // } else {
        //   return null;
        // }
      },
      header(currentPage, pageCount, pageSize) {
        // you can apply any logic and return any valid pdfmake element
        let unit = "";
        let date = "";
        let leader = "";
        let createdBy = "";
        let department = "";
        let year = "";
        for(let l = 0; l < def.content.length; l++){
          if(def.content[l].pageHeader && currentPage >= def.content[l].positions[0].pageNumber){
            unit = def.content[l].pageHeader.unit;
            date = def.content[l].pageHeader.date;
            leader = def.content[l].pageHeader.leader;
            createdBy = def.content[l].pageHeader.createdBy;
            department = def.content[l].pageHeader.department;
            year = def.content[l].pageHeader.year;
          }
        }

        if (currentPage) {
          return [
            {
              columns: [
                {
                  image: logoBASE64,
                  width: 55,
                  margin: [25, 15, 15, 0 ],
                },
                {
                  text: [
                    {
                      text: 'ASAMBLEA NACIONAL\n',
                      margin: [0, 35, 0, 0],
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: 'SECRETARÍA GENERAL\n',
                      margin: [0, 2, 0, 0],
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: 'INFORME DE EVALUACIÓN SEMESTRAL DE ACTIVIDADES Y PROYECTOS DEL ÁREA TÉCNICA Y ADMINISTRATIVA\n',
                      fontSize: 10,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: `                                                                                             ${year}                                                                                                       `,
                      background: '#c2c2c2',
                      fontSize: 12,
                      bold: true,
                      alignment: 'center',
                      width: 1
                    }
                  ],
                  margin: [65, 8, 65, 0 ],
                },
                {
                  text: [
                    {
                      text: `\nCódigo ${versioning.f3Header.code}\n`,
                      margin: [0, 50, 0, 0],
                      fontSize: 6,
                      alignment: 'left'
                    },
                    {
                      text: `Versión ${versioning.f3Header.version}\n`,
                      margin: [0, 2, 0, 0],
                      fontSize: 6,
                      alignment: 'left'
                    },
                    {
                      text: `Fecha de versión:\n${versioning.f3Header.date}\n`,
                      fontSize: 6,
                      alignment: 'left'
                    },
                  ],
                  width: 90,
                  padding: [15, 15, 25, 0 ],
                  margin: [5, 25, 25, 0]
                },
              ],
              margin: [0, 20, 0, 0]
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 80 ],
                body: [[
                  { text: [
                      {text: `Unidad ejecutora: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${unit}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: `Fecha: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${ date ? moment(date).format('DD/MM/yyyy') : 'Vacío' }`, fontSize: 9, italics: true
                        , alignment: 'left', decoration: 'underline'}
                    ]},
                ]]
              }
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 300 ],
                body: [
                  [
                    { text: [
                        {text: `Nombre del jefe y sello de la dependencia: `, fontSize: 9, bold: true, alignment: 'left'},
                        {text: `${leader}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                      ]},
                    { text: [
                        {text: department ? `Nombre del departamento: ` : '', fontSize: 9, bold: true, alignment: 'left'},
                        {text: department ? `${department}` : '', fontSize: 9, italics: true
                          , alignment: 'left', decoration: 'underline'}
                      ]},
                  ]
                ]
              }
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 300 ],
                body: [[
                  { text: [
                      {text: `Elaborado por: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${createdBy || 'Vacío'}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: `Pág. `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${(initial | 0) + currentPage - (initial ? 1 : 0)}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'},
                      {text: ` de `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${ final && final >= pageCount + initial - 1 ? final : pageCount }`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ],
                  },
                ]]
              }
            }
          ];
        } else {
          return null;
        }
      },
      content: []
    };

    parsedObjectives.forEach( (unit, currentIndex) => {
      unit.f1s.forEach( (f1, currentF1Index) => {
        if(f1.objectives.length){
          def.content.push(
              {
                layout: { paddingTop(index, node) {
                    applyVerticalAlignment(node, index, 'center');
                    return 0;
                  }, }, // optional
                table: {
                  // headers are automatically repeated if the table spans over multiple pages
                  // you can declare how many rows should be treated as headers
                  headerRows: 2,
                  widths: [ 160, 160, 80, 30, 60, 70, 30, 30, 30 ],
                  dontBreakRows: true,

                  body: [
                    [
                      { stack: [
                          { text: [
                              { text: 'Recursos: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
                              { text: 'El presupuesto es asignado y administrado por la Dirección General de Administración y Finanzas.',
                                fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
                            ]},
                          { text: [
                              { text: 'Fuente: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
                              { text: 'Datos provenientes de cada dirección.',
                                fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
                            ]}
                        ],
                        colSpan: 9,
                        fillColor: '#f6ef52'
                      },
                      // colSpan needs explicit columns definitions
                      { text: '' },
                      { text: '' },
                      { text: '' },
                      { text: '' },
                      { text: '' },
                      { text: '' },
                      { text: '' },
                      { text: '' },
                    ],
                    [ { text: 'OBJETIVOS', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                      { text: 'ACTIVIDADES', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                      { text: 'INDICADOR', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                      { text: 'META ANUAL %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                      { text: 'FRECUENCIA', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                      { text: 'RESPONSABLE', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                      { text: 'I Sem %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                      { text: 'II Sem %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                      { text: 'TOTAL %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' } ]
                  ],
                },
              }
          )
          let current = 0;
          f1.objectives.forEach( (objective, currentIndex) => {
            console.log(f1);
            def.content[def.content.length - 1].pageHeader = {
              unit: unit.unit.name,
              date:  semesterIsChecked ? f1.f3Sem2ExportedAt || f1.f3Sem1ExportedAt : f1.f3Sem1ExportedAt,
              leader: semesterIsChecked && f1.responsable2 ? f1.responsable2.name + ' ' + f1.responsable2.lastname : (f1.responsable ? f1.responsable.name + ' ' + f1.responsable.lastname : unit.unit.leader.name + ' ' + unit.unit.leader.lastname),
              createdBy: semesterIsChecked ? (f1.f3Sem2CreatedBy ? f1.f3Sem2CreatedBy?.name + ' ' + f1.f3Sem2CreatedBy?.lastname : (f1.f3Sem1CreatedBy ? f1.f3Sem1CreatedBy?.name + ' ' + f1.f3Sem1CreatedBy?.lastname : 'Vacío')) : (f1.f3Sem1CreatedBy ? f1.f3Sem1CreatedBy?.name + ' ' + f1.f3Sem1CreatedBy?.lastname : 'Vacío'),
              department: toPDF ? toPDF[currentF1Index].department?.name : f1.department?.name,
              year: unit.year
            }

            if(objective.index !== current || currentIndex === 0){
              counter++;
            }

            def.content[def.content.length - 1].table.body.push(
                [
                  // @ts-ignore
                  { stack: [ { text: objective.index !== current || currentIndex === 0 ? `${counter}.- ${objective.title}` : '', margin: [0, 2, 0, 2] } ], alignment: 'justify', unbreakable: true, fontSize: 9 },
                  // @ts-ignore
                  { stack: [ { text: !!objective.activities ? objective.activities : '--', margin: [0, 2, 0, 2] } ], alignment: 'justify', unbreakable: true, fontSize: 9 },
                  // @ts-ignore
                  { stack: [ { text: `${!!objective.indicatorUpperText ? objective.indicatorUpperText : '--'}/ ${!!objective.indicatorBottomText ? (objective.indicatorGoal || typeof(objective.indicatorGoal) === "number" ? objective.indicatorGoal.toString() : '#') + ' ' + objective.indicatorBottomText : '--'} x100`, margin: [0, 2, 0, 2] } ], alignment: 'center',
                    unbreakable: true, fontSize: 9 },
                  // @ts-ignore
                  { stack: [ { text: !!objective.annualGoal || typeof(objective.annualGoal) === "number" ? objective.annualGoal : '--', margin: [0, 2, 0, 2] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
                  // @ts-ignore
                  { stack: [ { text: this.periodList.find(p => p.value === objective.frequency)?.displayValue || '--', margin: [0, 2, 0, 2]}  ],
                    alignment: 'center',
                    unbreakable: true, fontSize: 9 },
                  // @ts-ignore
                  { stack: [ { text: !!objective.responsable ? objective.responsable : '--', margin: [0, 2, 0, 2] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
                  // @ts-ignore
                  { stack: [ { text: !!objective.f3?.semester1 ? objective.f3?.semester1 : '0', margin: [0, 2, 0, 2] } ], alignment: 'center',
                    unbreakable: true, fontSize: 9, bold: true },
                  // @ts-ignore
                  { stack: [ { text: !!objective.f3?.semester2 && semesterIsChecked ? objective.f3?.semester2 : '0' , margin: [0, 2, 0, 2] } ],
                    alignment: 'center', unbreakable: true, fontSize: 9, bold: true },
                  // @ts-ignore
                  { stack: [ { text: (!isNaN(objective.f3?.semester1) && !isNaN(objective.f3?.semester2)) && semesterIsChecked ? objective.f3?.total : '--', margin: [0, 2, 0, 2] } ], alignment: 'center',
                    unbreakable: true, fontSize: 9, bold: true },
                ],
            );
            current = objective.index;
          })
          def.content.push(
              [
                {
                  pageFooter:  data.length ? {...unit, approvalAt: unit.f1s[0].approvalAt} : {...data, approvalAt: f1.approvalAt},
                  layout: 'noBorders',
                  margin: [0, 0, 0, 0 ],
                  unbreakable: true,
                  table: {
                    headerRows: 0,
                    widths: [ '*' ],
                    dontBreakRows: true,
                    body: [
                      [{
                        stack: [
                          {text: '', fontSize: 9,
                            alignment: 'left', bold: true}
                        ]
                      }],
                    ]
                  },
                  pageBreak: toPDF && unit.f1s.length - 1 !== currentF1Index ? 'after' : parsedObjectives.length - 1 !== currentIndex ? 'after' : null
                }
              ]
          )
        }
      });
    });



    this.pdfMake.createPdf(def).download(`F3 Multi.pdf`);
  }

  async generateMultiF1FromInstPDF(
      data: any,
      parsedObjectives: any,
      toPDF?: any,
      initial?: number,
      final?: number,
      forcedDate?: Date,
      approvalBy?: string,
      leftFooter?: string,
      versioning?: YearConstant
  ) {
    let counter = 0;
    await this.loadPdfMaker();

    const currentUser =  this.authService.currentUserValue.name
        + ' '
        + this.authService.currentUserValue.lastname;

    const def = {
      pageSize: 'LETTER',
      pageMargins: [30, 140, 30, 35],
      pageOrientation: 'landscape',
      footer(currentPage, pageCount, pageSize) {
        let showApproved = false;
        let f1 = null;
        console.log(initial, final, pageCount);
        for(let l = 0; l < def.content.length; l++){
          if(def.content[l + 1]?.hasOwnProperty('stack')){
            if(def.content[l + 1].stack[0].pageFooter &&
                (
                    currentPage === def.content[l + 2]?.positions[0]?.pageNumber - 1 || currentPage == pageCount
                )
            ){
              showApproved = true;
              f1 = def.content[l + 1].stack[0].pageFooter
            }
          }
        }
        if (false) {
          return {
            layout: 'noBorders',
            margin: [90, 8, 90, 8 ],
            table: {
              headerRows: 0,
              widths: [ '*', '*' ],
              body: [
                [
                  {
                    text:
                        [
                          {text: !!f1.approvalAt ? `${f1.unit.leader.name.toUpperCase() + ' '
                            + f1.unit.leader.lastname.toUpperCase() + '\n' }` : 'SIN APROBAR\n', fontSize: 9,
                            alignment: 'left', bold: true},
                          {text: !!f1.approvalAt ? 'DIRECTOR DE UNIDAD' : '', fontSize: 9, alignment: 'left'}
                        ]
                  },
                  {
                    text:
                        [
                          {text: currentPage.toString() + ' de ' + pageCount.toString(), alignment: 'right', fontSize: 7}
                        ]
                  },
                ]
              ]
            }
          }
        } else {
          return {
            layout: 'noBorders',
            margin: [25, 8, 25, 8 ],
            table: {
              headerRows: 0,
              widths: [ '*', '*', '*' ],
              body: [
                [
                  {
                    text:
                        [
                          {text: leftFooter ? leftFooter : '', fontSize: 9,
                            alignment: 'left', bold: true},
                        ]
                  },
                  {
                    text:
                        [
                          {text: '"Documento No Controlado" a excepción del original', fontSize: 9,
                            alignment: 'center'},
                        ]
                  },
                  {
                    text:
                        [
                          {text: ( (initial | 0) + currentPage - (initial ? 1 : 0)).toString() + ' de ' + ( final && final >= pageCount + initial - 1 ? final : pageCount ).toString(), alignment: 'right', fontSize: 9}
                        ]
                  },
                ]
              ]
            }
          }
        }
        // } else {
        //   return null;
        // }
      },
      header(currentPage, pageCount, pageSize) {
        // you can apply any logic and return any valid pdfmake element
        let unit = "";
        let date = "";
        let leader = "";
        let createdBy = "";
        let department = "";
        let year = "";
        for(let l = 0; l < def.content.length; l++){
          if(def.content[l].pageHeader && currentPage >= def.content[l].positions[0].pageNumber){
            unit = def.content[l].pageHeader.unit;
            date = def.content[l].pageHeader.date;
            leader = def.content[l].pageHeader.leader;
            createdBy = def.content[l].pageHeader.createdBy;
            department = def.content[l].pageHeader.department;
            year = def.content[l].pageHeader.year;
          }
        }

        if (currentPage) {
          return [
            {
              columns: [
                {
                  image: logoBASE64,
                  width: 55,
                  margin: [25, 15, 15, 0 ],
                },
                {
                  text: [
                    {
                      text: 'ASAMBLEA NACIONAL\n',
                      margin: [0, 35, 0, 0],
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: 'SECRETARÍA GENERAL\n',
                      margin: [0, 2, 0, 0],
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: 'PLAN OPERATIVO ANUAL (POA - F1)\n',
                      fontSize: 12,
                      bold: true,
                      alignment: 'center'
                    },
                    {
                      text: `                                                                                             ${year}                                                                                                       `,
                      background: '#c2c2c2',
                      fontSize: 12,
                      bold: true,
                      alignment: 'center',
                      width: 1
                    }
                  ],
                  margin: [95, 8, 95, 0 ],
                },
                {
                  text: [
                    {
                      text: `\nCódigo ${versioning.f1Header.code}\n`,
                      margin: [0, 50, 0, 0],
                      fontSize: 6,
                      alignment: 'left'
                    },
                    {
                      text: `Versión ${versioning.f1Header.version}\n`,
                      margin: [0, 2, 0, 0],
                      fontSize: 6,
                      alignment: 'left'
                    },
                    {
                      text: `Fecha de versión:\n${versioning.f1Header.date}\n`,
                      fontSize: 6,
                      alignment: 'left'
                    },
                  ],
                  width: 90,
                  padding: [15, 15, 25, 0 ],
                  margin: [5, 25, 25, 0]
                },
              ],
              margin: [0, 20, 0, 0]
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 80 ],
                body: [[
                  { text: [
                      {text: `Unidad ejecutora: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${unit}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: `Fecha: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${date ? moment(date).format('DD/MM/yyyy') : 'Sin aprobar'}`, fontSize: 9, italics: true
                        , alignment: 'left', decoration: 'underline'}
                    ]},
                ]]
              }
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 300 ],
                body: [
                  [
                    { text: [
                        {text: `Nombre del jefe y sello de la dependencia: `, fontSize: 9, bold: true, alignment: 'left'},
                        {text: `${leader}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                      ]},
                    { text: [
                        {text: department ? `Nombre del departamento: ` : '', fontSize: 9, bold: true, alignment: 'left'},
                        {text: department ? `${department}` : '', fontSize: 9, italics: true
                          , alignment: 'left', decoration: 'underline'}
                      ]},
                  ]
                ]
              }
            },
            {
              layout: 'noBorders',
              margin: [90, 0, 90, 0 ],
              table: {
                headerRows: 0,
                widths: [ '*', 300 ],
                body: [[
                  { text: [
                      {text: `Elaborado por: `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${createdBy}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ]},
                  { text: [
                      {text: `Pág. `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${(initial | 0) + currentPage - (initial ? 1 : 0)}`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'},
                      {text: ` de `, fontSize: 9, bold: true, alignment: 'left'},
                      {text: `${ final && final >= pageCount + initial - 1 ? final : pageCount }`, fontSize: 9, italics: true, alignment: 'left', decoration: 'underline'}
                    ],
                  },
                ]]
              }
            }
          ];
        } else {
          return null;
        }
      },
      content: []
    };

    parsedObjectives.forEach( (unit, currentIndex) => {
      unit.f1s.forEach( (f1, currentF1Index) => {
        if(f1.objectives.length){
            def.content.push(
                {
                  layout: { paddingTop(index, node) {
                      applyVerticalAlignment(node, index, 'center');
                      return 0;
                    }, }, // optional
                  table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    headerRows: 2,
                    widths: [ 175, 180, 80, 30, 60, 70, 70 ],
                    dontBreakRows: true,

                    body: [
                      [
                        { stack: [
                            { text: [
                                { text: 'Recursos: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
                                { text: 'El presupuesto es asignado y administrado por la Dirección General de Administración y Finanzas.',
                                  fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
                              ]},
                            { text: [
                                { text: 'Fuente: ', fontSize: 10, bold: true, alignment: 'left', color: '#ed0008' },
                                { text: 'Datos provenientes de cada dirección.',
                                  fontSize: 10, italics: true, alignment: 'left', color: '#ed0008', decoration: 'underline'}
                              ]}
                          ],
                          colSpan: 7,
                          fillColor: '#f6ef52'
                        },
                        // colSpan needs explicit columns definitions
                        { text: '' },
                        { text: '' },
                        { text: '' },
                        { text: '' },
                        { text: '' },
                        { text: '' },
                      ],
                      [ { text: 'OBJETIVOS', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                        { text: 'ACTIVIDADES', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                        { text: 'INDICADOR', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                        { text: 'META ANUAL %', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                        { text: 'FRECUENCIA', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                        { text: 'RESPONSABLE', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                        { text: 'FECHA DE FINALIZACIÓN', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' } ],
                    ],
                  },
                }
            )
          let current = 0;
          f1.objectives.forEach( (objective, currentIndex) => {
            def.content[def.content.length - 1].pageHeader = {
              unit: unit.unit.name,
              date:  f1.approvalAt,
              leader: f1.director ? f1.director.name + ' ' + f1.director.lastname : unit.unit.leader.name + ' ' + unit.unit.leader.lastname,
              createdBy: f1.createdBy?.name + ' ' + f1.createdBy?.lastname,
              department: toPDF ? toPDF[currentF1Index].department?.name : f1.department?.name,
              year: unit.year
            }

            if(objective.index !== current || currentIndex === 0){
              counter++;
            }

            def.content[def.content.length - 1].table.body.push(
                [
                  // @ts-ignore
                  { stack: [ { text: objective.index !== current || currentIndex === 0 ? `${counter}.- ${objective.title}` : '', margin: [0, 2, 0, 2] } ], alignment: 'justify', unbreakable: true, fontSize: 9 },
                  // @ts-ignore
                  { stack: [ { text: !!objective.activities ? objective.activities : '--', margin: [0, 2, 0, 2] } ], alignment: 'justify', unbreakable: true, fontSize: 9 },
                  // @ts-ignore
                  { stack: [ { text: `${!!objective.indicatorUpperText ? objective.indicatorUpperText : '--'}/ ${!!objective.indicatorBottomText ? (objective.indicatorGoal || typeof(objective.indicatorGoal) === "number" ? objective.indicatorGoal.toString() : '#') + ' ' + objective.indicatorBottomText : '--'} x100`, margin: [0, 2, 0, 2] } ], alignment: 'center',
                    unbreakable: true, fontSize: 9 },
                  // @ts-ignore
                  { stack: [ { text: !!objective.annualGoal || typeof(objective.annualGoal) === "number" ? objective.annualGoal : '--', margin: [0, 2, 0, 2] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
                  // @ts-ignore
                  { stack: [ { text: this.periodList.find(p => p.value === objective.frequency)?.displayValue || '--', margin: [0, 2, 0, 2]}  ],
                    alignment: 'center',
                    unbreakable: true, fontSize: 9 },
                  // @ts-ignore
                  { stack: [ { text: !!objective.responsable ? objective.responsable : '--', margin: [0, 2, 0, 2] } ], alignment: 'center', unbreakable: true, fontSize: 9 },
                  // @ts-ignore
                  { stack: [ { text: this.moment(!!objective.endDate ? objective.endDate : f1.createdAt).format('ll'), margin: [0, 2, 0, 2] } ], alignment: 'center',
                    unbreakable: true, fontSize: 9 }
                ],
            );
            current = objective.index;
          })
          def.content.push(
              [
                {
                  pageFooter:  data.length ? {...unit, approvalAt: unit.f1s[0].approvalAt} : {...data, approvalAt: f1.approvalAt},
                  layout: 'noBorders',
                  margin: [0, 0, 0, 0 ],
                  unbreakable: true,
                  table: {
                    headerRows: 0,
                    widths: [ '*' ],
                    dontBreakRows: true,
                    body: [
                      [{
                        stack: [
                          {text: '', fontSize: 9,
                            alignment: 'left', bold: true}
                        ]
                      }],
                    ]
                  },
                  pageBreak: toPDF && unit.f1s.length - 1 !== currentF1Index ? 'after' : parsedObjectives.length - 1 !== currentIndex ? 'after' : null
                }
              ]
          )
        }
      });
    });



    this.pdfMake.createPdf(def).download(`F1 Multi.pdf`);
  }

  async generateTables(data: any, dataChart: any, initial?: number, final?: number){
    await this.loadPdfMaker();

    const def = {
      pageSize: 'LETTER',
      pageMargins: [30, 40, 30, 40],
      pageOrientation: 'portrait',
      footer(currentPage, pageCount, pageSize) {
        return {
            layout: 'noBorders',
            margin: [90, 8, 25, 8 ],
            table: {
              headerRows: 0,
              widths: [ '*', 70 ],
              body: [
                [
                  {
                    text:
                        [
                          {text: '', fontSize: 9,
                            alignment: 'left', bold: true},
                          {text: '', fontSize: 9, alignment: 'left'}
                        ]
                  },
                  {
                    text:
                        [
                          {text: ( (initial | 0) + currentPage - (initial ? 1 : 0)).toString() + ' de ' + ( final && final >= pageCount + initial - 1 ? final : pageCount ).toString(), alignment: 'right', fontSize: 9}
                        ]
                  },
                ]
              ]
            }
        }
      },
      content: []
    };

    data[0]?.f1s.forEach((objective, index) => {

      def.content.push({
            columns: [
              { width: '*', text: '' },
              {
                width: 300,
                layout: { paddingTop(index, node) {
                    applyVerticalAlignment(node, index, 'center');
                    return 0;
                  }, }, // optional
                unbreakable: true,
                table: {
                  // headers are automatically repeated if the table spans over multiple pages
                  // you can declare how many rows should be treated as headers
                  headerRows: 2,
                  widths: [ '*', '*' ],
                  dontBreakRows: true,
                  body: [
                    [
                      { stack: [
                          { text: [
                              { text: `${objective.department?.name || dataChart[index].label}`, fontSize: 16, bold: true, alignment: 'center', color: '#000' },
                            ], margin: [0, 12, 0, 4]},
                        ],
                        colSpan: 2,
                        fillColor: '#fff',
                        border: [false, false, false, false],
                        alignment:"center",
                      },
                      // colSpan needs explicit columns definitions
                      { text: '' },
                    ],
                    [
                      { text: 'Nro. de objetivo', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                      { text: 'Primer semestre', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    ]
                  ],
                },
              },
              { width: '*', text: '' },
            ]
          }
      )

      objective.objectives.forEach((currentObjective, innerIndex) => {
        def.content[def.content.length - 1].columns[1].table.body.push([
          {
            "text": "Nro. " + (innerIndex + 1),
            "nodeName": "TD",
            "unbreakable": true,
            "alignment": 'center',
            "fontSize": 10,
            "style": [
              "html-td",
              "html-tr",
              "html-tbody",
              "html-table",
              "mb-5"
            ],
            "_margin": null,
            "_inlines": [],
            "_minWidth": 22.5,
            "_maxWidth": 22.5,
            "positions": [
              {
                "pageNumber": 1,
                "pageOrientation": "portrait",
                "pageInnerHeight": 432,
                "pageInnerWidth": 732,
                "left": 30,
                "top": 140,
                "verticalRatio": 0,
                "horizontalRatio": 0
              }
            ]
          },
          {
            "text": currentObjective.f3.semester1 + "%",
            "nodeName": "TD",
            "unbreakable": true,
            "alignment": 'center',
            "fontSize": 10,
            "style": [
              "html-td",
              "html-tr",
              "html-tbody",
              "html-table",
              "mb-5"
            ],
            "_margin": null,
            "_inlines": [],
            "_minWidth": 29.00390625,
            "_maxWidth": 29.00390625,
            "positions": [
              {
                "pageNumber": 1,
                "pageOrientation": "portrait",
                "pageInnerHeight": 432,
                "pageInnerWidth": 732,
                "left": 30,
                "top": 140,
                "verticalRatio": 0,
                "horizontalRatio": 0,
                "alignment": 'center'
              }
            ]
          }
        ])
      });
    })

    this.pdfMake.createPdf(def).download(`F3 Tablas.pdf`);
  }

  async generateConsolidateTable(data: any, initial?: number, final?: number){
    await this.loadPdfMaker();

    const def = {
      pageSize: 'LETTER',
      pageMargins: [30, 40, 30, 40],
      pageOrientation: 'portrait',
      footer(currentPage, pageCount, pageSize) {
        return {
          layout: 'noBorders',
          margin: [90, 8, 25, 8 ],
          table: {
            headerRows: 0,
            widths: [ '*', 70 ],
            body: [
              [
                {
                  text:
                      [
                        {text: '', fontSize: 9,
                          alignment: 'left', bold: true},
                        {text: '', fontSize: 9, alignment: 'left'}
                      ]
                },
                {
                  text:
                      [
                        {text: ( (initial | 0) + currentPage - (initial ? 1 : 0)).toString() + ' de ' + ( final && final >= pageCount + initial - 1 ? final : pageCount ).toString(), alignment: 'right', fontSize: 9}
                      ]
                },
              ]
            ]
          }
        }
      },
      content: []
    };

    // data.forEach((current, index) => {

      def.content.push({
            columns: [
              { width: '*', text: '' },
              {
                width: 400,
                layout: { paddingTop(index, node) {
                    applyVerticalAlignment(node, index, 'center');
                    return 0;
                  }, }, // optional
                // unbreakable: true,
                table: {
                  // headers are automatically repeated if the table spans over multiple pages
                  // you can declare how many rows should be treated as headers
                  headerRows: 2,
                  widths: [ '*', 80 ],
                  dontBreakRows: true,
                  body: [
                    [
                      { stack: [
                          { text: [
                              { text: ``, fontSize: 16, bold: true, alignment: 'center', color: '#000' },
                            ], margin: [0, 12, 0, 4]},
                        ],
                        colSpan: 2,
                        fillColor: '#fff',
                        border: [false, false, false, false],
                        alignment:"center",
                      },
                      // colSpan needs explicit columns definitions
                      { text: '' },
                    ],
                    [
                      { text: 'Seguimiento y medición', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                      { text: 'Promedio', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                    ]
                  ],
                },
              },
              { width: '*', text: '' },
            ]
          }
      )

      // objective.objectives.forEach((currentObjective, innerIndex) => {
    data.forEach((current, index) => {
      def.content[def.content.length - 1].columns[1].table.body.push([
        { text: current.cleanName, fontSize: 10, bold: true, alignment: 'center', fillColor: '#ffffff', color: '#000' },
        { text: current.value.toFixed(0) + '%', fontSize: 10, bold: true, alignment: 'center', fillColor: current.color, color: '#ffffff' },
        // {
        //   "text": current.name,
        //   "nodeName": "TD",
        //   "unbreakable": true,
        //   "alignment": 'center',
        //   "fontSize": 10,
        //   "style": [
        //     "html-td",
        //     "html-tr",
        //     "html-tbody",
        //     "html-table",
        //     "mb-5"
        //   ],
        //   "_margin": null,
        //   "_inlines": [],
        //   "_minWidth": 22.5,
        //   "_maxWidth": 22.5,
        //   "positions": [
        //     {
        //       "pageNumber": 1,
        //       "pageOrientation": "portrait",
        //       "pageInnerHeight": 432,
        //       "pageInnerWidth": 732,
        //       "left": 30,
        //       "top": 140,
        //       "verticalRatio": 0,
        //       "horizontalRatio": 0
        //     }
        //   ]
        // },
        // {
        //   "text": current.value + "%",
        //   "nodeName": "TD",
        //   "unbreakable": true,
        //   "alignment": 'center',
        //   "fontSize": 10,
        //   "style": [
        //     "html-td",
        //     "html-tr",
        //     "html-tbody",
        //     "html-table",
        //     "mb-5"
        //   ],
        //   "_margin": null,
        //   "_inlines": [],
        //   "_minWidth": 29.00390625,
        //   "_maxWidth": 29.00390625,
        //   "positions": [
        //     {
        //       "pageNumber": 1,
        //       "pageOrientation": "portrait",
        //       "pageInnerHeight": 432,
        //       "pageInnerWidth": 732,
        //       "left": 30,
        //       "top": 140,
        //       "verticalRatio": 0,
        //       "horizontalRatio": 0,
        //       "alignment": 'center'
        //     }
        //   ]
        // },
      ])
    });
      // });
    // })

    this.pdfMake.createPdf(def).download(`Consolidado.pdf`);
  }

  async generateTablesFull(data: any, dataChart: any, initial?: number, final?: number){
    await this.loadPdfMaker();

    const def = {
      pageSize: 'LETTER',
      pageMargins: [30, 40, 30, 40],
      pageOrientation: 'portrait',
      footer(currentPage, pageCount, pageSize) {
        return {
          layout: 'noBorders',
          margin: [90, 8, 25, 8 ],
          table: {
            headerRows: 0,
            widths: [ '*', 70 ],
            body: [
              [
                {
                  text:
                      [
                        {text: '', fontSize: 9,
                          alignment: 'left', bold: true},
                        {text: '', fontSize: 9, alignment: 'left'}
                      ]
                },
                {
                  text:
                      [
                        {text: ( (initial | 0) + currentPage - (initial ? 1 : 0)).toString() + ' de ' + ( final && final >= pageCount + initial - 1 ? final : pageCount ).toString(), alignment: 'right', fontSize: 9}
                      ]
                },
              ]
            ]
          }
        }
      },
      content: []
    };

    data[0]?.f1s.forEach((objective, index) => {
      def.content.push({
            columns: [
              { width: '*', text: '' },
              {
                width: 400,
                layout: { paddingTop(index, node) {
                    applyVerticalAlignment(node, index, 'center');
                    return 0;
                  }, }, // optional
                unbreakable: true,
                table: {
                  // headers are automatically repeated if the table spans over multiple pages
                  // you can declare how many rows should be treated as headers
                  headerRows: 2,
                  widths: [ '*', '*','*', '*' ],
                  dontBreakRows: true,
                  body: [
                    [
                      { stack: [
                          { text: [
                              { text: `${objective.department?.name || dataChart[index].label}`, fontSize: 16, bold: true, alignment: 'center', color: '#000' },
                            ], margin: [0, 12, 0, 4]},
                        ],
                        colSpan: 4,
                        fillColor: '#fff',
                        border: [false, false, false, false],
                        alignment:"center",
                      },
                      // colSpan needs explicit columns definitions
                      { text: '' },
                      { text: '' },
                      { text: '' },
                    ],
                    [
                      { text: 'Nro. de objetivo', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                      { text: 'Primer semestre', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                      { text: 'Segundo semestre', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' },
                      { text: 'Total', fontSize: 10, bold: true, alignment: 'center', fillColor: '#275ead', color: '#ffffff' }
                    ]
                  ],
                },
              },
              { width: '*', text: '' },
            ]
          }
      )

      objective.objectives.forEach((currentObjective, innerIndex) => {
        def.content[def.content.length - 1].columns[1].table.body.push([
          {
            "text": "Nro. " + (innerIndex + 1),
            "nodeName": "TD",
            "unbreakable": true,
            "fontSize": 10,
            "alignment": 'center',
            "style": [
              "html-td",
              "html-tr",
              "html-tbody",
              "html-table",
              "mb-5"
            ],
            "_margin": null,
            "_inlines": [],
            "_minWidth": 22.5,
            "_maxWidth": 22.5,
            "positions": [
              {
                "pageNumber": 1,
                "pageOrientation": "portrait",
                "pageInnerHeight": 432,
                "pageInnerWidth": 732,
                "left": 30,
                "top": 140,
                "verticalRatio": 0,
                "horizontalRatio": 0
              }
            ]
          },
          {
            "text": currentObjective.f3.semester1 + "%",
            "nodeName": "TD",
            "unbreakable": true,
            "alignment": 'center',
            "fontSize": 10,
            "style": [
              "html-td",
              "html-tr",
              "html-tbody",
              "html-table",
              "mb-5"
            ],
            "_margin": null,
            "_inlines": [],
            "_minWidth": 29.00390625,
            "_maxWidth": 29.00390625,
            "positions": [
              {
                "pageNumber": 1,
                "pageOrientation": "portrait",
                "pageInnerHeight": 432,
                "pageInnerWidth": 732,
                "left": 30,
                "top": 140,
                "verticalRatio": 0,
                "horizontalRatio": 0,
                "alignment": 'center'
              }
            ]
          },
          {
            "text": currentObjective.f3.semester2 + "%",
            "nodeName": "TD",
            "unbreakable": true,
            "alignment": 'center',
            "fontSize": 10,
            "style": [
              "html-td",
              "html-tr",
              "html-tbody",
              "html-table",
              "mb-5"
            ],
            "_margin": null,
            "_inlines": [],
            "_minWidth": 29.00390625,
            "_maxWidth": 29.00390625,
            "positions": [
              {
                "pageNumber": 1,
                "pageOrientation": "portrait",
                "pageInnerHeight": 432,
                "pageInnerWidth": 732,
                "left": 30,
                "top": 140,
                "verticalRatio": 0,
                "horizontalRatio": 0,
                "alignment": 'center'
              }
            ]
          },
          {
            "text": currentObjective.f3.total + "%",
            "nodeName": "TD",
            "unbreakable": true,
            "alignment": 'center',
            "fontSize": 10,
            "style": [
              "html-td",
              "html-tr",
              "html-tbody",
              "html-table",
              "mb-5"
            ],
            "_margin": null,
            "_inlines": [],
            "_minWidth": 29.00390625,
            "_maxWidth": 29.00390625,
            "positions": [
              {
                "pageNumber": 1,
                "pageOrientation": "portrait",
                "pageInnerHeight": 432,
                "pageInnerWidth": 732,
                "left": 30,
                "top": 140,
                "verticalRatio": 0,
                "horizontalRatio": 0,
                "alignment": 'center'
              }
            ]
          }

        ])
      });

      // def.content.push(
      //     [
      //       {
      //         layout: 'noBorders',
      //         margin: [0, 0, 0, 0 ],
      //         unbreakable: true,
      //         table: {
      //           headerRows: 0,
      //           widths: [ '*' ],
      //           dontBreakRows: true,
      //           body: [
      //             [{
      //               stack: [
      //                 {text: '', fontSize: 9,
      //                   alignment: 'left', bold: true}
      //               ]
      //             }],
      //           ]
      //         },
      //         pageBreak: data[0]?.f1s.length - 1 !== index ? 'after' : null
      //       }
      //     ]
      // )
    })

    this.pdfMake.createPdf(def).download(`F3 Tablas.pdf`);
  }

  async exportPieChartJPEG(jpegBase64: string, filename: string) {
    await this.loadPdfMaker();
    const def = {
      pageSize: 'LETTER',
      pageMargins: [0, 0, 0, 0],
      pageOrientation: 'landscape',
      content: [
        {
            image: jpegBase64,
            fit: [1000, 1000],
            alignment: 'center',
            margin: [0, 100, 0, 0]
        }
      ],
    }
    this.pdfMake.createPdf(def).download(filename);
  }

}
