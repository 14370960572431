import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  duration = 2000;

  constructor(
    private toastController: ToastController
  ) { }

  async showSuccess(message: string, duration = this.duration) {
    const toast = await this.toastController.create({
      message,
      duration,
      color: 'success'
    });

    toast.present();
  }

  async showError(message: string, duration = this.duration) {
    const toast = await this.toastController.create({
      message,
      duration,
      color: 'danger'
    });

    toast.present();
  }

  async showInfo(message: string, duration = this.duration) {
    const toast = await this.toastController.create({
      message,
      duration: this.duration,
      color: 'dark'
    });

    toast.present();
  }
}
