import { F2 } from './../models/F2';
import { F1 } from './../models/F1';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OperativePlan, OPStatus } from '../models/OperativePlan';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class OperativePlanService {
  constructor(private http: HttpClient, private auth: AuthService) {}

  async getAllYears(): Promise<string[]> {
    const res = await this.http.get<any>(`${environment.apiUrl}/api/v1/operative-plans/years`).toPromise();
    return res.years;
  }

  async getOperativePlansByUnit(unitId: number): Promise<OperativePlan[]> {
    const res = await this.http
      .get<any>(`${environment.apiUrl}/api/v1/operative-plans?unit=${unitId}&paginated=false&full=true`)
      .toPromise();
    return res.plans;
  }

  async getOperativePlansByYear(year: string): Promise<OperativePlan[]> {
    const res = await this.http
      .get<any>(`${environment.apiUrl}/api/v1/operative-plans?year=${year}&paginated=false&full=true`)
      .toPromise();
    return res.plans;
  }

  async getOperativePlansByYearByUnit(year: string, unitId: number): Promise<OperativePlan[]> {
    const res = await this.http
      .get<any>(`${environment.apiUrl}/api/v1/operative-plans?year=${year}&unit=${unitId}&paginated=false&full=true`)
      .toPromise();
    return res.plans;
  }

  async getOperativePlan(planId: number): Promise<OperativePlan> {
    const res = await this.http.get<any>(`${environment.apiUrl}/api/v1/operative-plan/${planId}&full=true`).toPromise();
    // console.log('REVIEW ->', res.plan);
    return res.plan;
  }

  async generateAnualPlan(year: number): Promise<any> {
    const res = await this.http.post<any>(`${environment.apiUrl}/api/v1/operative-plans/${year}`, {}).toPromise();
    return res.plan;
  }

  async changeOpePlansIndexes(data: { plans: { id: number; index: number }[] }): Promise<any> {
    const res = await this.http.put<any>(`${environment.apiUrl}/api/v1/operative-plans/indexes`, data).toPromise();
    return res.plan;
  }

  // return the class for the status;
  getStatusClass(opStatus: OPStatus, f1?: F1, f2?: F2): string {
    if (!f2) {
      switch (opStatus) {
        case 0:
          return 'a507-pill--to-fill';
        case 1:
          if (this.auth.isUnitLeader) {
            // If the user is unitLeader and the f1 is from the unit
            // if (this.auth.isUnitLeader && (f1 && f1.unit && !f1.department)) {
            if (f1 && f1.unit && !f1.department) {
              return 'a507-pill--to-send';
            } else {
              return 'a507-pill--to-receive';
            }
          } else {
            return 'a507-pill--to-send';
          }
        case 2:
          if (this.auth.isUnitLeader) {
            return 'a507-pill--to-send';
          } else if (this.auth.isDepartmentLeader) {
            return 'a507-pill--sent';
          } else {
            return 'a507-pill--to-send';
          }
        case 3:
          return 'a507-pill--to-approve';
        case 4:
          return 'a507-pill--approved';
        case 5:
          return 'a507-pill--returned';
        case 6:
          return 'a507-pill--to-review';
      }
    } else {
      switch (opStatus) {
        case 0:
          return 'a507-pill--to-fill';
        case 1:
          return 'a507-pill--sent';
        case 2:
          return 'a507-pill--approved';
        case 3:
          return 'a507-pill--returned';
        case 4:
          return 'a507-pill--to-send';
        case 5:
          return 'a507-pill--to-approve';
        case 6:
          return 'a507-pill--to-review';
      }
    }
  }

  getStatus(opStatus: OPStatus, f1?: F1, f2?: F2): string {
    if (!f2) {
      switch (opStatus) {
        case 0:
          return 'Por llenar';
        case 1:
          if (this.auth.isUnitLeader) {
            // If the user is unitLeader and the f1 is from the unit
            if (f1 && f1.unit && !f1.department) {
              return 'Por enviar';
            } else {
              return 'Por recibir';
            }
          } else {
            return 'Por enviar';
          }
        case 2:
          if (this.auth.isUnitLeader) {
            return 'Por Enviar';
          } else if (this.auth.isDepartmentLeader) {
            return 'Enviado';
          } else {
            return 'Por Enviar';
          }
        case 3:
          return 'Por aprobar';
        case 4:
          return 'Aprobado';
        case 5:
          return 'Devuelto';
        case 6:
          return 'Por revisar';
      }
    } else {
      switch (f2.status) {
        case 0:
          return 'Por llenar';
        case 1:
          return 'Enviado';
        case 2:
          return 'Aprobado';
        case 3:
          return 'Devuelto';
        case 4:
          return 'Por enviar';
        case 5:
          return 'Por aprobar';
        case 6:
          return 'Por revisar';
      }
    }
  }
}
