import { F2, getMonthMultiuse } from 'src/app/models/F2';
import { F1 } from 'src/app/models/F1';
import { OperativePlan } from 'src/app/models/OperativePlan';
import * as moment from 'moment';

moment.locale('es-us');

export class OPTableModel {
  header: {
    displayValue: string;
    value: string;
  }[];
  dataRows: any[]; // Each row must contain the properties name as the value of the header.
}

export function f1ToDatatable(f1: F1) {
  return {
    id: f1.id,
    f1,
    period: f1.plan.year,
    approvalAt: f1.approvalAt ? moment(f1.approvalAt).format('ll') : '--',
    date: moment(f1.createdAt).format('ll'),
    status: f1.status,
    departmentName: f1.department?.name || 'Plan de la unidad',
    responsableName: `${f1.responsable.name} ${f1.responsable.lastname}`,
    createdName: `${(f1.createdBy as any).name} ${(f1.createdBy as any).lastname}`,
    f3Sem1ExportedAt: f1.f3Sem1ExportedAt ? moment(f1.f3Sem1ExportedAt).format('ll') : '--',
    f3Sem2ExportedAt: f1.f3Sem2ExportedAt ? moment(f1.f3Sem2ExportedAt).format('ll') : '--',
    f3CreatedName: `${(f1.f3CreatedBy as any)?.name || '--'} ${(f1.f3CreatedBy as any)?.lastname || ''}`,
    f3Sem1CreatedName: `${(f1.f3Sem1CreatedBy as any)?.name || '--'} ${(f1.f3Sem1CreatedBy as any)?.lastname || ''}`,
    f3Sem2CreatedName: `${(f1.f3Sem2CreatedBy as any)?.name || '--'} ${(f1.f3Sem2CreatedBy as any)?.lastname || ''}`,
    updatedBy: f1.createdBy,
    f3UpdatedBy: f1.f3CreatedBy,
    f3Sem1CreatedBy: f1.f3Sem1CreatedBy,
    f3Sem2CreatedBy: f1.f3Sem2CreatedBy,
    updatedByF1Name: `${f1.unit.leader.name} ${f1.unit.leader.lastname}`,
    updatedByF1Name2: f1.responsable2 ? `${f1.responsable2.name} ${f1.responsable2.lastname}` : '--',
    sendToFirstRevisionAt: f1.sendToFirstRevisionAt ? moment(f1.sendToFirstRevisionAt).format('ll') : 'Sin fecha',
    directorName: f1.director ? `${f1.director.name} ${f1.director.lastname}` : `${f1.unit.leader.name} ${f1.unit.leader.lastname}`,
  };
}

export function f1ArrayToDatatable(f1s: F1[]) {
  return f1s.map((f1) => f1ToDatatable(f1));
}

export function f2ToDatatable(f2: F2, year?: string) {
  return {
    id: f2.id,
    f2,
    f1Id: f2.objective.f1Id,
    f1Id_month: `${f2.objective.f1Id}/${f2.month}`,
    deparmentInfo: f2.objective.f1.department?.name || 'Pertenece a la Unidad',
    updatedByName: f2.updatedBy ? `${f2.updatedBy.name} ${f2.updatedBy.lastname}` : '---',
    updatedByDirector: f2.director ? `${f2.director.name} ${f2.director.lastname}` : '---',
    period: year ? `${year} - ${getMonthMultiuse(+f2.month)}` : `${f2.month}`,
    approvalAt: f2.approvalAt ? moment(f2.approvalAt).format('ll') : '---',
    updatedBy: f2.updatedBy,
    director: f2.director,
    status: f2.status,
    exportedAt: moment(f2.objective.f1.exportedAt || f2.objective.f1.createdAt).format('ll') || 'Sin fecha',
    sendToFirstRevisionAt: f2.sendToFirstRevisionAt ? moment(f2.sendToFirstRevisionAt).format('ll') : 'Sin fecha'
  };
}

export function f2ArrayToDatatable(f2s: F2[], year?: string) {
  return f2s.map((f2) => f2ToDatatable(f2, year));
}

export function operativePlanToDatatable(operativePlan: OperativePlan) {
  // console.log(operativePlan);
  return {
    id: operativePlan.id,
    period: operativePlan.year,
    date: moment(operativePlan.createdAt).format('ll'),
    approvalAt: operativePlan.f1s?.length && operativePlan.f1s[0].approvalAt ? moment(operativePlan.f1s[0].approvalAt).format('ll') : '--',
    status: operativePlan.status,
    unitName: operativePlan.unit.name,
    responsableName: `${operativePlan.f1s[0].responsable.name} ${operativePlan.f1s[0].responsable.lastname}`,
    responsableName2: operativePlan.f1s[0].responsable2 ? `${operativePlan.f1s[0].responsable2.name} ${operativePlan.f1s[0].responsable2.lastname}` : '--',
    unitId: operativePlan.unitId,
    sendToFirstRevisionAt: operativePlan.f1s[0]?.sendToFirstRevisionAt ? moment(operativePlan.f1s[0].sendToFirstRevisionAt).format('ll') : 'Sin fecha',
  };
}

export function operativePlanArrayToDatatable(operativePlans: OperativePlan[]) {
  return operativePlans.map((operativePlan) => operativePlanToDatatable(operativePlan));
}
