import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AngularSvgIconModule } from 'angular-svg-icon';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule.forRoot(),
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: 'Sin información para mostrar', // Message to show when array is presented, but contains no values
        totalMessage: 'total', // Footer total message
        selectedMessage: 'seleccionado' // Footer selected message
      }
    }),
  ],
  exports: [
    NgxDatatableModule,
    AngularSvgIconModule
  ]
})
export class TableModule { }
