import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { F1 } from 'src/app/models/F1';
import { isEditable } from 'src/app/models/OperativePlan';
import { AuthService } from 'src/app/services/auth.service';
import { CloseIconService } from 'src/app/services/close-icon.service';
import { F1Service } from 'src/app/services/f1.service';
import { F2Service } from 'src/app/services/f2.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { OperativePlanService } from 'src/app/services/operative-plan.service';

@Component({
  selector: 'app-f2-review',
  templateUrl: './f2-review.component.html',
  styleUrls: ['./f2-review.component.scss'],
})
export class F2ReviewComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<boolean>();

  f1Id: number;
  f1: F1;
  f1ToList: {
    f1Id: number,
    f1: F1,
    department: string,
    header: { displayValue: string, value: string, flex: any }[],
    plans: {
      id: number,
      objectives: string,
      activities: string,
      indicator: {
        upperText: string,
        bottomText: string,
        goal: number,
      },
      results: any,
      annualGoal: string,
      frequency: number,
      endDate: string
    }[]
  };
  planEditable: boolean;
  objectives: {
    count: number,
    rows: {
      title: string,
      id: number,
      index: number,
      objectives: string,
      activities: string,
      indicatorUpperText: string,
      indicatorBottomText: string,
      indicatorGoal: number,
      results: any,
      annualGoal: string,
      frequency: number,
      status: number,
      endDate: string
    }[]
  };

  RESULTS_ARRAY = {
    ene: null,
    feb: null,
    mar: null,
    abr: null,
    may: null,
    jun: null,
    jul: null,
    ago: null,
    sep: null,
    oct: null,
    nov: null,
    dic: null,
  };

  constructor(
    private closeIcon: CloseIconService,
    public authService: AuthService,
    public opService: OperativePlanService,
    private f1Service: F1Service,
    private f2Service: F2Service,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.initialSetup();
  }

  ionViewDidEnter() {
    this.initialSetup();
  }

  initialSetup() {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(queryParams => {
      if (queryParams.back) {
        this.closeIcon.open();
      } else {
        this.closeIcon.open(this.authService.isUnitLeader ? 'unit/f2' : (this.authService.isDepartmentLeader ? 'department/f2' : 'institutional/f2'));
      }
    });

    this.activatedRoute.params.pipe(takeUntil(this.destroyed$)).subscribe(async params => {
      this.f1Id = +params.f1Id;
      this.f1 = await this.f1Service.getF1(this.f1Id);
      this.objectives = await this.f2Service.getF1Objectives(this.f1Id);
      this.objectives.rows = this.objectives.rows.sort((a, b) => a.id > b.id ? 1 : -1);
      this.planEditable = true;
      this.f1ToList = {
        f1Id: this.f1Id,
        f1: this.f1,
        department: this.f1.department ? `Departamento: '${this.f1.department.name}'` : `Unidad: '${this.f1.unit.name}'`,
        header: [
          { displayValue: 'Objetivos', value: 'objectives', flex: '1 1 150px' },
          { displayValue: 'Actividades', value: 'activities', flex: '1 1 150px' },
          { displayValue: 'Ene', value: 'ene', flex: '0.1 0 50px' },
          { displayValue: 'Feb', value: 'feb', flex: '0.1 0 50px' },
          { displayValue: 'Mar', value: 'mar', flex: '0.1 0 50px' },
          { displayValue: 'Abr', value: 'abr', flex: '0.1 0 50px' },
          { displayValue: 'May', value: 'may', flex: '0.1 0 50px' },
          { displayValue: 'Jun', value: 'jun', flex: '0.1 0 50px' },
          { displayValue: 'Jul', value: 'jul', flex: '0.1 0 50px' },
          { displayValue: 'Ago', value: 'ago', flex: '0.1 0 50px' },
          { displayValue: 'Sep', value: 'sep', flex: '0.1 0 50px' },
          { displayValue: 'Oct', value: 'oct', flex: '0.1 0 50px' },
          { displayValue: 'Nov', value: 'nov', flex: '0.1 0 50px' },
          { displayValue: 'Dic', value: 'dic', flex: '0.1 0 50px' },
        ],
        plans: this.objectives.rows.map((obj, index) => ({
          id: obj.id,
          index: obj.index,
          objectives: obj.title,
          activities: obj.activities,
          results: this.fillResults(obj.results),
          indicator: {
            upperText: obj.indicatorUpperText,
            bottomText: obj.indicatorBottomText,
            goal: obj.indicatorGoal,
          },
          annualGoal: obj.annualGoal,
          frequency: obj.frequency,
          status: obj.results.length ? obj.results[0].status : null,
          endDate: obj.endDate ? `${(new Date(obj.endDate)).getFullYear()}-${((new Date(obj.endDate)).getMonth() + 1) < 10 ? '0' + ((new Date(obj.endDate)).getMonth() + 1) : ((new Date(obj.endDate)).getMonth() + 1)}-${(new Date(obj.endDate)).getDate() < 10 ? '0' + (new Date(obj.endDate)).getDate() : (new Date(obj.endDate)).getDate()}` : null
        }))
      };
    });
  }

  fillResults(results: any[]) {
    const resArray = Object.keys(this.RESULTS_ARRAY);
    const objArr = {};
    resArray.forEach((r, index) => {
      const monthVal = results.find(a => Number(a.month) - 1 === index);
      objArr[r] = monthVal ? { resultStatus: monthVal.resultStatus, goal: monthVal.goal, execution: monthVal.execution, observations: monthVal.observations } : null;
    });
    return objArr;
  }

  showComment() {
    this.modalService.openModal('comment-modal', { comment: this.f1ToList.f1.comment }, 'normal');
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }
}
