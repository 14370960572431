import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})

export class JWTTokenService {

  helper = new JwtHelperService();

  jwtToken: string;
  decodedToken: { [key: string]: string };

  constructor(private localStorageService: LocalStorageService) { }

  setToken(token: string) {
    if (token) {
      this.jwtToken = token;
    }
  }

  decodeToken() {
    this.decodedToken = this.helper.decodeToken(this.jwtToken ? this.jwtToken : this.localStorageService.get('token'));
  }

  getDecodeToken() {
    return this.helper.decodeToken(this.jwtToken);
  }

  getUserId() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken._id : null;
  }

  isTokenExpired(): boolean {
    return this.helper.isTokenExpired(this.jwtToken);
  }
}
